import { BreakpointsOptions } from '@mui/system';

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 464,
    md: 960,
    lg: 1280,
    xl: 1920,
    /* MUI Defaults */
    sm: 600,
  },
};
