import { Components, Theme } from '@mui/material';

const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginLeft: 0,
      '> .MuiFormControlLabel-label': {
        ...theme.typography.body2Strong,
      },
    }),
  },
};

export default MuiFormControlLabel;
