// Mui Component overrides (Default props + style overrides)
import * as components from './components';
import {
  breakpoints,
  dark,
  light,
  shadows,
  shape,
  spacing,
  typography,
} from './constants';

export const themeOptions = {
  breakpoints,
  components,
  shadows,
  shape,
  spacing,
  typography,
  palette: {
    light,
    dark,
  },
};
