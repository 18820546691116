import { api } from './baseApi';

export const addTagTypes = ['Clusters'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      listClusters: build.query<ListClustersApiResponse, ListClustersApiArg>({
        query: queryArg => ({
          url: `/v1/clusters`,
          params: {
            owned_only: queryArg.ownedOnly,
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Clusters'],
      }),
      getCluster: build.query<GetClusterApiResponse, GetClusterApiArg>({
        query: queryArg => ({ url: `/v1/clusters/${queryArg.clusterId}` }),
        providesTags: ['Clusters'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ClustersAPI };
export type ListClustersApiResponse = /** status 200 OK */ ListClustersResponse;
export type ListClustersApiArg = {
  ownedOnly?: boolean;
  expand?: string[];
};
export type GetClusterApiResponse = /** status 200 OK */ GetClusterResponse;
export type GetClusterApiArg = {
  clusterId: string;
};
export type UserProfileImage = {
  small?: string;
  medium?: string;
  large?: string;
};
export type UserProfile = {
  id?: string;
  firstname?: string;
  lastname?: string;
  image?: UserProfileImage;
  email: string;
};
export type CloudProviderInfoAws = {};
export type CloudProviderInfoGcp = {};
export type CloudProviderInfoAzure = {};
export type ClusterCloudProviderInfo = {
  region?: string;
  aws?: CloudProviderInfoAws;
  gcp?: CloudProviderInfoGcp;
  azure?: CloudProviderInfoAzure;
};
export type ClusterStatus = {
  health_status?:
    | 'HEALTH_STATUS_UNKNOWN'
    | 'HEALTH_STATUS_HEALTHY'
    | 'HEALTH_STATUS_DEGRADED'
    | 'HEALTH_STATUS_UNHEALTHY';
};
export type ConfigAsymmetricKeyMetadata = {
  /** A string to allow identifying the key used for decryption. This may reference a resource within a cloud provider; however, clients may treat this as a fully opaque value. */
  key_id?: string;
  /** The asymmetric decryption algorithm to use with this key. */
  algorithm?: 'UNKNOWN_ALGORITHM' | 'RSA_4096_OAEP_SHA256';
  /** PEM-encoded public key. */
  public_key_pem?: string;
};
export type ClusterConfig = {
  asymmetric_key?: ConfigAsymmetricKeyMetadata;
};
export type Cluster = {
  guid?: string;
  name?: string;
  owner_guid?: string;
  owner_profile?: UserProfile;
  cloud_provider?: ClusterCloudProviderInfo;
  cloud_provider_type?: 'UNKNOWN' | 'AWS' | 'GCP' | 'AZURE';
  status?: ClusterStatus;
  created_at?: string;
  last_checkin_time?: string;
  config?: ClusterConfig;
  chart_version?: string;
  app_version?: string;
};
export type ListClustersResponse = {
  clusters?: Cluster[];
};
export type GoogleProtobufAny = {
  /** The type of the serialized message. */
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  /** The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code]. */
  code?: number;
  /** A developer-facing error message, which should be in English. Any user-facing error message should be localized and sent in the [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client. */
  message?: string;
  /** A list of messages that carry the error details.  There is a common set of message types for APIs to use. */
  details?: GoogleProtobufAny[];
};
export type GetClusterResponse = {
  cluster?: Cluster;
};
export const { useListClustersQuery, useGetClusterQuery } = injectedRtkApi;
