import { Shadows } from '@mui/material/styles/shadows';

export const shadows: Shadows = [
  'none', // [0] - none
  '0px 1px 1px 0px hsla(240, 8%, 5%, 0.05)', // [1] - small, 5% of Palette.Grey.950
  '0px 1px 2px 0px hsla(240, 8%, 5%, 0.1), 0px 1px 1px 0px hsla(240, 8%, 5%, 0.06)', // [2] - base/default, 10% and 6% of Palette.Grey.950
  '0px 4px 3px 0px hsla(240, 8%, 5%, 0.07), 0px 2px 2px 0px hsl(240, 8%, 5%)', // [3] - medium, 7% and 6% of Palette.Grey.950
  '0px 10px 8px 0px hsla(240, 8%, 5%, 0.04), 0px 4px 3px 0px hsla(240, 8%, 5%, 0.1)', // [4] - large, 4% and 10% of Palette.Grey.950
  '0px 20px 13px 0px hsla(240, 8%, 5%, 0.03), 0px 8px 5px 0px hsla(240, 8%, 5%, 0.08)', // [5] - extra large, 3% and 8% of Palette.Grey.950
  '0px 25px 25px 0px hsla(240, 8%, 5%, 0.15)', // [6] - extra extra large, 15% of Palette.Grey.950
  'none', // [7] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [8] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [9] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [10] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [11] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [12] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [13] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [14] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [15] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [16] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [17] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [18] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [19] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [20] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [21] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [22] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [23] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
  'none', // [24] - Defaulting to none to avoid complaints from MUI components that try to access shadow values we do not define.
];
