import { Components, Theme } from '@mui/material';

const MuiBreadcrumbs: Components<Theme>['MuiBreadcrumbs'] = {
  styleOverrides: {
    separator: ({ theme }) => ({
      color: theme.palette.text.secondary,
    }),
  },
};

export default MuiBreadcrumbs;
