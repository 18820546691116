import * as React from 'react';
import { Box, BoxProps } from '@mui/material';

/**
 *
 * This component is based on MUI examples https://mui.com/material-ui/react-tabs/,
 * but adjusted a bit differently than their labs component https://mui.com/material-ui/api/tab-panel/
 *
 */

type TabPanelProps = Partial<BoxProps> & {
  children?: React.ReactNode;
  index: number;
  value: number;
  tabIdentifier: string;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, tabIdentifier, ...tabPanelProps } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${tabIdentifier}-tabpanel-${index}`}
      aria-labelledby={`${tabIdentifier}-tab-${index}`}
      {...tabPanelProps}
    >
      {value === index && children}
    </Box>
  );
};
