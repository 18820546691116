import { ModelMode } from 'src/api/types/Model';
import { Field } from './util/types';

const TABULAR_HYPERPARAM_FIELDS: readonly Field[] = [
  {
    label: 'Rows',
    field: 'rows',
    value: 50,
    range: [1, 100],
    advanced: false,
    tooltip:
      'Use this to set the number of output rows. If you don’t set a value, rows will default to 50 or the instruction you write in the prompt.',
  },
  {
    label: 'Temperature',
    field: 'temperature',
    value: 0.7,
    range: [0.01, 1],
    advanced: false,
    tooltip:
      'Controls randomness of generation, higher temperature means more variability in generated data.',
  },
  {
    label: 'Top K',
    field: 'top_k',
    value: 40,
    range: [1, 40],
    advanced: true,
    tooltip:
      'Number of highest probability tokens to keep for generation. Along with a higher temperature, larger `Top K` creates more variability in generated data.',
  },
  {
    label: 'Top P',
    field: 'top_p',
    value: 0.9,
    range: [0, 1],
    advanced: true,
    tooltip:
      'Only most probable tokens with probabilities that add up to `Top P` are kept for generation. Along with a higher temperature, `Top P` closer to 1.0 creates more variability in generated data.',
  },
] as const;

const GPTX_HYPERPARAM_FIELDS: readonly Field[] = [
  {
    label: 'Temperature',
    field: 'temperature',
    value: 0.6,
    range: [0.01, 1],
    advanced: false,
    tooltip:
      'Controls randomness of generation, higher temperature means more variability in generated data.',
  },
  {
    label: 'Max length',
    field: 'max_tokens',
    value: 512,
    range: [1, 1024],
    advanced: false,
    tooltip:
      'Controls max length of generation in # of tokens, depending on the model each token is 2-4 characters. Smaller values can speed up generation.',
  },
  {
    label: 'Top K',
    field: 'top_k',
    value: 43,
    range: [1, 50],
    advanced: true,
    tooltip:
      'Number of highest probability tokens to keep for generation. Along with a higher temperature, larger `Top K` creates more variability in generated data.',
  },
  {
    label: 'Top P',
    field: 'top_p',
    value: 0.9,
    range: [0, 1],
    advanced: true,
    tooltip:
      'Only most probable tokens with probabilities that add up to `Top P` are kept for generation. Along with a higher temperature, `Top P` closer to 1.0 creates more variability in generated data.',
  },
] as const;

export const fieldsMap: Record<ModelMode, readonly Field[]> = {
  [ModelMode.NAVIGATOR]: TABULAR_HYPERPARAM_FIELDS,
  [ModelMode.NATURAL]: GPTX_HYPERPARAM_FIELDS,
};
