import React from 'react';
import { User } from 'src/api/types/User';
import Formatters from './formatters';
import { logger } from './logger';

class ZenDeskError extends Error {
  constructor(public error: unknown) {
    super('ZenDesk Error');
  }
}

/** hides the ZenDesk widget, removing the chat window Floating Action Button to the corner of the app */
export const hideZenDeskWidget = () => {
  try {
    window.zE?.('webWidget', 'hide');
  } catch (error: unknown) {
    logger.error(new ZenDeskError(error));
  }
};

/** shows the ZenDesk widget, adding the chat window Floating Action Button to the corner of the app */
export const showZenDeskWidget = () => {
  try {
    window.zE?.('webWidget', 'show');
  } catch (error: unknown) {
    logger.error(new ZenDeskError(error));
  }
};

/** opens the ZenDesk widget */
export const openZenDeskWidget = () => {
  try {
    window.zE?.('webWidget', 'open');
  } catch (error: unknown) {
    logger.error(new ZenDeskError(error));
  }
};

/** closes the ZenDesk widget */
export const closeZenDeskWidget = () => {
  try {
    window.zE?.('webWidget', 'close');
  } catch (error: unknown) {
    logger.error(new ZenDeskError(error));
  }
};

/** prefill the ZenDesk widget with the user's name and email */
export const prefillZenDeskWidget = (profile: User) => {
  try {
    window.zE?.('webWidget', 'prefill', {
      name: { value: Formatters.User.name(profile) },
      email: {
        value: profile.email,
        readOnly: true,
      },
    });
  } catch (error: unknown) {
    logger.error(new ZenDeskError(error));
  }
};

export const useHideZenDeskWidget = () => {
  React.useEffect(() => {
    hideZenDeskWidget();
    return () => showZenDeskWidget();
  }, []);
};
