import { Components, Theme } from '@mui/material';

const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      border: `1px solid ${theme.palette.border.default}`,
      borderRadius: theme.shape.radii.large,
      background: theme.palette.background.default,
      minWidth: 200,
    }),
  },
};

export default MuiMenu;
