const ERROR_IDS = {
  UNHANDLED: 'unhandled',
  SYN_INVALID_RECORDS: 'syn_invalid_records',
  MAX_RUNTIME: 'max_runtime',
  SYN_TOO_FEW_RECORDS: 'syn_too_few_records',
  SYN_MISSING_DELIM: 'syn_missing_delim',
  LOAD_DATA: 'load_data',
  DOWNGRADE_SCHEDULED: 'downgrade_scheduled',
  SUBSCRIPTION_INCOMPLETE: 'subscription_incomplete',
} as const;

export type ERROR_IDS_TYPE = (typeof ERROR_IDS)[keyof typeof ERROR_IDS];

export default ERROR_IDS;
