import * as React from 'react';
import { useInviteMemberMutation } from 'src/api';
import { Model } from 'src/api/types/Model';
import useActions from 'utils/useActions';
import { ModelCompletionDialog } from '../../Dialog';
import modelDialogDuck from '../../modelDialogDuck';

export const Shower = ({ model }: { model: Model }) => {
  const { removeModelFromDialogList } = useActions(modelDialogDuck.actions);
  const [dialogIsOpen, setDialogIsOpen] = React.useState(true);
  const { report, project_guid } = model;
  const score =
    report?.synthetic_text_data_quality_score ||
    report?.synthetic_data_quality_score;

  React.useEffect(() => {
    // on "willUnMount", remove from the dialog list
    return () => {
      removeModelFromDialogList(model.uid);
    };
  }, [model.uid, removeModelFromDialogList]);

  const [inviteUser] = useInviteMemberMutation();
  const handleInviteSubmit = React.useCallback(
    invite => inviteUser({ ...invite, guid: project_guid }),
    [inviteUser, project_guid]
  );

  if (!score) {
    // this will only happen when we think a model should have a report
    // but it comes back from the server without it.
    // It's important to note that we're including the `report` field in the query
    // done by the manager component
    return null;
  }

  return (
    <ModelCompletionDialog
      isOpen={dialogIsOpen}
      model={model}
      score={score}
      onClose={() => {
        setDialogIsOpen(false);
        removeModelFromDialogList(model.uid);
      }}
      onInviteSubmit={handleInviteSubmit}
    />
  );
};
