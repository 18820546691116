import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { milliseconds } from 'date-fns';
import LocalStorageHelper from 'common/localStorageHelper';

const FOUR_WEEKS_IN_MILLISECONDS = milliseconds({ weeks: 4 });

export type ModelWatchList = Record<
  string,
  { createdAt: number; projectGuid: string }
>;
/**
 * For each model, we need it's guid, as well as when it was created and the project it is associated with.
 * We need the project guid because we cant query models without it.
 */
type State = {
  modelsToWatch: ModelWatchList;
  modelsToShowDialogFor: ModelItemPayload[];
};

type ModelItemPayload = {
  modelUid: string;
  projectGuid: string;
  createdAt: number;
};

const initialState: State = {
  modelsToWatch: {},
  modelsToShowDialogFor: [],
};

const SLICE_NAME = 'modelCompletionDialog';

const hydratedInitialStateFromLocalStorage = () => {
  const { modelsToWatch, modelsToShowDialogFor } =
    LocalStorageHelper.hydrateState(SLICE_NAME, initialState);

  // we filter out anything older than 4 weeks
  const now = Date.now();
  const watchKeys = modelsToWatch && Object.keys(modelsToWatch);
  const filteredWatch = {};

  watchKeys.forEach(k => {
    const distance = now - modelsToWatch[k].createdAt;
    if (distance < FOUR_WEEKS_IN_MILLISECONDS) {
      filteredWatch[k] = modelsToWatch[k];
    }
  });

  const filteredDialog = modelsToShowDialogFor.filter((m: ModelItemPayload) => {
    const distance = now - m.createdAt;
    return distance < FOUR_WEEKS_IN_MILLISECONDS;
  });

  return {
    modelsToWatch: filteredWatch,
    modelsToShowDialogFor: filteredDialog,
  };
};

/**
 * IMPORTANT: If you add a new action to this reducer, make sure we update the localStorage
 * listener logic, `updateModelDialogLocalStorage` below this slice.
 */
const modelDialogSlice = createSlice({
  name: SLICE_NAME,
  initialState: hydratedInitialStateFromLocalStorage,
  reducers: {
    /**
     * add a newly created model to the list to poll server for completion status
     */
    addModelToWatchList: (state, action: PayloadAction<ModelItemPayload>) => {
      const { modelUid, projectGuid, createdAt } = action.payload;
      state.modelsToWatch[modelUid] = { createdAt, projectGuid };
    },
    /**
     * model completed in an eligible way,
     * so now we want to show a dialog for it.
     * there could be multiple models queued for Dialog, so we
     * keep a list in case, operating on a FIFO system rather than
     * continuing to sort on createdAt.
     */
    promoteModelToDialogList: (
      state,
      action: PayloadAction<ModelItemPayload>
    ) => {
      const { modelUid, projectGuid, createdAt } = action.payload;
      delete state.modelsToWatch[action.payload.modelUid];
      state.modelsToShowDialogFor.push({ modelUid, projectGuid, createdAt });
    },
    /**
     *  this is for models that become uneligible:
     *  e.g., they are "cancelled" by the user or become too old to action on.
     *  for models that complete and we need to show a dialog for,
     *  use `moveModelFromWatchListToDialogList` above to transition item.
     */
    removeModelFromWatchList: (state, action: PayloadAction<string>) => {
      delete state.modelsToWatch[action.payload];
    },
    /**
     * when we show the dialog, remove it from the list
     */
    removeModelFromDialogList: (state, action: PayloadAction<string>) => {
      state.modelsToShowDialogFor = state.modelsToShowDialogFor.filter(
        item => item.modelUid !== action.payload
      );
    },
  },
});

export default modelDialogSlice;

/**
 * These two functions are used in store.ts to invoke a listener middleware.
 * This listener middleware will update localstorage any time we update
 * the reducer state, so that we can continue to show modals for completed models
 * when the user returns to the site.
 */
export const updateModelDialogLocalStorage = (action, listenerApi) => {
  const sliceName = modelDialogSlice.name;
  // on the action, update localstorage. can debounce/throtle  or do on request idle callback
  const updatedState = listenerApi.getState()[sliceName];
  LocalStorageHelper.persistState(sliceName, updatedState);
};
// update this list with any new actions,
// so we can manage LS updates using `updateModelDialogLocalStorage` above
export const modelDialogLocalStorageMatcher = isAnyOf(
  modelDialogSlice.actions.addModelToWatchList.match,
  modelDialogSlice.actions.promoteModelToDialogList.match,
  modelDialogSlice.actions.removeModelFromDialogList,
  modelDialogSlice.actions.removeModelFromWatchList.match
);
