import { Components, Theme } from '@mui/material';

const MuiCardHeader: Components<Theme>['MuiCardHeader'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1, 2),
      borderBottom: `1px solid ${theme.palette.border.default}`,
      backgroundColor: theme.palette.background.secondary,
      minHeight: theme.spacing(10),
    }),
    avatar: ({ theme }) => ({
      marginRight: theme.spacing(2),
      height: theme.spacing(4),
      width: theme.spacing(4),
    }),
    content: {
      flex: 'revert',
      flexGrow: 1,
    },
    action: {
      flex: 'revert',
      marginTop: 'revert',
      marginBottom: 'revert',
      alignSelf: 'center',
    },
  },
};

export default MuiCardHeader;
