import { OracleFormState } from './types';

// Setting the default values for the form
export const initialOracleForm: OracleFormState = {
  name: '',
  type: 'oracle',
  credentials: {
    password: '',
  },
  connection_target_type: 'source',
  config: {
    username: '',
    host: '',
    port: 1521,
    service_name: '',
    instance_name: '',
    params: '',
  },
};
