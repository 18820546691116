import * as React from 'react';
import { IconButton } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import UserAvatar from 'common/UserAvatar';
import { ChevronDown } from 'icons/figma';
import { useLoadBillingQuery, useLoadProfileQuery } from 'src/api';
import UserMenu from './Menu';

type Props = {
  limited: boolean;
};

export const UserButtonMenu = ({ limited = false }: Props) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });
  const { data: user } = useLoadProfileQuery();
  const billingQuery = useLoadBillingQuery();

  const { billing_tier } = billingQuery?.data?.me || {};

  if (!user) {
    return null;
  }

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        aria-label="User Profile"
        aria-controls="userMenu"
        aria-haspopup="true"
      >
        <UserAvatar user={user} sx={{ mr: 1 }} />
        <ChevronDown height={16} width={16} />
      </IconButton>
      <UserMenu
        popupState={popupState}
        limited={limited}
        billingTier={billing_tier}
      />
    </>
  );
};

export default UserButtonMenu;
