import { ConnectionTypes } from 'src/api/pilot';
import {
  ConnectionTargetType,
  ConnectionTargetTypeBoth,
  FormErrors,
} from 'src/Console/Connections/utils';

export enum AuthStrategy {
  ACCESS_KEY = 'access_key',
  ROLE_ARN = 'role_arn',
}

export type S3Connection = {
  name: string;
  type: 's3';
  credentials?: ConnectionTypes['S3Credentials'];
  config?: ConnectionTypes['S3Config'];
  connection_target_type: ConnectionTargetType | ConnectionTargetTypeBoth;
  auth_strategy?: AuthStrategy;
};

export type S3FormState = {
  name: string;
  type: 's3';
  connection_target_type: ConnectionTargetType | ConnectionTargetTypeBoth;
  auth_strategy: AuthStrategy;
} & S3Connection['config'] &
  S3Connection['credentials'];

// Error type including any possible field for S3
export type S3ConnectionErrors = FormErrors<S3FormState>;
