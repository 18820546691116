import Cookies from 'js-cookie';
import { USER_TOKEN } from 'constants/cookieKeys';

export type JWTToken = {
  exp: number;
  email: string;
  username: string;
  _id: string;
  guid: string;
  new_signup: boolean;
};

const getBearerToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    urlParams.get('token') ||
    (JSON.parse(Cookies.get(USER_TOKEN) || 'false') as string)
  );
};

export const setToken = (token: string) => {
  Cookies.set(USER_TOKEN, JSON.stringify(token), { secure: true });
};

export const clearToken = () => {
  Cookies.remove(USER_TOKEN);
};

/**
 * Use as http header:
 * ```javascript
 * req.headers.set('Authorization', Authorization())
 * ```
 */
export const getAuthorization = (): string => {
  if ('Cypress' in window) {
    return localStorage.getItem('cypressToken') ?? 'missing-token';
  }

  return `Bearer ${getBearerToken()}`;
};
