import { RumEvent } from '@datadog/browser-rum';

/**
 *
 * -- Data Dog utilities --
 *
 */

const ERRORS_TO_IGNORE = [
  /**
   * seems to be an error from Grammarly extension
   */
  'originalPrompt',
  /**
   * "font-src: csp_violation: 'chrome-extension' blocked by 'font-src' directive"
   *  extension seems to be trying to load fonts
   */
  'chrome-extension',
  /**
   * LinkedIn Conversion pixel errors
   */
  'window.lintrk',
  /**
   * Marketing tool, requests fail a lot, probably network connectivity.
   * next two strings.
   */
  'segment performance metrics',
  'analytics.js',
  /**
   * Marketing tool...
   */
  'Appcues snippet included twice',
  /**
   * Outlook scanning links in emails
   * full error message looks like
   * 'Object Not Found Matching Id:2, MethodName:update, ParamCount:4'
   * but the id number can change
   */
  'MethodName:update, ParamCount:',
  /**
   * Internal monaco-editor error, most likely related to a component getting unmounted.
   */
  'Canceled: Canceled',
  /**
   * Our API Status (in the Footer) occasionally returns an
   * opaque error. We've spent time debugging and have decided its
   * ok to ignore for now and manually check its still working occasionally.
   */
  'StatusPage returned an error',
];

/**
 * Used in beforeSend to determine if we want to ignore an error.
 * Ignoring an error here means that it will never be sent to DD, so please use
 * cautiously.
 *
 * @param event RumEvent
 * @returns boolean
 */
const shouldKeep = (event: RumEvent) => {
  if (event.type === 'error') {
    const isErrorToIgnore = ERRORS_TO_IGNORE.some(errStr =>
      event.error.message.includes(errStr)
    );
    return !isErrorToIgnore;
  }
  return true;
};

/**
 *
 * We add the `beforeSend` function to our DataDog init configuration. This function allows us to review
 * every error before we send it to DD to discard errors, add context, etc.
 * https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#discard-a-rum-event
 * https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event
 * @param event RumEvent
 * @param context RumContext (depends on type of event)
 * @returns
 */
export const beforeSend = (event: RumEvent) => {
  return shouldKeep(event);
};
