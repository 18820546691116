import * as React from 'react';
import { Link as InternalLink } from 'react-router-dom';
import { Alert, IconButton, styled } from '@mui/material';
import Cookies from 'js-cookie';
import BILLING_TIER from 'common/constants/billingTier';
import LinkButton, { LinkButtonProps } from 'common/input/LinkButton';
import { DISMISS_BILLING_BANNER } from 'constants/cookieKeys';
import { X } from 'icons/figma';
import { useLoadBillingQuery } from 'src/api';
import { USER_BILLING_ROUTE } from 'src/routes';
import Formatters from 'utils/formatters';

const WARNING_THRESHOLD = 0.75;
type StyledLinkButtonProps = {
  severity: 'error' | 'warning';
} & LinkButtonProps;

const StyledLinkButton = styled(LinkButton, {
  shouldForwardProp: prop => prop !== 'severity',
})<StyledLinkButtonProps>(({ severity, theme }) => ({
  color: theme.palette[severity][700],
  ':hover': {
    color: theme.palette[severity][800],
  },
  ':active': {
    color: theme.palette[severity][900],
  },
}));

const BillingBanner = () => {
  const billingQuery = useLoadBillingQuery();
  const {
    billing_tier = 0,
    credits_used_cycle = 0,
    /** @note these values have proven unreliable and need to be updated on the BE in some future epic yet undefined
    // credits_used_warn = false,
    // credits_used_exhaust = false,
    // credits_extra_warn = false,
    // credits_extra_exhaust = false, */
    credits_tier = 0,
    service_limits: {
      max_extra_free_credits = 0, // sales can add these to incentivize usage
      max_extra_credits = 0, // these are pay-to-consume overage credits, the limit is there to prevent runaway costs
    } = {},
  } = billingQuery.data?.me || {};

  const freeTierUser = billing_tier === BILLING_TIER.FREE;
  const userHasNoCreditLimit =
    billing_tier >= BILLING_TIER.TEAM || // ENTERPRISE & TEAM users have no warnings
    max_extra_credits === -1; // non-enterprise users can have their soft-limit removed via `max_extra_credits === -1`
  const creditsIncluded = credits_tier + max_extra_free_credits;
  const creditsAvailable = creditsIncluded + max_extra_credits;
  const percentageUsedAlready = Math.min(
    1,
    credits_used_cycle / creditsIncluded
  );
  const percentageUsedOfExtraCredits = Math.min(
    1,
    credits_used_cycle / creditsAvailable
  );
  const isDelinquent = userHasNoCreditLimit
    ? false
    : credits_tier + max_extra_free_credits + max_extra_credits <=
      credits_used_cycle;

  const isWarning =
    isDelinquent || userHasNoCreditLimit
      ? false
      : credits_used_cycle >= creditsIncluded * WARNING_THRESHOLD &&
        !isDelinquent;

  // Check cookie for warning dismissal.
  const [dismissed, setDismissed] = React.useState(
    Cookies.get(DISMISS_BILLING_BANNER) === 'true'
  );

  // Dismiss Banner (unavailable for delinquent status);
  const handleDismiss = () => {
    setDismissed(true);
    Cookies.set(DISMISS_BILLING_BANNER, JSON.stringify(true), { secure: true });
  };

  // Check for delinquency undismiss banner if delinquent.
  React.useEffect(() => {
    if (isDelinquent) {
      setDismissed(false);
    }
  }, [isDelinquent]);

  const showBanner =
    billingQuery.isSuccess && !dismissed && (isWarning || isDelinquent);

  return showBanner ? (
    <Alert
      icon={false}
      severity={isDelinquent ? 'error' : 'warning'}
      action={
        <>
          {freeTierUser ? (
            <StyledLinkButton
              severity={isDelinquent ? 'error' : 'warning'}
              component={InternalLink}
              to={USER_BILLING_ROUTE.path}
            >
              Add payment method
            </StyledLinkButton>
          ) : (
            <StyledLinkButton
              severity={isDelinquent ? 'error' : 'warning'}
              component="a"
              href="mailto:support@gretel.ai?subject='I would like to increase my usage limits'"
            >
              Contact support
            </StyledLinkButton>
          )}
          {isDelinquent ? null : (
            <IconButton onClick={handleDismiss} size="small" sx={{ ml: 1 }}>
              <X />
            </IconButton>
          )}
        </>
      }
    >
      {
        freeTierUser
          ? isDelinquent
            ? 'Your account is paused. Please add a payment method to continue using Gretel services.' // Tier 0 delinquent
            : `You have used ${Formatters.Number.percent(
                percentageUsedAlready
              )} of your monthly credits. Add a payment method to avoid service interruptions.` // Tier 0 warning
          : isDelinquent
            ? 'You have used all your available monthly tier credits. Please contact support to increase your usage limits.' // Tier 1+ delinquent
            : `You have used ${Formatters.Number.percent(
                percentageUsedOfExtraCredits
              )} of your monthly credits. Please contact support to increase your usage limits.` // Tier 1+ warning
      }
    </Alert>
  ) : null;
};

export default BillingBanner;
