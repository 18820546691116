import { Components, darken, iconButtonClasses, Theme } from '@mui/material';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    text: true;
  }
}

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    color: 'text',
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      let color: string;
      let backgroundColor: string;
      let hoverBackgroundColor: string;
      let activeBackgroundColor: string;
      switch (ownerState?.color) {
        case 'inherit':
          color = 'inherit';
          backgroundColor = 'inherit';
          hoverBackgroundColor = 'inherit';
          activeBackgroundColor = 'inherit';
          break;
        case 'secondary':
          color = theme.palette.primary.main;
          backgroundColor = theme.palette.primary.contrastText;
          hoverBackgroundColor = darken(
            backgroundColor,
            theme.palette.action.hoverOpacity
          );
          activeBackgroundColor = darken(
            backgroundColor,
            theme.palette.action.activatedOpacity
          );
          break;
        case 'default':
        case 'text':
          color = theme.palette.text.primary;
          backgroundColor = 'inherit';
          hoverBackgroundColor = darken(
            theme.palette.primary[50],
            theme.palette.action.hoverOpacity
          );
          activeBackgroundColor = darken(
            theme.palette.primary[50],
            theme.palette.action.activatedOpacity
          );
          break;
        default:
          color = theme.palette.common.white;
          backgroundColor = theme.palette[ownerState?.color || 'primary'].main;
          hoverBackgroundColor = darken(
            backgroundColor,
            theme.palette.action.hoverOpacity
          );
          activeBackgroundColor = darken(
            backgroundColor,
            theme.palette.action.activatedOpacity
          );
      }
      return {
        color,
        backgroundColor,
        ...theme.typography.button2,
        borderRadius: theme.shape.radii.medium,
        '& svg': {
          color: 'inherit',
        },
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
        ':focus,.Mui-focusVisible': {
          zIndex: theme.zIndex.tooltip, // Elevate element to avoid box-shadow clipping caused by nearby elements.
          boxShadow: theme.palette.action.focusBoxShadow,
        },
        ':hover': {
          backgroundColor: hoverBackgroundColor,
        },
        ':active': {
          backgroundColor: activeBackgroundColor,
        },
        '& > *': {
          // prevents child elements from registering clicks rather than the button itself
          // to help with Google Analytics event delegation
          pointerEvents: 'none',
        },
        [`&.${iconButtonClasses.disabled}`]: {
          backgroundColor: theme.palette.grey[100],
        },
      };
    },
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(8),
      padding: theme.spacing(1, 2),
      '&>svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(10),
      padding: theme.spacing(2, 3),
      '&>svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
    }),
    sizeLarge: ({ theme }) => ({
      height: theme.spacing(12),
      width: theme.spacing(12),
      padding: theme.spacing(3, 4),
      '&>svg': {
        height: theme.spacing(10),
        width: theme.spacing(10),
      },
    }),
  },
};

export default MuiIconButton;
