import { BigQueryFormState } from './types';

export const initialBigQueryForm: BigQueryFormState = {
  name: '',
  type: 'bigquery',
  credentials: {
    private_key_json: '',
  },
  connection_target_type: 'source',
  config: {
    dataset: '',
    project_id: '',
    service_account_email: '',
  },
};
