/**
 *  -- LocalStorageHelper --
 * A group of functions to help with setting and retrieving data
 * from localStorage. Designed largely to be used with setting and retrieving
 * data for redux slices. See model completion dialogs for example.
 *
 * Also note the logic in utils/auth to manage setting and reseting localstorage
 * on logout/login
 */

const hydrateState = (
  key: string,
  defaultInitialState: Record<string, unknown>
) => {
  const persistedState = getByKey(key);
  if (persistedState && typeof persistedState === 'object') {
    // spread them so we are sure to have all the starting keys we want.
    return { ...defaultInitialState, ...persistedState };
  } else {
    return defaultInitialState;
  }
};

const persistState = (key: string, state: Record<string, unknown>) => {
  try {
    const stringified = JSON.stringify(state);
    localStorage.setItem(key, stringified);
  } catch (err) {
    // can throw error due to privacy settings
    return undefined;
  }
};

const setItem = (k: string, v: string) => {
  try {
    localStorage.setItem(k, v);
  } catch (err) {
    // can throw error due to privacy settings
    return undefined;
  }
};

const getByKey = (key: string) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData) {
      return JSON.parse(serializedData);
    }
  } catch (err) {
    // can throw error due to privacy settings
    return undefined;
  }
};

// remove localStorage associated with app.
// can pass optional key to remove specific items.
const clear = (key?: string) => {
  try {
    if (key) {
      localStorage.removeItem(key);
    } else {
      localStorage.clear();
    }
  } catch (err) {
    // can throw error due to privacy settings
    return undefined;
  }
};

/**
 * In utils/auth, when logging in a user,
 * we check to see if they are a different user than we previously
 * stored data in LS for. If they are, we clear LS and set the new controlKey.
 */
const CONTROL_KEY = 'controlKey';
const resetIfChangedUser = (userGuid: string) => {
  const existingControlKey = getByKey(CONTROL_KEY);
  if (existingControlKey && existingControlKey !== userGuid) {
    clear();
    setItem('controlKey', userGuid);
  }
  if (!existingControlKey) {
    setItem('controlKey', userGuid);
  }
};

export default {
  hydrateState,
  persistState,
  clear,
  resetIfChangedUser,
  setItem,
};
