import * as React from 'react';
import { Box, Grid, Link, SvgIcon, Typography } from '@mui/material';
import { DISCORD_GROUP } from 'common/constants/urls';
import { Elink as ExternalLink } from 'icons/figma';
import { openZenDeskWidget } from 'utils/zenDesk';

export const FeelingStuck = () => {
  return (
    <Grid item sx={{ m: 0, p: 0 }}>
      <Typography variant="body1Strong">Feeling stuck?</Typography>
      <Box component="ul" sx={{ m: 0, pl: 6, pt: 2 }}>
        <Typography component="li" sx={{ pb: 1 }}>
          Ask for help from our&nbsp;
          <Link variant="body1Medium" href={DISCORD_GROUP}>
            Discord Community
            <SvgIcon
              sx={{
                ml: 1,
                top: theme => theme.spacing(0.5),
                position: 'relative',
                fontSize: 16,
              }}
            >
              <ExternalLink />
            </SvgIcon>
          </Link>
          .
        </Typography>
        <Typography component="li">
          Submit a&nbsp;
          <Link variant="body1Medium" onClick={openZenDeskWidget}>
            support ticket
          </Link>
          &nbsp;and we will get back to you ASAP.
        </Typography>
      </Box>
    </Grid>
  );
};
