import { Connection } from 'src/api/pilot/connections';
import { ConnectionTargetType } from 'src/Console/Connections/utils';
import { AuthStrategy, AzureConnection, AzureFormState } from './types';

export const formToApi = (form: AzureFormState): Partial<Connection> => {
  const transformed: Partial<Connection> = {
    name: form.name,
    type: form.type,
    config: {
      account_name: form.account_name,
      default_container: form.default_container,
    },
    auth_strategy: form.auth_strategy,
    connection_target_type: form.connection_target_type,
  };

  switch (form.auth_strategy) {
    case AuthStrategy.ACCESS_KEY:
      if (form.access_key) {
        transformed.credentials = {
          access_key: form.access_key,
        };
      }
      break;
    case AuthStrategy.ENTRA_ID:
      if (form.entra_password) {
        transformed.credentials = {
          entra_password: form.entra_password,
        };
      }
      transformed.config = {
        ...transformed.config,
        entra_config: {
          client_id: form.client_id,
          tenant_id: form.tenant_id,
          username: form.username,
        },
      };
      break;
    case AuthStrategy.SAS_TOKEN:
      if (form.sas_token) {
        transformed.credentials = {
          sas_token: form.sas_token,
        };
      }
      break;
  }

  return transformed;
};

export const apiToForm = (connection: Connection): AzureFormState => {
  // TS not happy about the nested objects in Connection
  // being type 'object'
  const conn = connection as AzureConnection;
  const transformed: AzureFormState = {
    name: conn.name,
    type: conn.type as 'azure',
    account_name: conn.config.account_name,
    default_container: conn.config.default_container,
    access_key: conn.credentials?.access_key ?? '',
    entra_password: conn.credentials?.entra_password ?? '',
    sas_token: conn.credentials?.sas_token ?? '',
    client_id: conn.config?.entra_config?.client_id ?? '',
    tenant_id: conn.config?.entra_config?.tenant_id ?? '',
    username: conn.config?.entra_config?.username ?? '',
    connection_target_type: conn.connection_target_type as ConnectionTargetType,
    // auth_strategy should be set but falls back on earlier logic for backward compatibility
    auth_strategy:
      // conn.auth_strategy default to an empty string so we use the || operator instead of ??
      conn.auth_strategy ||
      (conn.config.entra_config
        ? AuthStrategy.ENTRA_ID
        : AuthStrategy.ACCESS_KEY),
  };

  return transformed;
};
