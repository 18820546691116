import { Components, Theme } from '@mui/material';

const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) =>
      !ownerState.disableGutters && {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(12),
          paddingRight: theme.spacing(12),
        },
      },
  },
};

export default MuiContainer;
