import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Chip } from '@mui/material';
import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';
import { getRadians } from 'utils/math';
import { useThemeForChartPalettes } from './util';

import { scaleLinear, scaleQuantize } from '@visx/scale';

/**
 * Pointer Element
 * width: 20
 * height: 96
 * @param props JSX.IntrinsicElements['g']
 * @returns https://developer.mozilla.org/en-US/docs/Web/SVG/Element/g
 */
const Pointer = (props: JSX.IntrinsicElements['g']) => (
  <g {...props}>
    <path
      d="M8.92374 1.99709C8.96676 1.43453 9.43579 1 9.99999 1C10.5642 1 11.0332 1.43453 11.0762 1.99709L16.9502 78.8101C18.8307 80.6283 20 83.1776 20 86C20 91.5229 15.5228 96 9.99998 96C4.47714 96 -1.58275e-05 91.5229 -1.48619e-05 86C-1.43684e-05 83.1776 1.16926 80.6283 3.04982 78.8101L8.92374 1.99709ZM10 92C13.3137 92 16 89.3137 16 86C16 82.6863 13.3137 80 10 80C6.68629 80 4 82.6863 4 86C4 89.3137 6.68629 92 10 92Z"
      fill="currentColor"
    />
  </g>
);

export type DialProps = {
  data?: Array<number>;
  palette?: Array<string>;
  domain?: [number, number];
  value?: number;
  label?: string;
  chipSize?: 'small' | 'medium' | 'large';
};

const Wrapper = ({
  data = [0.2, 0.2, 0.2, 0.2, 0.2],
  palette,
  value = 0,
  label = '',
  domain = [0, 100],
  chipSize = 'large',
}: DialProps) => {
  const redToGreenPalette = useThemeForChartPalettes();
  const color = scaleQuantize({
    domain: domain,
    range: palette || redToGreenPalette,
  });
  return (
    <>
      <AutoSizer style={{ minWidth: '90px', minHeight: '90px' }}>
        {({ height, width }) => (
          <Dial
            width={width}
            height={height}
            value={value}
            data={data}
            palette={palette || redToGreenPalette}
            domain={domain}
          />
        )}
      </AutoSizer>
      {label && (
        <Chip
          label={label}
          size={chipSize}
          sx={theme => ({
            verticalAlign: 'baseline',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '&.MuiChip-root': {
              backgroundColor: color(value),
              color: theme.palette.getContrastText(color(value)),
            },
          })}
        />
      )}
    </>
  );
};

type InnerDialProps = Omit<DialProps, 'label'> &
  Required<Pick<DialProps, 'value'>> & {
    width: number;
    height: number;
  };

const Dial = ({
  data,
  value,
  palette,
  domain,
  width,
  height,
}: InnerDialProps) => {
  // Starting and ending angles, in degrees, from 12 'o clock being 0deg
  const startAngle = -120;
  const endAngle = 120;

  // Scale to get pointer degrees based on value
  const degrees = scaleLinear({
    range: [startAngle, endAngle],
    domain: domain,
  });

  // SVG calculations
  const radius: number = Math.min(width, height) / 2;
  const centerY: number = height / 2;
  const centerX: number = width / 2;

  const donutThickness = Math.min(height / 10, width / 12.85);

  // Pointer drawn width/height
  const DIAL_WIDTH = 20;
  const DIAL_HEIGHT = 96;
  const pointerMultiplier: number =
    (radius - donutThickness / 2) / (DIAL_HEIGHT - DIAL_WIDTH / 2);

  const pointerDegrees: number = degrees(value);
  const emptyHeight: number = Math.sin(getRadians(startAngle - 90)) * radius;

  return (
    <svg width={width} height={height - emptyHeight}>
      <Group top={centerY} left={centerX}>
        {/* draw the color donut sections */}
        <Pie
          data={data}
          pieValue={d => d}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          startAngle={getRadians(startAngle)}
          endAngle={getRadians(endAngle)}
          pieSortValues={null}
        >
          {pie =>
            pie.arcs.map((arc, index) => (
              <path
                key={`arc-${index}`}
                d={pie.path(arc) as string}
                fill={palette?.[index]}
              />
            ))
          }
        </Pie>
        {/* Draw Pointer, translate by negative half it's width to center x, then by pointerRadius for y */}
        {/* Rotate to the degrees(value) specifying the center as the transformOrigin */}
        <Pointer
          transform={`
          scale(${pointerMultiplier})
          translate(-${DIAL_WIDTH / 2}, -${DIAL_HEIGHT - DIAL_WIDTH / 2})
          rotate(${pointerDegrees}, ${DIAL_WIDTH / 2}, ${
            DIAL_HEIGHT - DIAL_WIDTH / 2
          })
          `}
        />
      </Group>
    </svg>
  );
};

Wrapper.displayName = 'Dial';

export default Wrapper;
