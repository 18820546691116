import { Components, Theme } from '@mui/material';

const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
    }),
    head: ({ theme }) => ({ ...theme.typography.body2Strong }),
  },
};

export default MuiTableCell;
