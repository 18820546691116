import { Connection } from 'src/api/pilot/connections';
import { ConnectionTargetType } from 'src/Console/Connections/utils';
import { DatabricksConnection, DatabricksFormState } from './types';

export const formToApi = (form: DatabricksFormState): Partial<Connection> => {
  const transformed: Partial<Connection> = {
    name: form.name,
    type: form.type,
    config: {
      server_hostname: form.server_hostname,
      http_path: form.http_path,
      catalog: form.catalog,
      schema: form.schema,
      params: form.params,
      port: form.port,
    },
    connection_target_type: form.connection_target_type,
  };

  if (form.personal_access_token) {
    transformed.credentials = {
      personal_access_token: form.personal_access_token,
    };
  }

  return transformed;
};

export const apiToForm = (connection: Connection): DatabricksFormState => {
  // TS not happy about the nested objects in Connection
  // being type 'object'
  const conn = connection as DatabricksConnection;
  const transformed: DatabricksFormState = {
    name: conn.name,
    type: conn.type as 'databricks',
    personal_access_token: conn.credentials?.personal_access_token || '',
    server_hostname: conn.config?.server_hostname,
    http_path: conn.config?.http_path,
    catalog: conn.config?.catalog,
    schema: conn.config?.schema,
    params: conn.config?.params,
    port: conn.config?.port,
    connection_target_type: conn.connection_target_type as ConnectionTargetType,
  };

  return transformed;
};
