import { Components, Theme } from '@mui/material';
import { ChevronDown } from 'icons/figma';

const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: ChevronDown,
  },
  styleOverrides: {
    icon: ({ theme }) => ({
      height: theme.spacing(4),
      width: theme.spacing(4),
      right: theme.spacing(3),
    }),
  },
};

export default MuiSelect;
