import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontWeightLight: React.CSSProperties['fontWeight'];
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
  }
}

declare module '@mui/material/styles' {
  type GretelTypographyOverrides = Pick<
    React.CSSProperties,
    'fontFamily' | 'fontWeight' | 'lineHeight' | 'fontSize'
  >;

  interface TypographyVariants {
    h1: GretelTypographyOverrides;
    h2: GretelTypographyOverrides;
    h3: GretelTypographyOverrides;
    h4: GretelTypographyOverrides;
    h5: GretelTypographyOverrides;
    h6: GretelTypographyOverrides;
    subtitle1: GretelTypographyOverrides;
    subtitle2: GretelTypographyOverrides;
    body1: GretelTypographyOverrides;
    body2: GretelTypographyOverrides;
    caption: GretelTypographyOverrides;
    overline: GretelTypographyOverrides;
    display1: GretelTypographyOverrides;
    display2: GretelTypographyOverrides;
    body1Medium: GretelTypographyOverrides;
    body1Strong: GretelTypographyOverrides;
    body2Medium: GretelTypographyOverrides;
    body2Strong: GretelTypographyOverrides;
    code: GretelTypographyOverrides;
    button1: GretelTypographyOverrides;
    button2: GretelTypographyOverrides;
  }

  // allow configuration using 'createTheme'
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    h4?: React.CSSProperties;
    h5?: React.CSSProperties;
    h6?: React.CSSProperties;
    subtitle1?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    caption?: React.CSSProperties;
    overline?: React.CSSProperties;
    display1?: React.CSSProperties;
    display2?: React.CSSProperties;
    body1Medium?: React.CSSProperties;
    body1Strong?: React.CSSProperties;
    body2Medium?: React.CSSProperties;
    body2Strong?: React.CSSProperties;
    code?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    body1Medium: true;
    body1Strong: true;
    body2Medium: true;
    body2Strong: true;
    code: true;
    button: false;
    button1: true;
    button2: true;
  }
}

export const typography: TypographyOptions = {
  htmlFontSize: 16,
  fontSize: 16,
  fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
  fontWeightLight: 'initial',
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '40px',
    fontSize: '32px',
  },
  h2: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '30px',
    fontSize: '24px',
  },
  h3: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '20px',
  },
  h4: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '22px',
    fontSize: '18px',
  },
  h5: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '14px',
  },
  h6: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: '12px',
  },
  body1: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: '16px',
  },
  body1Medium: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
  },
  body1Strong: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '16px',
  },
  body2: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: '14px',
  },
  body2Medium: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: '14px',
  },
  body2Strong: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
  },
  code: {
    fontFamily:
      "'Source Code Pro', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '14px',
  },
  button1: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '16px',
  },
  button2: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '14px',
  },
  subtitle1: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '22px',
    fontSize: '18px',
  },
  subtitle2: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: '16px',
  },
  overline: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '14px',
  },
  caption: {
    fontFamily: "'Inter', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: '14px',
  },
  display1: {
    fontFamily: "'Apercu Pro', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 700,
    lineHeight: '40px',
    fontSize: '32px',
  },
  display2: {
    fontFamily: "'Apercu Pro', Segoe UI, system-ui, -apple-system, sans-serif",
    fontWeight: 700,
    lineHeight: '30px',
    fontSize: '24px',
  },
};
