import { Components, Theme } from '@mui/material';

const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2, 0),
    }),
  },
};

export default MuiAccordionDetails;
