import { Components, Theme } from '@mui/material';

const MuiButtonBase: Components<Theme>['MuiButtonBase'] = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button1,
      textTransform: 'none',
    }),
  },
};

export default MuiButtonBase;
