import { Components, Theme } from '@mui/material';

const MuiAlertTitle: Components<Theme>['MuiAlertTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body1Strong,
      padding: 0,
    }),
  },
};

export default MuiAlertTitle;
