import { Components, Theme } from '@mui/material';

const MuiStepConnector: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-completed > .MuiStepConnector-line': {
        borderColor: theme.palette.success[500],
      },
      '&.Mui-active > .MuiStepConnector-line': {
        borderColor: theme.palette.success[500],
      },
    }),
    line: ({ theme }) => ({
      borderColor: theme.palette.border.default,
    }),
    lineVertical: {
      borderLeftWidth: 3,
    },
  },
};

export default MuiStepConnector;
