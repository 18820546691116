import { Components, Theme } from '@mui/material';

const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: 'none',
      // Must be background instead of backgroundColor because MUI adds a linear gradient by default
      background: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.border.default}`,
      color: theme.palette.text.primary,
      padding: theme.spacing(0, 6),
    }),
  },
};

export default MuiAppBar;
