import * as React from 'react';
import { styled, SvgIcon, SvgIconProps } from '@mui/material';

const ANIMATION_DURATION = 1250;

const StyledG = styled('g')(({ theme }) => ({
  animationIterationCount: 'infinite',
  /* An approximation of a sine wave */
  animationTimingFunction:
    'cubic-bezier(0.3633802276324187, 0, 0.6366197723675813, 1)',
  animationDuration: `${ANIMATION_DURATION}ms`,
  animationName: 'move-p1',
  ...[2, 3, 4, 5, 6, 7, 8].reduce(
    (styles, child) => ({
      ...styles,
      [`&:nth-child(${child}), &:nth-child(${child}) circle`]: {
        animationDelay: `${-1 * (ANIMATION_DURATION / 8) * (child - 1)}ms`,
      },
    }),
    {}
  ),
  '@keyframes move-p1': {
    '0%': {
      transform: `translate3d(0, ${theme.spacing(-1)}, 0)`,
      opacity: 0.75,
    },
    '25%': {
      opacity: 1,
    },
    '50%': {
      transform: `translate3d(0, ${theme.spacing(1)}, 0)`,
      opacity: 0.75,
    },
    '75%': {
      opacity: 0.1,
    },
    '100%': {
      transform: `translate3d(0, ${theme.spacing(-1)}, 0)`,
      opacity: 0.75,
    },
  },
}));

const StyledCircle = styled('circle')(() => ({
  animationIterationCount: 'infinite',
  /* An approximation of a sine wave */
  animationTimingFunction:
    'cubic-bezier(0.3633802276324187, 0, 0.6366197723675813, 1)',
  animationDuration: `${ANIMATION_DURATION}ms`,
  animationName: 'shrink',
  transformOrigin: '50%',
  '@keyframes shrink': {
    '0%': {
      transform: 'scale(0.75)',
    },
    '25%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.75)',
    },
    '75%': {
      transform: 'scale(0.5)',
    },
    '100%': {
      transform: 'scale(0.75)',
    },
  },
}));

const QuadDamage = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgIcon ref={ref} fill="currentColor" {...props}>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="1" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="7" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="14" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="20" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="1" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="7" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="14" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
        <StyledG>
          <svg viewBox="0 0 4 4" width="4" height="4" x="20" y="10">
            <StyledCircle cx="2" cy="2" r="2" />
          </svg>
        </StyledG>
      </SvgIcon>
    );
  }
);

QuadDamage.displayName = 'QuadDamage';

const QuadDotsAnimated = QuadDamage;
export default QuadDotsAnimated;
