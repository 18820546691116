import * as React from 'react';
import { Link as InternalLink } from 'react-router-dom';
import { Box, SxProps } from '@mui/material';
import Wordmark from 'src/resources/GretelWordmark.svg';

const logoHeight = 32;
const logoWidth = 78;

export const Logo = ({ sx }: { sx?: SxProps }) => {
  return (
    <Box
      component={InternalLink}
      to="/"
      data-cy="logoLink"
      sx={{
        ...sx,
        display: 'inline-block',
        verticalAlign: 'top',
      }}
    >
      <Wordmark
        width={logoWidth}
        height={logoHeight}
        style={{ display: 'block' }}
      />
    </Box>
  );
};
