import * as React from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  InputProps,
  ListItemIcon,
  ListItemText,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import StyledRouterLink from 'common/StyledRouterLink';
import { ChevronDown, File as FileText } from 'icons/figma';
import { useDataSourcesQuery } from 'src/api';
import { ProjectArtifact } from 'src/api/types/Artifact';
import { Project } from 'src/api/types/Project';
import { SELECTED_PROJECT_DATA_ARTIFACTS } from 'src/routes';
import { getArtifactsSorted } from 'utils/data';
import Formatters from 'utils/formatters';
import useConsoleRoute from 'utils/useConsoleRoute';

type Props = Omit<
  AutocompleteProps<
    ProjectArtifact & { project: Project; id: string },
    false,
    true,
    false
  >,
  'onSelect' | 'renderInput' | 'options'
> & {
  TextFieldProps?: TextFieldProps;
  InputProps?: InputProps;
  project: Project;
  onSelect: (projectArtifact: ProjectArtifact) => void;
  onClear: () => void;
};

const DataSourceSelector = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      project,
      onSelect,
      onClear,
      TextFieldProps,
      InputProps,
      classes: classesProp,
      ...props
    },
    ref
  ) => {
    const { compileLocation } = useConsoleRoute();
    const dataSourcesQuery = useDataSourcesQuery(project.guid, {
      skip: !project.guid,
    });
    const options = React.useMemo(
      () =>
        getArtifactsSorted(dataSourcesQuery.data || {}).map(
          (artifact, index) => ({
            ...artifact,
            project,
            id: `${index}-${artifact.key}`,
          })
        ),
      [project, dataSourcesQuery.data]
    );
    const shouldShowAutoCompleteLoadingMessage =
      dataSourcesQuery.isUninitialized || dataSourcesQuery.isLoading;
    return (
      <Autocomplete
        data-testid="data-source-selector"
        loading={shouldShowAutoCompleteLoadingMessage}
        autoSelect
        blurOnSelect
        forcePopupIcon
        popupIcon={<ChevronDown height={16} width={16} />}
        noOptionsText="No data artifacts found."
        {...props}
        ref={ref}
        onChange={(event, value, reason) =>
          reason === 'clear' ? onClear() : onSelect?.(value)
        }
        options={options}
        groupBy={({ project: { display_name } }) =>
          Formatters.Project.displayName(display_name)
        }
        getOptionLabel={option => Formatters.Project.artifactName(option)}
        renderOption={(props, { key, id }) => (
          <li {...props} key={id}>
            <ListItemIcon>
              <FileText height={16} width={16} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              sx={{
                display: 'block',
                width: 'min-content',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              primary={Formatters.Project.artifactName(key)}
              title={Formatters.Project.artifactName(key)}
            />
          </li>
        )}
        renderInput={({ InputProps: MuiInputProps, ...params }) => (
          <TextField
            helperText={
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.secondary' }}
              >
                Manage your{' '}
                <StyledRouterLink
                  underline="none"
                  to={compileLocation(SELECTED_PROJECT_DATA_ARTIFACTS)}
                >
                  data artifacts
                </StyledRouterLink>{' '}
                from your project.
              </Typography>
            }
            {...TextFieldProps}
            {...params}
            InputProps={{
              ...MuiInputProps,
              ...InputProps,
              startAdornment: project ? (
                <Typography
                  variant="body1Strong"
                  sx={theme => ({
                    maxWidth: '144px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    paddingRight: theme.spacing(2),
                    '&:after': {
                      ...theme.typography.body1Strong,
                      content: '"/"',
                      display: 'block',
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    },
                  })}
                  title={Formatters.Project.displayName(project.display_name)}
                >
                  {Formatters.Project.displayName(project.display_name)}
                </Typography>
              ) : (
                <CircularProgress size={16} />
              ),
              sx: theme => ({
                '&[class*="MuiOutlinedInput-root"]': {
                  paddingLeft: theme.spacing(4),
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                  '& $input, & $input:first-of-type': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                },
              }),
              // @ts-expect-error: untyped-prop
              'data-testid': 'data-source-selector-input',
            }}
          />
        )}
      />
    );
  }
);

DataSourceSelector.displayName = 'DataSourceSelector';

export default DataSourceSelector;
