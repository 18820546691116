import { Components, Theme } from '@mui/material';

const MuiInputAdornment: Components<Theme>['MuiInputAdornment'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.secondary,
    }),
    positionStart: ({ theme }) => ({
      marginRight: theme.spacing(2),
    }),
  },
};

export default MuiInputAdornment;
