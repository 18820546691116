import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import DataSourceSelector from 'common/DataSourceSelector';
import UploadProgressCard from 'common/Upload/Progress/Card';
import UploadDropzone from 'common/Upload/UploadDropzone';
import uploadSlice, { ActionCreators } from 'common/Upload/uploadDuck';
import { useAppSelector } from 'src/store.types';
import { isDone, isException, isLoading } from 'utils/stage';
import useActions from 'utils/useActions';
import useSelectedProject from 'utils/useSelectedProject';

type Props = Omit<DialogProps, 'onClose'> & {
  title: string;
  description: string;
  onClose: (dataSource?: string) => void;
};

const DataModal = React.forwardRef<HTMLDivElement, Props>(
  ({ title, description, onClose, ...props }, ref) => {
    const [{ reset: resetUploads }] = useActions([
      uploadSlice.actions,
      ActionCreators,
    ]);
    const { selectedProject } = useSelectedProject();
    const { stage, uploads } = useAppSelector(state => state.upload);

    const [dataSource, setDataSource] = React.useState<string>();
    // TODO: Make sure other pages properly clear uploads so this doesn't fuck up
    const firstUpload = uploads[0];
    const uploadStage = stage.uploads[firstUpload?.fileKey];

    const handleRun = React.useCallback(() => {
      onClose(dataSource);
      resetUploads();
    }, [onClose, dataSource, resetUploads]);

    const handleCancel = React.useCallback(() => {
      onClose();
      resetUploads();
    }, [onClose, resetUploads]);

    React.useEffect(() => {
      if (isDone(uploadStage)) {
        setDataSource(firstUpload?.artifactKey);
      }
    }, [uploadStage, firstUpload]);

    return (
      <Dialog
        ref={ref}
        onClose={handleCancel}
        {...props}
        PaperProps={{
          sx: { width: '572px', ...props.PaperProps?.sx },
          ...props.PaperProps,
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
        {uploads.length ? (
          <DialogContent>
            <UploadProgressCard />
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText variant="body2" sx={{ mb: 6 }}>
              {description}
            </DialogContentText>
            {selectedProject && (
              <DataSourceSelector
                sx={{ mb: 4 }}
                project={selectedProject}
                onSelect={({ artifactKey }) => setDataSource(artifactKey)}
                onClear={() => setDataSource(undefined)}
              />
            )}
            <UploadDropzone multiple={false} />
          </DialogContent>
        )}

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCancel} variant="text">
            Dismiss
          </Button>
          <Button
            onClick={handleRun}
            color="success"
            disabled={
              !dataSource || isLoading(uploadStage) || isException(uploadStage)
            }
          >
            Run
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DataModal.displayName = 'DataModal';

export default DataModal;
