import { Components, Theme } from '@mui/material';

const MuiLinearProgress: Components<Theme>['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      borderRadius: 4,
    },
    colorPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.grey[100],
    }),
    colorSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.grey[50],
    }),
    dashedColorPrimary: ({ theme }) => ({
      backgroundImage: `radial-gradient(${theme.palette.grey[100]} 0%, ${theme.palette.grey[100]} 16%, transparent 42%)`,
      backgroundSize: '10px 10px',
      backgroundPosition: '0 -23px',
    }),
    dashedColorSecondary: ({ theme }) => ({
      backgroundImage: `radial-gradient(${theme.palette.grey[50]} 0%, ${theme.palette.grey[50]} 16%, transparent 42%)`,
      backgroundSize: '10px 10px',
      backgroundPosition: '0 -23px',
    }),
    /* Styles applied to the layered bar1 and bar2 elements. */
    bar: {
      borderRadius: 4,
      width: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
      transition: 'transform 0.2s linear',
      transformOrigin: 'left',
    },
    /* Styles applied to the bar elements if `color="primary"`; bar2 if `variant` not "buffer". */
    barColorPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
    }),
    /* Styles applied to the bar elements if `color="secondary"`; bar2 if `variant` not "buffer". */
    barColorSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.main,
    }),
  },
};

export default MuiLinearProgress;
