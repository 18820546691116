import { alpha, PaletteOptions } from '@mui/material/styles';
import {
  common,
  error,
  grey,
  info,
  primary,
  success,
  warning,
} from './colorValues';

export const paletteDark: PaletteOptions = {
  primary: {
    ...primary,
    main: primary[400],
    light: primary[100],
    dark: primary[500],
    contrastText: primary[950],
  },
  secondary: {
    ...grey,
    main: grey[400],
    light: grey[300],
    dark: grey[500],
    contrastText: grey[950],
  },
  success: {
    ...success,
    main: success[300],
    light: success[100],
    dark: success[800],
    contrastText: success[950],
  },
  info: {
    ...info,
    main: info[300],
    light: info[200],
    dark: info[800],
    contrastText: info[950],
  },
  warning: {
    ...warning,
    main: warning[300],
    light: warning[200],
    dark: warning[700],
    contrastText: warning[950],
  },
  error: {
    ...error,
    main: error[300],
    light: error[200],
    dark: error[700],
    contrastText: error[950],
  },
  border: {
    default: grey[800],
    strong: grey[700],
  },
  // Setting to border default here for components that use it.
  divider: grey[800],
  text: {
    primary: common.white,
    secondary: grey[300],
    disabled: grey[600],
  },
  background: {
    default: grey[900],
    secondary: grey[950],
    inset: grey[800],
    // Setting to default here for components that use it.
    paper: grey[900],
  },
  action: {
    hoverOpacity: 0.1,
    activatedOpacity: 0.1,
    disabledOpacity: 0.4,
    focusOpacity: 0.15,
    selectedOpacity: 0.1,
    hover: alpha(grey[50], 0.1),
    active: alpha(grey[50], 0.1),
    selected: alpha(grey[50], 0.1),
    disabled: alpha(grey[50], 0.4),
    disabledBackground: alpha(grey[50], 0.1),
    focus: alpha(grey[50], 0.15),
    focusBoxShadow: `0px 0px 0px 1px ${common.black}, 0px 0px 0px 3px ${primary[400]}`, // primary.main
    errorBoxShadow: `0px 0px 0px 1px ${common.black}, 0px 0px 0px 3px ${error[300]}`, // error.main
  },
};
