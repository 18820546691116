import * as React from 'react';
import { ShowerManager } from './Managers/ShowerManager';
import { WatcherManager } from './Managers/WatcherManager';

export const ModelCompletionDialogManager = () => {
  return (
    <>
      <WatcherManager />
      <ShowerManager />
    </>
  );
};
