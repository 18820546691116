import { UseCaseBlueprint } from 'src/api/types/Blueprint';

export const FROM_SCRATCH_CARD: UseCaseBlueprint = {
  gtmId: 'from-scratch',
  title: 'Start from scratch',
  cardType: 'Console',
  description:
    'Pick your own model type or select an existing model to get started.',
  icon: 'start-from-scratch.png',
};

export const NAVIGATOR_CARD: Partial<UseCaseBlueprint> = {
  gtmId: 'use-case-navigator',
  detailsFileName: 'navigator.md',
};
