import { matchPath } from 'react-router';
import { LocationValidator } from 'common/utils/useOnNavigate';
import { BUILDER, WORKFLOW_DETAILS_ROUTE } from 'src/routes';

const INVALID_WORKFLOW_NAME_CHARS = /[^A-Za-z0-9_-]/g;

/** replaces invalid characters with `-` */
export const sanitizeWorkflowName = (name: string) => {
  return name.replace(INVALID_WORKFLOW_NAME_CHARS, '-');
};

export const shouldResetOnNavigate: LocationValidator = location =>
  !matchPath(location.pathname, {
    path: [BUILDER.path, WORKFLOW_DETAILS_ROUTE.path],
  });

export const MODEL_INPUT_PLACEHOLDER = 'MODEL_INPUT_PLACEHOLDER';
export const OUTPUT_INPUT_PLACEHOLDER = 'OUTPUT_INPUT_PLACEHOLDER';
