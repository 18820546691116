import { Components, Theme } from '@mui/material';

const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.secondary,
      minWidth: 'unset',
      marginRight: theme.spacing(3),
    }),
  },
};

export default MuiListItemIcon;
