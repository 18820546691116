import * as React from 'react';
import {
  Card,
  CardActions,
  CardActionsProps,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Typography,
} from '@mui/material';

export type Props = React.PropsWithChildren<{
  CardHeaderProps?: CardHeaderProps;
  CardContentProps?: CardContentProps;
  CardActionProps?: CardActionsProps;
  title?: string;
  subtitle?: React.ReactNode;
  mainContent?: React.ReactNode;
  actions?: React.ReactNode;
}> &
  Partial<CardProps>;

const QubitCard = ({
  CardHeaderProps,
  CardContentProps = {},
  CardActionProps = {},
  title,
  subtitle,
  mainContent,
  children,
  actions,
  sx,
  ...props
}: Props) => {
  return (
    <Card sx={sx ? { mb: 6, ...sx } : { mb: 6 }} {...props}>
      {(title || subtitle) && (
        <CardHeader
          title={
            <Typography
              variant="display2"
              {...CardHeaderProps?.titleTypographyProps}
            >
              {title || ''}
            </Typography>
          }
          subheader={
            subtitle && (
              <Typography
                variant="body1"
                component="p"
                sx={{ mt: 2 }}
                {...CardHeaderProps?.subheaderTypographyProps}
              >
                {subtitle || ''}
              </Typography>
            )
          }
          disableTypography
          sx={{
            backgroundColor: 'inherit',
            border: 'none',
            p: 6,
            pb: 0,
          }}
          {...CardHeaderProps}
        />
      )}
      <CardContent {...CardContentProps}>{mainContent || children}</CardContent>
      {actions && <CardActions {...CardActionProps}>{actions}</CardActions>}
    </Card>
  );
};

export default QubitCard;
