import { Components, Theme } from '@mui/material';

const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    popper: ({ theme }) => ({
      margin: theme.spacing(1, 0),
    }),
    paper: ({ theme }) => ({
      boxShadow: theme.shadows[4],
      margin: 0,
      padding: 0,
    }),
    listbox: {
      padding: 0,
    },
  },
};

export default MuiAutocomplete;
