import { Components, Theme } from '@mui/material';
import { DRAWER_PEEK_WIDTH, DRAWER_WIDTH } from 'common/constants/layouts';

const MuiDrawer: Components<Theme>['MuiDrawer'] = {
  styleOverrides: {
    paper: {
      width: DRAWER_WIDTH - DRAWER_PEEK_WIDTH,
      overflow: 'visible',
    },
  },
};

export default MuiDrawer;
