import { Components, Theme } from '@mui/material';

declare module '@mui/material/Tabs' {
  interface TabsOwnProps {
    secondary?: 'true' | 'false';
  }
}

const MuiTabs: Components<Theme>['MuiTabs'] = {
  variants: [
    {
      props: { secondary: 'true' },
      style: ({ theme }) => ({
        '& .MuiTab-root': {
          ...theme.typography.button2,
          lineHeight: '24px',
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.common.white,
          padding: theme.spacing(2, 6),
          margin: 0,
          '& > svg': {
            color: theme.palette.text.secondary,
          },
          border: `1px solid ${theme.palette.border.default}`,
          // Hover Styles
          ':hover, &:hover > svg': {
            backgroundColor: theme.palette.grey[100],
          },
          // Active - Selected Styles
          '&.Mui-selected,&:active': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary[50],
            borderColor: theme.palette.primary[300],
            '& > svg': {
              color: theme.palette.primary.main,
            },
            ':hover, &:hover > svg': {
              backgroundColor: theme.palette.primary[100],
            },
          },
          // Disabled Styles
          '&.Mui-disabled,&:disabled,&.Mui-disabled > svg': {
            color: theme.palette.text.disabled,
          },
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }),
    },
    {
      props: { secondary: 'true', orientation: 'horizontal' },
      style: ({ theme }) => ({
        '& .MuiTab-root': {
          '&:first-of-type': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          ':last-child': {
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
          // To give the illusion of shared borders remove right border unless last Tab
          ':not(&.Mui-selected):not(&:active):not(:last-child)': {
            borderRight: 'none',
            // When removing border we need to pad it's width to avoid shifting
            paddingRight: theme.spacing(6 + 0.25),
          },
          // Selected Tab has full border so remove left border of following sibling
          '&.Mui-selected + .MuiTab-root,&:active + .MuiTab-root': {
            borderLeft: 'none',
            // When removing border we need to pad it's width to avoid shifting
            paddingLeft: theme.spacing(6 + 0.25),
          },
        },
      }),
    },
    {
      props: { secondary: 'true', orientation: 'vertical' },
      style: ({ theme }) => ({
        '& .MuiTab-root': {
          '&:first-of-type': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
          },
          ':last-child': {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
          // To give the illusion of shared borders remove bottom border unless last Tab
          ':not(&.Mui-selected,&:active):not(:last-child)': {
            borderBottom: 'none',
            // When removing border we need to pad it's width to avoid shifting
            paddingBottom: theme.spacing(3 + 0.25),
          },
          // Selected Tab has full border so remove top border of following sibling
          '&.Mui-selected,&:active + .MuiTab-root': {
            borderTop: 'none',
            // When removing border we need to pad it's width to avoid shifting
            paddingTop: theme.spacing(3 + 0.25),
          },
        },
      }),
    },
  ],
  styleOverrides: {
    root: {
      minHeight: 'unset',
    },
    indicator: ({ theme }) => ({
      marginTop: theme.spacing(-0.5),
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      bottom: 'unset',
    }),
  },
};

export default MuiTabs;
