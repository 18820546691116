import { Components, Theme } from '@mui/material';

const MuiImageList: Components<Theme>['MuiImageList'] = {
  defaultProps: {
    gap: 24, // px
    rowHeight: 'auto',
  },
};

export default MuiImageList;
