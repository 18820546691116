import { ConfigValidationError } from 'src/api/types/ModelConfig';

const isError = item => !!item;
const formatNestedError = (arr: string[]) => {
  const results = arr?.map(string => {
    const startParenthesis = string.indexOf('(');
    const endParenthesis = string.indexOf(')');
    const subString = string.substr(startParenthesis, endParenthesis);
    return [string.trim().replace(subString, ' ')];
  });
  return results.flatMap(i => i);
};

export const mapErrors = (errors: ConfigValidationError[]) => {
  return errors
    .flatMap(error => {
      let key = error.loc[1] || error.loc[0];
      let msg = error.msg;

      const msgArr = error.msg?.split('\n') || [];
      msgArr?.shift();

      const formattedMsgArr = formatNestedError(msgArr);
      const nestedErrors: string[] = [];
      for (let i = 0; i < formattedMsgArr.length; i += 2) {
        key = formattedMsgArr[i];
        msg = formattedMsgArr[i + 1];
        const errorString = `${key}: ${msg}`;
        nestedErrors.push(errorString);
      }
      return [`${key}: ${msg}`, ...nestedErrors];
    })
    .filter(isError);
};

const BLUEPRINTS_DIR_PREFIX = 'config_templates/gretel/' as const;

export const getConfigTemplateNameAndCategoryFromFilePath = (
  filepath: string
) => {
  const pathParts = filepath.replace(BLUEPRINTS_DIR_PREFIX, '').split('/');
  const filename = pathParts.pop() as string;
  const fileKey = filename.replace(/\.ya?ml$/, '');
  const modelType = pathParts.join('/');
  return { name: fileKey, modelType };
};
