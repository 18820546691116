/* eslint-disable @typescript-eslint/no-explicit-any */
import { ERROR_IDS_TYPE } from 'constants/errorIDs';
import { YesOrNo } from './common';
import { Log } from './Log';
import { ModelBaseQueryParams, RunnerMode } from './Model';

export enum Status {
  CREATED = 'created',
  PENDING = 'pending',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  LOST = 'lost',
}

export type GetModelRunsParams = ModelBaseQueryParams & {
  modelId: string;
};

export type GetModelRunParams = {
  guid: string;
  modelId: string;
  uid: string;
  logs?: YesOrNo;
};

export type CreateRecordHandlerParams = {
  guid: string;
  modelId: string;
  runner_mode: RunnerMode | 'inherit';
  [key: string]: any;
};

export type UpdateRecordHandlerParams = {
  guid: string;
  modelId: string;
  uid: string;
  [key: string]: any;
};

type BaseModelRun = {
  uid?: string;
  guid?: string;
  model_name?: string;
  model_key: string;
  runner_mode: RunnerMode;
  user_id: string;
  user_guid?: string;
  billing_domain?: string;
  billing_domain_guid?: string;
  project_id: string;
  project_guid?: string;
  status_history: Partial<Record<Status, string>>;
  last_modified?: string;
  status: Status;
  last_active_hb?: string;
  duration_minutes?: number;
  error_msg?: string;
  error_id?: ERROR_IDS_TYPE;
  traceback?: string;
  annotations?: Record<string, any>;
};

export type ModelRun = BaseModelRun & {
  artifacts?: Record<string, any>[];
  container_image?: string;
  model_id?: string;
  model_guid?: string;
  model_type?: string;
  config: {
    data_source?: string;
    params?: Record<string, any>;
  };
  is_autouse: boolean;
  logs?: Log[];
  billing_data?: {
    total_time_seconds: number;
    total_billed_seconds: number;
    job_status: string; // example: completed
    job_type: string; // example: transform
  };
};
