import * as React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps,
  Typography,
} from '@mui/material';
import StyledRouterLink from 'common/StyledRouterLink';
import { Route } from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';

/**
 * include a `to` field mapped to a route from `src/routes.ts`
 * to make a hyperlinked breadcrumb.
 * */
type BreadcrumbItem =
  | {
      label: React.ReactNode;
      to: Route;
      active?: boolean;
    }
  | {
      label: React.ReactNode;
      to?: never;
      active?: never;
    };

export type BreadcrumbProps = {
  items: BreadcrumbItem[];
  trailing?: boolean;
} & Partial<BreadcrumbsProps>;

const Breadcrumbs = React.forwardRef<HTMLElement, BreadcrumbProps>(
  ({ items, className, trailing = false, ...props }, ref) => {
    const { compileLocation } = useConsoleRoute({ withRouterContext: true });
    return (
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        className={className}
        {...props}
        ref={ref}
      >
        {items.map((item, index) =>
          item.to ? (
            <StyledRouterLink
              key={index}
              to={compileLocation(item.to)}
              sx={{
                '&:hover, &:focus': {
                  textDecoration: 'underline',
                  color: 'primary.main',
                  '&:active': {
                    color: 'primary.dark',
                  },
                },
                ...(item.active && {
                  textDecoration: 'underline',
                  color: 'primary.dark',
                }),
              }}
              variant="button1"
              underline="hover"
            >
              {item.label}
            </StyledRouterLink>
          ) : (
            <Typography
              variant="body1Strong"
              sx={{ color: 'text.primary' }}
              key={index}
            >
              {item.label}
            </Typography>
          )
        )}
        {trailing && <span />}
      </MuiBreadcrumbs>
    );
  }
);

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
