import { Components, Theme } from '@mui/material';

const MuiDialogContentText: Components<Theme>['MuiDialogContentText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
    }),
  },
};

export default MuiDialogContentText;
