import { Components, Theme } from '@mui/material';

const MuiPaper: Components<Theme>['MuiPaper'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    rounded: ({ theme }) => ({
      borderRadius: theme.shape.radii.base,
    }),
  },
};

export default MuiPaper;
