import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Divider,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';
import { bindMenu, PopupState } from 'material-ui-popup-state/hooks';
import { useLazyLogoutQuery } from 'src/api';
import { useOrgSettingsRoutes } from 'src/Console/Users/utils';
import {
  ACCOUNT_SETTINGS_ROUTES,
  Route,
  USER_BILLING_ROUTE,
  USER_USAGE_ROUTE,
} from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';

type UserMenuProps = {
  popupState: PopupState;
  limited?: boolean;
  billingTier?: number;
};

export const UserMenu = ({
  popupState,
  limited = false,
  billingTier = 0,
}: UserMenuProps) => {
  const { compileLocation } = useConsoleRoute();
  const [triggerLogout] = useLazyLogoutQuery();

  // Re-usable route menu item component
  const RouteMenuItem = forwardRef<HTMLAnchorElement, { route: Route }>(
    ({ route }, ref) => (
      <MenuItem
        ref={ref}
        component={NavLink}
        to={compileLocation(route)}
        onClick={popupState.close}
      >
        <ListItemText primary={route.title} />
      </MenuItem>
    )
  );
  RouteMenuItem.displayName = 'RouteMenuItem';

  // Logout component for reuse
  const LogoutMenuItem = forwardRef<HTMLLIElement>((_, ref) => (
    <MenuItem
      ref={ref}
      key="userMenuLogout"
      onClick={() => {
        popupState.close();
        triggerLogout();
      }}
    >
      <ListItemText primary="Log out" />
    </MenuItem>
  ));
  LogoutMenuItem.displayName = 'LogoutMenuItem';

  // Non-team (developer / enterprise) user menu
  const BaseMenuItems = () => (
    <>
      {ACCOUNT_SETTINGS_ROUTES.map((route, index) => (
        <RouteMenuItem key={`user-menu-item-${index}`} route={route} />
      ))}
      <RouteMenuItem route={USER_USAGE_ROUTE} />
      <RouteMenuItem route={USER_BILLING_ROUTE} />
      <Divider />
      <LogoutMenuItem />
    </>
  );

  const orgSettings = useOrgSettingsRoutes();

  // Team User menu has subheaders for Account + Organization
  const TeamMenuItems = () => (
    <>
      <ListSubheader color="primary">Account</ListSubheader>
      {ACCOUNT_SETTINGS_ROUTES.map((route, index) => (
        <RouteMenuItem route={route} key={index} />
      ))}
      <Divider />
      <>
        <ListSubheader>Organization</ListSubheader>
        {orgSettings.map((route, index) => (
          <RouteMenuItem route={route} key={index} />
        ))}
        <Divider />
      </>
      <LogoutMenuItem />
    </>
  );

  return (
    <Menu
      variant="menu"
      disableAutoFocusItem
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...bindMenu(popupState)}
    >
      {limited ? (
        <LogoutMenuItem />
      ) : billingTier === 2 ? (
        <TeamMenuItems />
      ) : (
        <BaseMenuItems />
      )}
    </Menu>
  );
};

export default UserMenu;
