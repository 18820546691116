import { Components, Theme } from '@mui/material';

const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.spacing(8),
      width: theme.spacing(8),
      ...theme.typography.body1Strong,
    }),
    colorDefault: ({ theme }) => ({
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.secondary,
    }),
  },
};

export default MuiAvatar;
