import { DatabricksFormState } from './types';

// Setting the default values for the form
export const initialDatabricksForm: DatabricksFormState = {
  name: '',
  type: 'databricks',
  personal_access_token: '',
  server_hostname: '',
  http_path: '',
  catalog: '',
  schema: '',
  params: '',
  port: 443,
  connection_target_type: 'source',
};
