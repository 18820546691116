import * as React from 'react';
import { Components, Theme } from '@mui/material';
import { ChevronRight } from 'icons/figma';

const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <ChevronRight width={16} height={16} />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: 0,
      padding: 0,
      '&:hover, &:hover .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.primary.main,
      },
    }),
    content: ({ theme }) => ({
      margin: 0,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    }),
  },
};

export default MuiAccordionSummary;
