import * as React from 'react';
import { ThemeMode, ThemeModeContext } from './Context';

const THEME_MODE_KEY = 'themeMode';

export const ThemeModeProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const storedMode = localStorage.getItem(THEME_MODE_KEY);
  const [mode, setModeState] = React.useState<ThemeMode>(
    storedMode ? (storedMode as ThemeMode) : 'light'
  );

  const setMode = (mode: ThemeMode) => {
    localStorage.setItem(THEME_MODE_KEY, mode);
    setModeState(mode);
  };

  const value = React.useMemo(
    () => ({
      mode,
      setMode,
    }),
    [mode]
  );

  return (
    <ThemeModeContext.Provider value={value}>
      {children}
    </ThemeModeContext.Provider>
  );
};

ThemeModeProvider.displayName = 'ThemeModeProvider';
