import { ConnectionFormatter } from './connection';
import { DateFormatter } from './date';
import { FileNameFormatter } from './fileName';
import { NumberFormatter } from './number';
import { OtherFormatter } from './other';
import { ProjectFormatter } from './project';
import { StringFormatter } from './string';
import { UserFormatter } from './user';
import { WorkflowFormatter } from './workflow';

const Formatters = {
  Connection: ConnectionFormatter,
  Date: DateFormatter,
  FileName: FileNameFormatter,
  Number: NumberFormatter,
  Other: OtherFormatter,
  Project: ProjectFormatter,
  String: StringFormatter,
  User: UserFormatter,
  Workflow: WorkflowFormatter,
};

export default Formatters;
