import cronstrue from 'cronstrue';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { DateStringISO } from './types';

export const DateFormatter = {
  // 01/01/2020 01:01 AM
  imperialistTimestamp: (ts?: DateStringISO) =>
    ts ? format(parseISO(ts), 'MM/dd/yyy, h:mm a') : '',
  // Jan 1, 2020
  date: (ts?: DateStringISO) => (ts ? format(parseISO(ts), 'MMM d, yyyy') : ''),
  // 01:01 Jan 1, 2020
  timePlusDate: (ts?: DateStringISO) =>
    ts ? format(parseISO(ts), 'HH:mm MMM d, yyyy') : '',
  // 01/01/20
  imperialistDate: (ts: DateStringISO) =>
    ts ? format(parseISO(ts), 'MM/dd/yy') : '',
  // 01:23:30
  logTime: (ts?: DateStringISO) => (ts ? format(parseISO(ts), 'HH:mm:ss') : ''),
  // "less than a minute ago", "2 days ago", etc.
  // https://date-fns.org/v2.16.1/docs/formatDistanceToNow
  humanize: (ts?: DateStringISO) =>
    ts ? formatDistanceToNow(parseISO(ts), { addSuffix: true }) : '',
  dashFormat: (ts?: DateStringISO) =>
    ts ? format(parseISO(ts), 'yyyy-MM-dd-hh-mm-ss') : '',
  /**
   *
   * A small utility wrapper around the cronstrue library.
   * Note that this method will throw an error if the cronstring provided
   * is not considered valid by the library.
   * @param cronString string representing cron time, e.g., * * * * *. Must have at least 5 parts.
   * @returns string - human friendly interpretation of crontime.
   */
  cron: (cronString: string): string =>
    cronstrue.toString(cronString, {
      verbose: true,
    }),
  detailed: (ts: DateStringISO) => format(parseISO(ts), 'y-MM-dd, pp'),
  /**
   * takes a number of seconds (e.g., the time something took to run)
   * and returns a concise human-friendly string representation of that time.
   */
  secondsToHumanFriendly: (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m `;
    }
    if (remainingSeconds > 0) {
      result += `${remainingSeconds}s`;
    }
    return result.trim();
  },
};
