import * as React from 'react';
import { useModelQuery } from 'src/api';
import { useAppSelector } from 'src/store.types';
import { Shower } from './Shower';

export const ShowerManager = () => {
  const { modelsToShowDialogFor } = useAppSelector(
    state => state.modelCompletionDialog
  );
  // FIFO, one at a time
  const oldestEligibleModel = modelsToShowDialogFor[0];
  const modelQuery = useModelQuery(
    {
      uid: oldestEligibleModel?.modelUid,
      guid: oldestEligibleModel?.projectGuid,
      expand: 'report,artifacts',
    },
    { skip: !oldestEligibleModel }
  );
  const model = modelQuery.currentData;

  if (!oldestEligibleModel || !model) {
    return null;
  }

  return <Shower model={model} />;
};
