export const FileNameFormatter = {
  fileNameToLabel: (name = '') =>
    name.substring(0, 1).toUpperCase() + name.substring(1).replace(/-/g, ' '),
  // maxLength IS inclusive of the file type (e.g., ".csv")
  truncate: (fileName: string, maxLength = 40) => {
    if (fileName.length <= maxLength) {
      return fileName;
    }
    // caveat: filetype may or may not be present
    const [name, filetype] = fileName.split('.');
    /**
     * we want the result to have ~2/3 first part of name, `1/3 second part of name
     * and total the maxLength minus the ellipses, filetype and punctuation
     */
    const ellipses = '...';
    const suffixLength = filetype ? filetype.length + 1 : 0;
    const maxNameLength = maxLength - (ellipses.length + suffixLength);

    const thirdOfLength = Math.round(maxNameLength / 3);
    const startLength = thirdOfLength * 2;
    const endLength = maxNameLength - startLength;

    const start = name.substring(0, startLength);
    const end = name.substring(name.length - endLength);
    const suffix = filetype ? `.${filetype}` : '';
    return `${start}${ellipses}${end}${suffix}`;
  },
  fromPath: (path: string) => path.substring(path.lastIndexOf('/') + 1),
};
