import * as React from 'react';
import { Avatar, AvatarProps } from '@mui/material';

type UserAvatarInnerProps = AvatarProps & {
  username?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  image?: {
    small: string;
    medium: string;
    large: string;
  };
  _id: string;
};

/**
 *
 * A sub-component for UserAvatar. MUI Tooltip components
 * needs to be able to give its child component a ref, which
 * can be made possible via forwardRef.
 * https://mui.com/material-ui/guides/composition/#caveat-with-refs
 *
 */
export const UserAvatarInner = React.forwardRef<
  HTMLDivElement,
  UserAvatarInnerProps
>(
  (
    { username, image, firstname, lastname, email, _id, ...avatarProps },
    ref
  ) => {
    return (
      <Avatar
        alt={username}
        src={image?.medium}
        srcSet={[`${image?.medium} 1x`, `${image?.large} 2x`].join(', ')}
        ref={ref}
        {...avatarProps}
      >
        {getAvatarFallback({ firstname, lastname, username })}
      </Avatar>
    );
  }
);

export const getAvatarFallback = ({
  firstname,
  lastname,
  username,
}: {
  firstname?: string;
  lastname?: string;
  username?: string;
}) => {
  if (!firstname && !lastname) {
    return (username ? username[0] : ' ').toUpperCase();
  } else {
    const firstInitial = (firstname ? firstname[0] : ' ').toUpperCase();
    const lastInitial = (lastname ? lastname[0] : ' ').toUpperCase();
    return `${firstInitial}${lastInitial}`;
  }
};

UserAvatarInner.displayName = 'UserAvatarInner';
