import { Components, Theme } from '@mui/material';

const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  defaultProps: {
    primaryTypographyProps: {
      variant: 'body2Medium',
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.body2.fontSize,
    }),
  },
};

export default MuiListItemText;
