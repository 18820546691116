import { createApi } from '@reduxjs/toolkit/query/react';
import { tenantBaseQuery } from '../utils/tenantBaseQuery';

export const api = createApi({
  reducerPath: 'pilot-api',
  baseQuery: tenantBaseQuery({
    paramsSerializer(params) {
      return Object.entries(params)
        .reduce((acc, [field, value]) => {
          if (value === undefined || value === null) {
            return acc;
          }

          if (Array.isArray(value)) {
            value.forEach(v => acc.append(field, v));
          } else {
            acc.append(field, value);
          }
          return acc;
        }, new URLSearchParams())
        .toString();
    },
  }),
  endpoints: () => ({}),
});
