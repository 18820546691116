import { Components, Theme } from '@mui/material';

const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }),
  },
};

export default MuiDivider;
