import * as React from 'react';
import { alpha, Components, Theme } from '@mui/material';
import { AlertCircle, AlertTriangle, Check, Info } from 'icons/figma';

declare module '@mui/material/Alert' {
  interface AlertProps {
    shadow?: boolean;
  }
}

const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    variant: 'filled',
    iconMapping: {
      error: <AlertCircle height={16} width={16} />,
      warning: <AlertTriangle height={16} width={16} />,
      success: <Check height={16} width={16} />,
      info: <Info height={16} width={16} />,
    },
  },
  variants: [
    {
      props: { shadow: true },
      style: ({ theme }) => ({
        boxShadow: `0px 3px 6px 0px ${alpha(theme.palette.grey[950], 0.12)}`,
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(3, 2, 3, 4),
      borderRadius: theme.shape.borderRadius,
    }),
    message: ({ theme }) => ({
      ...theme.typography.body2Medium,
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: theme.spacing(2),
    }),
    icon: ({ theme }) => ({
      padding: 0,
      marginTop: theme.spacing(0.5),
      alignSelf: 'flex-start',
    }),
    action: ({ theme }) => ({
      padding: 0,
      paddingLeft: theme.spacing(1),
      marginRight: 0,
      maxHeight: theme.spacing(5),
      alignItems: 'center',
      alignSelf: 'flex-start',
    }),
    /* variant="filled" should be the only variant used */
    filled: ({ ownerState, theme }) => {
      const color = ownerState?.severity || 'info';
      return {
        backgroundColor: theme.palette[color][100],
        color: theme.palette[color][900],
        '& .MuiAlertTitle-root': {
          color: theme.palette[color][900],
        },
        '& .MuiAlert-icon': {
          color: theme.palette[color][700],
        },
        '& .MuiAlert-action > button': {
          color: theme.palette[color][700],
          ':hover': {
            backgroundColor: theme.palette[color][200],
          },
          ':active': {
            backgroundColor: theme.palette[color][300],
          },
        },
      };
    },
  },
};

export default MuiAlert;
