import * as React from 'react';
import { useAppSelector } from 'src/store.types';
import { Watcher } from './Watcher';

export const WatcherManager = () => {
  const { modelsToWatch } = useAppSelector(
    state => state.modelCompletionDialog
  );
  const modelUids = Object.keys(modelsToWatch);

  return (
    <>
      {modelUids.map(uid => {
        return (
          <Watcher
            uid={uid}
            projectGuid={modelsToWatch[uid].projectGuid}
            createdAt={modelsToWatch[uid].createdAt}
            key={uid}
          />
        );
      })}
    </>
  );
};
