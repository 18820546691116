import React from 'react';
import { Container, Divider, Grid, Link, Typography } from '@mui/material';
import {
  CONTACT_US,
  GRETEL_PRIVACY_STATEMENT,
  GRETEL_TERMS_OF_SERVICE,
} from 'common/constants/urls';
import useConsoleRoute from 'common/utils/useConsoleRoute';
import { LICENSE_ROUTE, Route } from 'src/routes';
import FooterAPIStatus from './APIStatus';
import { CookieConsentPreference } from './CookieConsentPreference';

type FooterLink =
  | { text: string; link: string; internal?: never }
  | { text: string; link: Route; internal: true };

const links: FooterLink[] = [
  { text: 'Terms', link: GRETEL_TERMS_OF_SERVICE },
  { text: 'Privacy', link: GRETEL_PRIVACY_STATEMENT },
  { text: 'Licenses', link: LICENSE_ROUTE, internal: true },
  { text: 'Contact', link: CONTACT_US },
];
const Footer = () => {
  const { compileLocation } = useConsoleRoute();

  return (
    <>
      <Divider sx={{ mt: 8, mb: 0, flexGrow: 1 }} />
      <Container
        component="footer"
        sx={{ py: 3, bgcolor: 'background.default' }}
      >
        <Grid container wrap="nowrap" gap={7} py={1}>
          <Typography
            variant="body2"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            Copyright &copy; 2019 - {new Date().getFullYear()} Gretel Labs, Inc.
          </Typography>
          {links.map(({ text, link, internal }) => {
            let attributes = {};
            if (internal) {
              attributes = {
                href: compileLocation(link).pathname,
                target: '_self',
                rel: 'noopener',
              };
            } else {
              attributes = { href: link };
            }
            return (
              <Link
                key={`$footerLink-${text}`}
                variant="body2"
                color="text.secondary"
                sx={{ '&:hover': { textDecoration: 'none' } }}
                {...attributes}
              >
                {text}
              </Link>
            );
          })}
          <FooterAPIStatus />
          <CookieConsentPreference />
        </Grid>
      </Container>
    </>
  );
};

Footer.displayName = 'Footer';

export default Footer;
