import { Components, Theme } from '@mui/material';

const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: {
    'html, body, #root': {
      height: '100svh',
    },
    fieldset: ({ theme }) => ({
      borderRadius: 2,
      borderColor: theme.palette.grey[100],
      padding: theme.spacing(4),
    }),
    blockquote: ({ theme }) => ({
      marginLeft: 0,
      paddingLeft: theme.spacing(4),
      borderLeft: `${theme.spacing(1)} solid ${theme.palette.grey[100]}`,
    }),
    '.iconCenter': ({ theme }) => ({
      fill: theme.palette.background.default,
    }),
    'pre, textarea': {
      margin: 0,
    },
    'div.ProseMirror': {
      '& div.image': {
        display: 'inline',
      },
      '& pre': {
        margin: 0,
        border: 'none',
      },
      '& fieldset': ({ theme }) => ({
        borderRadius: 2,
        borderColor: theme.palette.grey[100],
        padding: theme.spacing(4),
      }),
      '& .iconCenter': ({ theme }) => ({
        fill: theme.palette.background.default,
      }),
    },
  },
};

export default { ...MuiCssBaseline };
