import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
} from '@mui/material';

const CHAR_LIMIT = 140;
const DISPLAY_CHARS_LEFT = 40;

type DescriptionProps = Omit<OutlinedInputProps, 'onChange'> & {
  onChange: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string | React.ReactNode;
  value?: string;
};

const ProjectDescriptionField = ({
  onChange,
  onBlur = () => null,
  onFocus = () => null,
  error = false,
  helperText,
  value = '',
  id,
  ...props
}: DescriptionProps) => {
  const [showCharsLeft, setShowCharsLeft] = React.useState(false);
  const [charsLeft, setCharsLeft] = React.useState(
    CHAR_LIMIT - (value.length || 0)
  );

  const handleChangeDescription = ({ target: { value: newValue } }) => {
    setCharsLeft(CHAR_LIMIT - newValue.length);
    return onChange(newValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setShowCharsLeft(false);
    return onBlur(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setShowCharsLeft(true);
    return onFocus(event);
  };

  React.useEffect(() => {
    setCharsLeft(CHAR_LIMIT - (value?.length || 0));
  }, [value]);

  return (
    <FormControl fullWidth>
      <OutlinedInput
        id={id || 'project-description'}
        aria-describedby={id ? `${id}-helper` : 'project-description-helper'}
        onFocus={handleFocus}
        onChange={handleChangeDescription}
        onBlur={handleBlur}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            // Cause blur on enter to trigger change.
            event.preventDefault();
            (event.target as HTMLInputElement).blur();
          }
        }}
        error={charsLeft < 0 || error}
        value={value}
        {...props}
      />
      <FormHelperText id={id ? `${id}-helper` : 'project-description-helper'}>
        {helperText}{' '}
        <Typography
          color={charsLeft < 0 ? 'error' : 'primary'}
          component="span"
          variant="body2"
          sx={{
            float: 'right',
          }}
        >
          {showCharsLeft &&
            charsLeft < DISPLAY_CHARS_LEFT &&
            `${charsLeft} characters remaining`}
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};

export default ProjectDescriptionField;
