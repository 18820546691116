import { Components, Theme } from '@mui/material';

const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.border.default,
      borderRadius: theme.shape.borderRadius,
    }),
  },
};

export default MuiCard;
