import { Components, Theme } from '@mui/material';

const MuiListItem: Components<Theme>['MuiListItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }),
  },
};

export default MuiListItem;
