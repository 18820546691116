import { SnowflakeFormState } from './types';

// Setting the default values for the form
export const initialSnowflakeForm: SnowflakeFormState = {
  name: '',
  type: 'snowflake',
  credentials: {
    password: '',
  },
  connection_target_type: 'source',
  config: {
    username: '',
    host: '',
    database: '',
    warehouse: '',
    schema: '',
    params: '',
  },
};
