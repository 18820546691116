import { Components, Theme } from '@mui/material';

const MuiPagination: Components<Theme>['MuiPagination'] = {
  defaultProps: {
    shape: 'rounded',
    siblingCount: 1,
    showFirstButton: false,
    showLastButton: false,
  },
};

export default MuiPagination;
