const BILLING_TIER = {
  FREE: 0,
  DEVELOPER: 1,
  TEAM: 2,
  ENTERPRISE: 3,
};

const labelMap = Object.freeze({
  [BILLING_TIER.FREE]: 'Developer',
  [BILLING_TIER.DEVELOPER]: 'Developer',
  [BILLING_TIER.TEAM]: 'Team',
  [BILLING_TIER.ENTERPRISE]: 'Enterprise',
});

const getLabel = level => labelMap[level];

const ordered = [
  BILLING_TIER.FREE,
  BILLING_TIER.DEVELOPER,
  BILLING_TIER.TEAM,
  BILLING_TIER.ENTERPRISE,
];

export default Object.freeze(
  Object.assign(BILLING_TIER, { labelMap, getLabel, ordered })
);
