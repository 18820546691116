type UserNames = {
  firstname?: string;
  lastname?: string;
  email: string;
};

export const UserFormatter = {
  /**
   * Replaces all but the first 4 characters of an API key with asterisks.
   *
   * @param apiKey
   * @returns string
   */
  censorAPIKey: (apiKey?: string) =>
    apiKey ? apiKey.slice(0, 4).concat('•'.repeat(apiKey.length - 4)) : '',
  /**
   * Formats a user's name, optionally appending "(You)" if the user is the logged-in user.
   * The formatter handles missing uses whatever is available from firstname and lastname,
   * falling back to email if both are missing.
   *
   * e.g. "John Doe", "John", "Doe", "john.doe@example.com"
   *
   * Then it appends "(You)" if the user is the logged-in user.
   *
   * @param User
   * @param isLoggedInUser boolean
   * @returns string
   */
  name: (
    { firstname, lastname, email }: UserNames,
    isLoggedInUser: boolean = false
  ) => {
    const fullName = [firstname, lastname].filter(Boolean).join(' ').trim();
    const nameOrEmail = fullName || email;

    return `${nameOrEmail}${isLoggedInUser ? ` (You)` : ''}`;
  },
  oauth: (serviceKey: string) => {
    switch (serviceKey) {
      case 'google':
        return 'Google';

      case 'github':
        return 'GitHub';

      case 'magic-link':
        return 'WorkOS Magic Link';

      case 'sso':
        return 'SSO';

      default:
        return '';
    }
  },
};
