import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link as InternalLink } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';

type Props = React.PropsWithChildren<{
  to: RouteComponentProps['location'];
}> &
  LinkProps;

const StyledRouterLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ children, ...props }, ref) => (
    <Link target="_self" component={InternalLink} ref={ref} {...props}>
      {children}
    </Link>
  )
);

StyledRouterLink.displayName = 'StyledRouterLink';

export default StyledRouterLink;
