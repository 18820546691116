import * as React from 'react';
import { Grid, IconButton, Link, List, Typography } from '@mui/material';
import QubitCard, { Props as QubitCardProps } from 'common/QubitCard';
import uploadSlice from 'common/Upload/uploadDuck';
import { Check, X as XIcon } from 'icons/figma';
import { useAppSelector } from 'src/store.types';
import { isLoading, isReady } from 'utils/stage';
import useActions from 'utils/useActions';
import UploadProgressItem from './Item';

const UploadProgressCard = (props: QubitCardProps) => {
  const { uploads, stage, error } = useAppSelector(state => state.upload);
  const [cancelAllConfirm, setCancelAll] = React.useState(false);
  const { cancelUpload, reset } = useActions(uploadSlice.actions, []);
  const hasActiveUploads =
    Object.values(uploads).filter(
      ({ fileKey }) =>
        isReady(stage.uploads[fileKey]) || isLoading(stage.uploads[fileKey])
    ).length > 0;

  React.useEffect(() => () => setCancelAll(false), []);

  if (!uploads.length) {
    return null;
  }

  return (
    <QubitCard
      CardActionProps={{ sx: { flexWrap: 'nowrap', whiteSpace: 'nowrap' } }}
      actions={
        <>
          {cancelAllConfirm ? (
            <Grid container wrap="nowrap" alignItems="center">
              <Typography>
                This will cancel all uploads. Are you sure?
              </Typography>
              <IconButton
                sx={{ ml: 2 }}
                size="small"
                color="error"
                onClick={() => {
                  setCancelAll(false);
                  reset();
                }}
              >
                <Check height={16} width={16} />
              </IconButton>
              <Typography>|</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setCancelAll(false)}
              >
                <XIcon height={16} width={16} />
              </IconButton>
            </Grid>
          ) : (
            <Link
              onClick={() => (hasActiveUploads ? setCancelAll(true) : reset())}
            >
              Close
            </Link>
          )}
        </>
      }
      {...props}
    >
      <List
        sx={theme => ({
          maxWidth: theme.breakpoints.values.sm,
        })}
      >
        {uploads.map(({ size, path, fileKey }) => (
          <UploadProgressItem
            key={fileKey}
            id={fileKey}
            error={error.uploads[fileKey]}
            stage={stage.uploads[fileKey]}
            cancelUpload={cancelUpload}
            size={size}
            name={path}
          />
        ))}
      </List>
    </QubitCard>
  );
};

export default UploadProgressCard;
