import * as React from 'react';
import { Components, Theme } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'src/resources/icons/figma';

const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {
    color: 'secondary',
    components: {
      previous: () => <ChevronLeft width={16} height={16} />,
      next: () => <ChevronRight width={16} height={16} />,
    },
  },
};

export default MuiPaginationItem;
