import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isGretelHost } from 'src/api/utils/baseQuery';
import { isAuthRoute } from 'src/routes';
import { nullFilter } from 'utils/data';
import { redirectToLogin } from 'utils/login';
import { clearToken, getAuthorization } from 'utils/token';

export class FetchError extends Error {
  status: number;
  response: unknown;

  constructor(message: string, status: number, response: unknown) {
    super(message);
    this.status = status;
    this.response = response;
  }
}

/**
 * Converts Error instances into standard objects so redux won't complain.
 */
export const serializableError = (error: Error | object | string) =>
  error instanceof FetchError
    ? { message: error.message, status: error.status, response: error.response }
    : typeof error === 'string'
      ? { message: error }
      : error;

/**
 * This should be able to handle any `@reduxjs/toolkit/query` requests
 * just place in the catch block.
 *
```typescript
  try {
    const result = request();
    if ('error' in result) throw result.error;
    // do result.data handling
  } catch (error) {
    if (isFetchAbortSignal(error)) {
      // do abort handling
    } else {
      // do error handling
    }
  }
``` */
export const isFetchAbortSignal = (error: FetchBaseQueryError) => {
  if (!error) {
    return false;
  }
  if (!(typeof error === 'object')) {
    return false;
  }
  if (!('error' in error && typeof error.error === 'string')) {
    return false;
  }
  if (error.error === 'AbortError: The user aborted a request.') {
    return true;
  }
  return false;
};

const buildUrl = (path: string, query) =>
  Object.values(nullFilter(query)).length
    ? new URL(
        `${process.env.BACKEND_URL}${path}?${new URLSearchParams(
          nullFilter(query)
        )}`
      )
    : new URL(`${process.env.BACKEND_URL}${path}`);

const fetcher = async (
  path: string,
  options?: RequestInit & { query?: object }
) => {
  const { query, ...fetchOptions } = options ?? {};
  const headers = {
    accept: 'application/json',
  };

  headers['authorization'] = isGretelHost()
    ? 'use-credentials'
    : getAuthorization();

  if (options?.method && ['POST', 'PUT', 'PATCH'].includes(options.method)) {
    headers['Content-Type'] = 'application/json';
  }

  return fetch(buildUrl(path, query).toString(), {
    headers,
    credentials: isGretelHost() ? 'include' : 'omit',
    ...fetchOptions,
  })
    .then(async response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.status === 401 && !isAuthRoute()) {
        clearToken();
        redirectToLogin({
          redirect: window.location.href,
        });
      }
      const body = await response.json();
      throw new FetchError(
        body?.message || response.statusText,
        response.status,
        body
      );
    })
    .then(response => response.json())
    .catch(error => {
      throw serializableError(error);
    });
};

export const fetchRemote = (
  url: string,
  options: RequestInit & { accept?: string } = {}
) =>
  fetch(url, {
    ...options,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      Accept: options.accept ?? '*/*',
      ...(options.headers ?? {}),
    },
  });

export default fetcher;
