import * as React from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { Check, LightDarkMode } from 'icons/figma';
import { useThemeMode } from '../../Theming';
import { ThemeMode } from '../../Theming/Context';

const ThemeOptionItem = ({ value, selected, onClick, children }) => {
  return (
    <MenuItem onClick={() => onClick(value)} selected={selected}>
      <ListItemText>{children}</ListItemText>
      {selected && (
        <ListItemIcon>
          <Check width={16} height={16} />
        </ListItemIcon>
      )}
    </MenuItem>
  );
};

export const ThemeMenu = () => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'themeMnu' });
  const { mode, setMode } = useThemeMode();

  const handleThemeOption = (theme: ThemeMode) => {
    setMode(theme);
  };

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        aria-label="Theme mode"
        aria-controls="themeMnu"
        aria-haspopup="true"
      >
        <LightDarkMode />
      </IconButton>
      <Menu variant="menu" disableAutoFocusItem {...bindMenu(popupState)}>
        <ThemeOptionItem
          value="light"
          selected={mode === 'light'}
          onClick={handleThemeOption}
        >
          Light
        </ThemeOptionItem>
        <ThemeOptionItem
          value="dark"
          selected={mode === 'dark'}
          onClick={handleThemeOption}
        >
          Dark
        </ThemeOptionItem>
        <ThemeOptionItem
          value="auto"
          selected={mode === 'auto'}
          onClick={handleThemeOption}
        >
          System
        </ThemeOptionItem>
      </Menu>
    </>
  );
};

export default ThemeMenu;
