import { Components, Theme } from '@mui/material';

const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variant: 'body1',
    variantMapping: {
      subtitle1: 'span',
      subtitle2: 'span',
      body1: 'span',
      body2: 'span',
      caption: 'span',
      overline: 'span',
      display1: 'h2',
      display2: 'h2',
      body1Medium: 'span',
      body1Strong: 'span',
      body2Medium: 'span',
      body2Strong: 'span',
      code: 'code',
      button1: 'span',
      button2: 'span',
    },
  },
};

export default MuiTypography;
