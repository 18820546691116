import React from 'react';
import { SvgIcon } from '@mui/material';
import QuadDotsAnimated from 'common/QuadDotsAnimated';
import MODEL_STATUS from 'constants/modelStatus';
import { Model } from 'src/api/types/Model';
import QuadDots from 'src/resources/icons/QuadDots.svg';

/**
 * Utilities to help us navigate different things about models
 */
export const getModelElement = (model: Model | undefined) => {
  return model?.config?.models?.[0];
};

export const getModelDataSource = (model: Model): string | undefined => {
  const modelElement = getModelElement(model);
  const modelElementDataSource =
    modelElement && Object.values(modelElement)?.[0]?.data_source?.[0];

  // if no data source on the ModelElement, check for one in the autouse_config,
  // which is used by playground model generation
  return modelElementDataSource ?? model.autouse_config?.data_source;
};

export const modelIconByStatus = {
  [MODEL_STATUS.CREATED]: (
    <SvgIcon color="primary">
      <QuadDots />
    </SvgIcon>
  ),
  [MODEL_STATUS.PENDING]: (
    <SvgIcon color="primary">
      <QuadDots />
    </SvgIcon>
  ),
  [MODEL_STATUS.ACTIVE]: (
    <SvgIcon color="primary">
      <QuadDotsAnimated />
    </SvgIcon>
  ),
};
