import * as React from 'react';
import { Container } from '@mui/material';
import { ErrorBoundary } from 'common/ErrorBoundary';
import { MessageBox } from 'design-system';
import ProjectPageHeader from './ProjectPageHeader';

const ProjectLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary
      fallback={<UnexpectedProjectPageError />}
      scope="project-layout"
    >
      <ProjectPageHeader />

      {children}
    </ErrorBoundary>
  );
};

ProjectLayout.displayName = 'ProjectLayout';

export default ProjectLayout;

const UnexpectedProjectPageError = () => {
  return (
    <Container>
      <MessageBox
        title="Oh no! Something went wrong."
        subtitle="We encountered an unexpected error."
        color="error"
      />
    </Container>
  );
};
