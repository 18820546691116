import { Components, Theme } from '@mui/material';

const MuiListItemAvatar: Components<Theme>['MuiListItemAvatar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: theme.spacing(8),
    }),
  },
};

export default MuiListItemAvatar;
