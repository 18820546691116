import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import fetcher from 'common/utils/fetch';
import { isReady, STAGE } from 'common/utils/stage';
import {
  ListServerlessTenantsApiResponse,
  ServerlessTenant,
} from 'src/api/pilot/serverless';
import { Store } from 'src/store.types';

type TenantState = {
  stage: STAGE;
  selected?: ServerlessTenant;
  tenants: ServerlessTenant[];
};

const initialState: TenantState = {
  stage: STAGE.READY,
  tenants: [],
};

export const loadTenants = createAsyncThunk(
  'tenants/load',
  async (_, { getState }) => {
    const { tenants } = (getState() as Store).tenants;
    if (tenants.length) {
      return tenants;
    }

    const response: ListServerlessTenantsApiResponse = await fetcher(
      '/v1/serverless/tenants'
    );
    return response.tenants;
  }
);

export const initializeSelected = createAsyncThunk(
  'tenants/initializeSelected',
  async (_, { getState, dispatch }) => {
    const { selected, stage } = (getState() as Store).tenants;
    if (selected) {
      return selected;
    }
    if (isReady(stage)) {
      const tenants = await dispatch(loadTenants()).unwrap();
      if (tenants?.length) {
        return tenants[0];
      }
    }
  }
);

export const tenantsDuck = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<ServerlessTenant>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTenants.pending, state => {
      state.stage = STAGE.LOADING;
    });
    builder.addCase(loadTenants.fulfilled, (state, { payload }) => {
      if (payload?.length) {
        state.stage = STAGE.DONE;
        state.tenants = payload;
      }
    });
    builder.addCase(loadTenants.rejected, state => {
      state.stage = STAGE.ERROR;
    });
    builder.addCase(initializeSelected.fulfilled, (state, { payload }) => {
      if (payload) {
        state.selected = payload;
      }
    });
  },
});

export const { setSelected } = tenantsDuck.actions;
