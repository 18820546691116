import { Components, Theme } from '@mui/material';

const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.h2,
      padding: theme.spacing(6),
      paddingBottom: theme.spacing(3),
    }),
  },
};

export default MuiDialogTitle;
