import * as React from 'react';
import { SidebarStatusContext } from './Context';

export const SidebarStatusProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const value = React.useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen]
  );

  return (
    <SidebarStatusContext.Provider value={value}>
      {children}
    </SidebarStatusContext.Provider>
  );
};

SidebarStatusProvider.displayName = 'SidebarStatusProvider';
