import * as React from 'react';
import { ThemeModeContext } from './Context';

export const useThemeMode = () => {
  const context = React.useContext(ThemeModeContext);

  if (!context) {
    throw new Error('useThemeMode must be used within a ThemeModeProvider');
  }

  const { mode, setMode } = context;

  return {
    mode,
    setMode,
  };
};
