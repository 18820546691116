import { matchPath } from 'react-router';
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import GretelAPI from 'src/api';
import { Project } from 'src/api/types/Project';
import {
  USE_CASES_DATASOURCES_ROUTE,
  USE_CASES_PROJECTS_ROUTE,
} from 'src/routes';
import { Store } from 'src/store.types';
import { FileUpload, getFileStats, ValidationResponse } from 'utils/data';
import fetcher, { fetchRemote, serializableError } from 'utils/fetch';
import Formatters from 'utils/formatters';
import { logger } from 'utils/logger';
import { isDone, isLoading, isReady, STAGE } from 'utils/stage';

// Keeping non-serializable File out of redux state.
// https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
let FILES: Record<string, FileUpload> = {};

export type KeyedUpload = FileUpload & {
  fileKey: string;
};

type UploadState = {
  error: {
    queue?: string | Error;
    uploads: Record<string, string | Error>;
  };
  stage: {
    queue: STAGE;
    uploads: Record<string, STAGE>;
  };

  uploads: KeyedUpload[];
};

const initialState: UploadState = {
  error: {
    // Keyed by fileKey
    uploads: {},
  },
  stage: {
    // Used for pre-processing states (before an item can be added to the queue) and errors
    queue: STAGE.READY,
    // Keyed by fileKey
    uploads: {},
  },
  uploads: [
    /*
    {
      // Name of the upload.
      name: 'FileName.csv',
      // Upload length in bytes.
      size: 65585,
      // The upload's artifact key, if any
      artifactKey,
      // Total count of columns
      columnCount,
      // Total count of rows
      rowCount,
      // The destination project
      projectId,
      // Boolean flag indicating that the previous upload's new project ID should be used, or a project created for it.
      newProject,
      // Boolean flag indicating that the upload is for a new model.
      forModel,
      // Optional name for the new project
      projectName,
      // Key for file/parsed reference in local files object
      fileKey
    }
     */
  ],
};

/**
 * @private
 * Adds items to the uploads map after generating unique IDs for them.
 * @param {Array|Object} payload Item(s) to queue for upload.
 * @returns {Array<String>} List of IDs of queued items.
 */
const queueItems = (
  payload: Array<FileUpload | KeyedUpload> | FileUpload | KeyedUpload
) =>
  (Array.isArray(payload) ? payload : [payload]).map(file => {
    if ('fileKey' in file) {
      return file.fileKey;
    }

    const newId = uuidv4();
    FILES[newId] = file;
    return newId;
  });

/*
  Actions needed by ActionCreators.
 */
const queue = createAction<KeyedUpload[]>('upload/queue');
const updateQueued = createAction<KeyedUpload[]>('upload/updateQueued');
const uploadProgress = createAction<{ fileKey: string; stage: STAGE }>(
  'upload/uploadProgress'
);

export const ActionCreators = {
  queueUploads: createAsyncThunk(
    'upload/queueUploads',
    async (
      {
        files,
        project,
      }: {
        files: FileUpload[];
        project?: Project;
      },
      { dispatch }
    ) => {
      const uploads = queueItems(files).map(
        (id, index) =>
          ({
            ...files[index],
            fileKey: id,
            projectId: files[index].projectId || project?.guid,
          }) as KeyedUpload
      );
      /** @see "extraReducers[queue.type]" */
      dispatch(queue(uploads));

      await dispatch(ActionCreators.startUploads()).catch(error => {
        /**
         * @note
         * This thunk isn't concerned with error handling,
         * `startUploads` actually deals with failures by updating
         * an in memory Record so that extra information is kept.
         * the purpose of `queueUploads` is just to kick off `startUploads`
         * and we only await to make this `async` thunk react when the
         * job is complete. -walston */
        // Log the error, as a treat. -md
        logger.error(error);
      });
      return uploads.map(upload => ({
        ...upload,
        artifactKey: FILES[upload.fileKey]?.artifactKey,
      }));
    },
    {
      dispatchConditionRejection: true,
      condition: () => process.env.NODE_ENV !== 'test',
    }
  ),
  queueRejected: createAsyncThunk(
    'upload/queueRejected',
    async (
      {
        files,
        project,
      }: {
        files: ValidationResponse['rejected'];
        project?: Project;
      },
      thunkAPI
    ) => {
      const rejectedUploads = queueItems(files.map(({ file }) => file)).map(
        (id, index) =>
          ({
            ...files[index].file,
            fileKey: id,
            projectId: files[index].file.projectId || project?.guid,
          }) as KeyedUpload
      );
      thunkAPI.dispatch(queue(rejectedUploads));
      return rejectedUploads;
    },
    { dispatchConditionRejection: true }
  ),
  startUploads: createAsyncThunk(
    'upload/startUploads',
    async (_, { getState, dispatch }) => {
      const { uploads, stage } = (getState() as Store).upload;

      if (
        Object.values(stage.uploads).filter(stage => isLoading(stage)).length >
        0
      ) {
        return;
      }
      // Find the next item in the queue that is STAGE.READY
      const nextUpload = uploads.find(({ fileKey }) =>
        isReady(stage.uploads[fileKey])
      );
      if (nextUpload) {
        await dispatch(ActionCreators.startUpload(nextUpload));
        // recursively get next upload on a new thread
        await dispatch(ActionCreators.startUploads());
      }
    },
    { dispatchConditionRejection: true }
  ),

  /*
  // we're actually uploading in this thunk,
  */
  startUpload: createAsyncThunk(
    'upload/startUpload',
    async (
      upload: {
        fileKey: string; // GUID
        path: string; // filename
        projectId?: string; // proj_GUID
        newProject: boolean;
        projectName?: string;
      },
      { dispatch, rejectWithValue }
    ) => {
      const { fileKey, newProject } = upload;
      let projectId = upload.projectId;
      const file = FILES[fileKey];
      try {
        let { columnCount, rowCount } = file;
        if (!file.parsed) {
          dispatch(uploadProgress({ fileKey, stage: STAGE.PROCESSING }));
          try {
            const {
              result,
              columnCount: colCount,
              rowCount: rows,
              fileType,
            } = await getFileStats({ file });

            FILES[fileKey].parsed = result;
            FILES[fileKey].columnCount = columnCount = colCount;
            FILES[fileKey].rowCount = rowCount = rows;
            FILES[fileKey].fileType = fileType;
          } catch (fileError) {
            // Usually an error from getFileStats, not something we need to track in dd. -md
            return rejectWithValue(fileError);
          }
        }
        if (newProject && !projectId) {
          dispatch(uploadProgress({ fileKey, stage: STAGE.CREATING }));
          const res = await dispatch(
            GretelAPI.endpoints.createProject.initiate({
              background: true,
              display_name:
                upload.projectName || file?.name?.replace(/\.[^.]*$/, ''),
            })
          );
          if ('data' in res) {
            projectId = res.data.id;
          }
        }
        dispatch(uploadProgress({ fileKey, stage: STAGE.UPDATING }));
        const artifactTokenAction = await dispatch(
          ActionCreators.getArtifactUploadToken({
            filename: file?.name,
            projectId,
          })
        );
        if (
          artifactTokenAction.type ===
          ActionCreators.getArtifactUploadToken.rejected
        ) {
          return rejectWithValue(
            Formatters.Other.error(
              artifactTokenAction.payload?.response?.context?.filename ||
                artifactTokenAction.payload?.response ||
                artifactTokenAction.payload
            )
          );
        }
        /**
         * @NOTE
         * when we fire off `getArtifactUploadToken`, we're not yet uploading
         * we're asking the back-end to provision us a spot to upload to.
         *
         * the next request is the actual upload.
         */
        const { url, method, key } = artifactTokenAction.payload;
        try {
          await fetchRemote(url, {
            method,
            body: file,
          });
        } catch (e) {
          const error = new Error('Failed to upload artifact');
          logger.error(error, { cause: e, tokenAction: artifactTokenAction });
          return rejectWithValue(error);
        }

        // We can end up in a situation where the FILES object has been cleared
        // before an upload actually finishes. As far as I can tell this can only
        // happen when `reset` is called or the navigation handler is triggered.
        // Ideally we'd have some kind of cancelablle action, but rewriting all
        // of our upload logic seems like a task not worth doing at the moment. -md
        if (FILES[fileKey]) {
          FILES[fileKey].artifactKey = key;
        }

        return {
          fileKey,
          key,
          projectId,
          columnCount,
          rowCount,
        };
      } catch (error) {
        logger.error(error);
        setTimeout(() => dispatch(ActionCreators.startUploads()), 1); // New thread
        return rejectWithValue(serializableError(error));
      }
    },
    { dispatchConditionRejection: true }
  ),
  getArtifactUploadToken: createAsyncThunk(
    'upload/getArtifactUploadToken',
    async (
      { filename, projectId }: { filename: string; projectId: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await fetcher(`/projects/${projectId}/artifacts`, {
          method: 'POST',
          body: JSON.stringify({ filename }),
        });
        return response.data;
      } catch (error) {
        logger.error(error);
        return rejectWithValue(serializableError(error));
      }
    },
    { dispatchConditionRejection: true }
  ),
} as const;

export default createSlice({
  name: 'upload',
  initialState: { ...initialState },
  reducers: {
    cancelUpload: (state, action) => {
      const uploadIndex = state.uploads.findIndex(
        upload => upload.fileKey === action.payload
      );
      if (state.uploads[uploadIndex]?.forModel) {
        delete state.uploads[uploadIndex]?.forModel;
      }
      if (state.uploads[uploadIndex]?.newProject) {
        delete state.uploads[uploadIndex]?.newProject;
      }
      if (!isDone(state.stage.uploads[action.payload])) {
        state.stage.uploads[action.payload] = STAGE.CANCELLED;
      }
    },
    // All this code is gonna be duped cause the state proxies fuck shit up.
    reset: () => {
      FILES = {};
      return { ...initialState };
    },
  },
  extraReducers: base => {
    base.addCase(queue, (state, { payload }) => {
      state.uploads = state.uploads.concat(
        payload.map(upload => {
          state.stage.uploads[upload.fileKey] = STAGE.READY;
          state.error.uploads[upload.fileKey] = 'Upload Error';
          return upload;
        })
      );
    }),
      base.addCase(updateQueued, (state, { payload }) => {
        state.uploads = state.uploads.map(
          upload =>
            payload.find(
              updatedUpload => updatedUpload.fileKey === upload.fileKey
            ) || upload
        );
      }),
      base.addCase(uploadProgress, (state, action) => {
        state.stage.uploads[action.payload.fileKey] = action.payload.stage;
      }),
      base.addCase(ActionCreators.queueUploads.pending, state => {
        delete state.error.queue;
        state.stage.queue = STAGE.LOADING;
      }),
      base.addCase(ActionCreators.queueUploads.fulfilled, state => {
        state.stage.queue = STAGE.DONE;
      }),
      base.addCase(ActionCreators.queueUploads.rejected, (state, action) => {
        state.error.queue = action.payload || action.error;
        state.stage.queue = STAGE.ERROR;
      }),
      base.addCase(ActionCreators.startUpload.pending, (state, action) => {
        const { fileKey } = action.meta.arg || {};
        state.stage.uploads[fileKey] = STAGE.PROCESSING;
        delete state.error.uploads[fileKey];
      }),
      base.addCase(ActionCreators.startUpload.fulfilled, (state, action) => {
        const {
          fileKey,
          key: artifactKey,
          projectId,
          ...rest
        } = action.payload || {};
        const wasNewProject = Boolean(action.meta.arg?.newProject);
        for (let i = 0; i < state.uploads.length; i++) {
          if (
            wasNewProject &&
            state.uploads[i].newProject &&
            !state.uploads[i].projectId
          ) {
            state.uploads[i].projectId = projectId;
          }
          if (state.uploads[i].fileKey === fileKey) {
            state.uploads[i] = {
              ...state.uploads[i],
              artifactKey,
              projectId,
              ...rest,
            };
          }
        }
        state.stage.uploads[fileKey] = STAGE.UPDATED;
      }),
      base.addCase(ActionCreators.startUpload.rejected, (state, action) => {
        const { fileKey } = action.meta.arg || {};
        const uploadIndex = state.uploads.findIndex(
          upload => upload.fileKey === fileKey
        );
        if (state.uploads[uploadIndex]?.forModel) {
          delete state.uploads[uploadIndex]?.forModel;
        }
        if (state.uploads[uploadIndex]?.newProject) {
          delete state.uploads[uploadIndex]?.newProject;
        }
        state.stage.uploads[fileKey] = STAGE.ERROR;
        state.error.uploads[fileKey] = action.payload;
      }),
      base.addCase(
        LOCATION_CHANGE,
        (
          _,
          action: PayloadAction<
            { location: { pathname: string } },
            typeof LOCATION_CHANGE
          >
        ) => {
          const currentPath = action.payload?.location?.pathname;
          const projectRoute = matchPath<{ project: string }>(currentPath, {
            path: '/:project/:route',
          });

          const useCasePath = matchPath(currentPath, {
            path: [
              USE_CASES_DATASOURCES_ROUTE.path,
              USE_CASES_PROJECTS_ROUTE.path,
            ],
          });
          // useCases workflow requires us to hold onto uploaded files temporarily
          // across a couple of urls
          if (!useCasePath && !projectRoute?.params?.project) {
            FILES = {};
            return { ...initialState };
          }
        }
      );
  },
});
