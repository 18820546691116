import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button, ButtonProps, Link, LinkBaseProps } from '@mui/material';

/**
 * @note not sure why but MUI doesn't seem to have typings included for the component prop.
 * This should be considered a hack, and we should look into fixing this at some point.
 * It ought to be generic enough to handle any additional props required via the passed in `component` prop...
 * maybe that's why they had to leave it out */
type OverrideProps =
  | { component: typeof ReactRouterLink; to: string }
  | { component: 'a'; href: string }
  | { component?: never; href?: string };

export type LinkButtonProps = LinkBaseProps &
  Omit<ButtonProps, 'variant'> &
  OverrideProps;

const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ children, component = Button, ...props }, ref) => (
    <Link component={component} ref={ref} variant="button2" {...props}>
      {children}
    </Link>
  )
);

LinkButton.displayName = 'LinkButton';

export default LinkButton;
