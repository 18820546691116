import * as React from 'react';
import { AlertCircle, File as FileText, Slash } from 'icons/figma';
import STAGE, { isCancelled, isError } from 'utils/stage';

type UploadProgressIconProps = React.SVGProps<SVGElement> & {
  stage: STAGE;
};

const UploadProgressIcon = ({ stage, ...props }: UploadProgressIconProps) => {
  if (isError(stage)) {
    return <AlertCircle {...props} />;
  }
  if (isCancelled(stage)) {
    return <Slash {...props} />;
  }
  return <FileText {...props} />;
};
export default UploadProgressIcon;
