import * as React from 'react';
import type { Toast, ToastOptions, ToastType } from 'react-hot-toast';
import { toast } from 'react-hot-toast';
import {
  Alert,
  AlertColor,
  LinkProps,
  styled,
  Typography,
} from '@mui/material';
import LinkButton from 'common/input/LinkButton';

export type ToastAlertType = {
  severity: AlertColor;
  close: boolean;
  action: {
    label: string;
    href: string;
  };
};

export type DefaultToastAlertOptions = ToastOptions &
  Partial<ToastAlertType> & {
    [key in ToastType]?: ToastOptions & Partial<ToastAlertType>;
  };

export type ToastAlert = Toast & ToastAlertType;

type UpdateHeightType = (toastId: string, height: number) => void;

type ToastLinkProps = LinkProps & {
  severity: AlertColor;
};

const StyledLinkButton = styled(LinkButton, {
  shouldForwardProp: prop => prop !== 'severity',
})<ToastLinkProps>(({ severity, theme }) => ({
  // Only for toast "action" links
  display: 'flex-inline',
  minWidth: 0,
  padding: 0,
  paddingTop: 2,
  color: theme.palette[severity][700],
  ':hover': {
    color: theme.palette[severity][800],
  },
  ':active,:focus': {
    boxShadow: 'none',
    textDecoration: 'underline',
    color: theme.palette[severity][900],
  },
}));

const OurToast = ({
  toastObject,
  offset,
  updateHeight,
}: {
  toastObject: ToastAlert;
  offset: number;
  updateHeight: UpdateHeightType;
}) => {
  const ref = (el: HTMLDivElement) => {
    if (el && !toastObject.height) {
      const height = el.getBoundingClientRect().height;
      updateHeight(toastObject.id, height);
    }
  };

  const handleClick = () => {
    toast.dismiss(toastObject.id);
  };

  const getSeverity = (): AlertColor => {
    if (toastObject?.severity) {
      return toastObject.severity;
    } else if (toastObject.type === 'success') {
      return 'success';
    } else if (toastObject.type === 'error') {
      return 'error';
    }
    return 'info';
  };

  return (
    <Alert
      key={toastObject.id}
      ref={ref}
      shadow
      sx={{
        position: 'absolute',
        right: 0,
        minWidth: '200px',
        maxWidth: '320px',
        transition: 'all 0.5s ease-out',
        opacity: toastObject.visible ? 1 : 0,
        transform: `translateY(${offset}px)`,
        ...toastObject.style,
      }}
      {...toastObject.ariaProps}
      className={toastObject.className}
      severity={getSeverity()}
      onClose={
        toastObject?.close ? () => toast.dismiss(toastObject.id) : undefined
      }
      data-testid={`toast-${toastObject.type}`}
      data-message={toastObject.message}
    >
      <Typography variant="body2Medium" component="p">
        {typeof toastObject.message === 'function'
          ? toastObject.message(toastObject)
          : toastObject.message}
      </Typography>
      {toastObject?.action ? (
        <StyledLinkButton
          severity={getSeverity()}
          href={toastObject.action.href}
          onClick={handleClick}
          target="_self"
        >
          {toastObject.action.label}
        </StyledLinkButton>
      ) : null}
    </Alert>
  );
};

export default OurToast;
