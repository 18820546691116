import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { GRETEL_FAQ_IMPROVE_ACCURACY } from 'common/constants/urls';
import useConsoleRoute from 'common/utils/useConsoleRoute';
import { File, RefreshCw } from 'icons/figma';
import { DASHBOARD_ROUTE } from 'src/routes';

export const NextStepsTabNotSoSuccessContent = () => {
  const { go } = useConsoleRoute();

  return (
    <Grid display="flex" gap={2} sx={{ justifyContent: 'space-between' }}>
      <Button
        id="model-complete-notsosuccess-report"
        color="secondary"
        startIcon={<File />}
        href={GRETEL_FAQ_IMPROVE_ACCURACY}
        sx={{ flex: 1 }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Improve model quality
      </Button>
      <Button
        id="model-complete-notsosuccess-another-model"
        color="primary"
        startIcon={<RefreshCw />}
        onClick={() => go(DASHBOARD_ROUTE)}
        sx={{ flex: 1 }}
      >
        Try another model
      </Button>
    </Grid>
  );
};
