import { alpha, PaletteOptions } from '@mui/material/styles';
import {
  common,
  error,
  grey,
  info,
  primary,
  success,
  warning,
} from './colorValues';

export const paletteLight: PaletteOptions = {
  primary: {
    ...primary,
    main: primary[600],
    light: primary[400],
    dark: primary[800],
    contrastText: primary[50],
  },
  secondary: {
    ...grey,
    main: grey[900],
    light: grey[700],
    dark: grey[950],
    contrastText: grey[50],
  },
  success: {
    ...success,
    main: success[600],
    light: success[200],
    dark: success[700],
    contrastText: success[50],
  },
  info: {
    ...info,
    main: info[600],
    light: info[200],
    dark: info[700],
    contrastText: info[50],
  },
  warning: {
    ...warning,
    main: warning[600],
    light: warning[300],
    dark: warning[700],
    contrastText: warning[50],
  },
  error: {
    ...error,
    main: error[600],
    light: error[200],
    dark: error[700],
    contrastText: error[50],
  },
  border: {
    default: grey[200],
    strong: grey[300],
  },
  // Setting to border default here for components that use it.
  divider: grey[200],
  text: {
    primary: grey[900],
    secondary: grey[600],
    disabled: grey[400],
  },
  background: {
    default: common.white,
    secondary: grey[50],
    inset: grey[100],
    // Setting to default here for components that use it.
    paper: common.white,
  },
  action: {
    hoverOpacity: 0.1,
    activatedOpacity: 0.1,
    disabledOpacity: 0.4,
    focusOpacity: 0.15,
    selectedOpacity: 0.1,
    hover: alpha(grey[950], 0.1),
    active: alpha(grey[950], 0.1),
    selected: alpha(grey[950], 0.1),
    disabled: alpha(grey[950], 0.4),
    disabledBackground: alpha(grey[950], 0.1),
    focus: alpha(grey[950], 0.15),
    focusBoxShadow: `0px 0px 0px 1px ${common.white}, 0px 0px 0px 3px ${primary[400]}`, // primary.light
    errorBoxShadow: `0px 0px 0px 1px ${common.white}, 0px 0px 0px 3px ${error[600]}`, // error.main
  },
};
