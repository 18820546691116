import * as React from 'react';
import {
  ConfigCatProvider as ReactConfigCatProvider,
  PollingMode,
} from 'configcat-react';

type Props = Pick<
  React.ComponentProps<typeof ReactConfigCatProvider>,
  'options' | 'pollingMode'
> & {
  inTestMode?: boolean;
  children: JSX.Element | null;
};

/**
 * Wrapper for Environment variable Typescript protection. Using configcat-react library.
 * Docs: https://configcat.com/docs/sdk-reference/react/
 *
 * Is used in tandem with { useFeatureFlag } from 'configcat-react' to provide flag settings.
 *
 * Options:
 * - pollingMode: AutoPoll (default), Lazy Loading, Manual Polling
 * - options: See Docs link above for full list of options
 * - inTestMode: boolean - set to true to use without env variable present (e.g., storybook)
 *
 * @param children
 * @returns React Component
 */
const ConfigCatProvider = ({
  pollingMode = PollingMode.AutoPoll,
  options,
  inTestMode,
  children,
}: Props) => {
  if (!inTestMode && !process.env.CONFIG_CAT_KEY) {
    console.error(
      'Config Cat env key not found, Feature Flags will revert to default'
    );
    return children;
  }

  return (
    <ReactConfigCatProvider
      sdkKey={process.env.CONFIG_CAT_KEY || 'TEST'}
      pollingMode={pollingMode}
      options={options}
    >
      {children}
    </ReactConfigCatProvider>
  );
};

export default ConfigCatProvider;
