import * as React from 'react';
import {
  capitalize,
  Chip,
  ChipProps,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import Switch from 'switch-function';
import ERROR_IDS, { ERROR_IDS_TYPE } from 'common/constants/errorIDs';
import { Info } from 'icons/figma';
import { openZenDeskWidget } from 'utils/zenDesk';

type Props = ChipProps & {
  status: string;
  tooltip?: boolean;
  className?: string;
  error?: { id: ERROR_IDS_TYPE; msg: string };
};

const ModelStatusChip = React.forwardRef<HTMLDivElement, Props>(
  ({ status, className, tooltip, error, ...props }, ref) => {
    const COLOR_KEYS = {
      created: 'success',
      pending: 'info',
      active: 'primary',
      completed: 'success',
      error: 'error',
      cancelled: 'default',
      lost: 'default',
    };

    return tooltip && error?.id ? (
      <Tooltip
        arrow
        variant="light"
        placement="bottom-start"
        componentsProps={{
          tooltip: { sx: { wordBreak: 'break-word', minWidth: '400px' } },
        }}
        title={
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item sx={{ color: 'error.main', mr: 4 }}>
              <Info height={16} width={16} />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <>
                  {Switch(error.id, {
                    [ERROR_IDS.UNHANDLED]:
                      'There was an unknown issue that stopped the generation process. Try again or submit a ',
                    [ERROR_IDS.SYN_INVALID_RECORDS]:
                      'There were too many invalid records that stopped the generation process. Try again or submit a ',
                    [ERROR_IDS.MAX_RUNTIME]:
                      'The job exceeded the maximum run time. Try again with a reduced number of records. You can also submit a ',
                    [ERROR_IDS.SYN_TOO_FEW_RECORDS]:
                      'There were too few records that stopped the generation process. Try again with an increased number of records. You can also submit a ',
                    [ERROR_IDS.LOAD_DATA]:
                      'Training data was not properly loaded. Try again or submit a ',
                    [ERROR_IDS.SYN_MISSING_DELIM]:
                      'Missing field delimiter that stopped generation process. Try again or submit a ',
                    default: error.msg,
                  })}
                  {Object.values(ERROR_IDS).includes(error.id) && (
                    <>
                      <Link
                        sx={{ fontWeight: 'fontWeightMedium' }}
                        onClick={openZenDeskWidget}
                      >
                        support ticket
                      </Link>{' '}
                      and we will get back to you ASAP.
                    </>
                  )}
                </>
              </Typography>
            </Grid>
          </Grid>
        }
      >
        <Chip
          size="small"
          className={className}
          color={COLOR_KEYS[status]}
          label={capitalize(status)}
          ref={ref}
          {...props}
        />
      </Tooltip>
    ) : (
      <Chip
        size="small"
        className={className}
        color={COLOR_KEYS[status]}
        label={capitalize(status)}
        ref={ref}
        {...props}
      />
    );
  }
);

ModelStatusChip.displayName = 'ModelStatusChip';

export default ModelStatusChip;
