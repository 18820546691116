import * as React from 'react';
import { SidebarStatusContext } from './Context';

export const useSidebarStatus = () => {
  const context = React.useContext(SidebarStatusContext);

  if (!context) {
    throw new Error(
      'useSidebarStatus must be used within a SidebarStatusProvider'
    );
  }

  const { isOpen, setIsOpen } = context;
  const openSidebar = () => setIsOpen(true);
  const closeSidebar = () => setIsOpen(false);

  return {
    isSidebarOpen: isOpen,
    openSidebar,
    closeSidebar,
  };
};
