import { ShapeOptions } from '@mui/system';

interface Radii {
  small: string;
  base: string;
  medium: string;
  large: string;
}
declare module '@mui/system/createTheme/shape' {
  interface Shape {
    radii: Radii;
  }
}

export const shape: ShapeOptions = {
  borderRadius: 4,
  radii: { small: '2px', base: '4px', medium: '6px', large: '8px' },
};
