import React from 'react';
import { Typography } from '@mui/material';
import { logger } from 'utils/logger';

declare global {
  interface Window {
    /**
     * This is a function exposed by Termly that opens the preference
     * modal. There documented implementation is to just use the "termly-display-preferences"
     * class and the embedded script will handle the binding the event listener. This
     * doesn't work since this is a CSR app and the script is run before the app has
     * fully rendered. This is a workaround to call the function directly.
     */
    displayPreferenceModal: () => void;
  }
}

export const CookieConsentPreference = () => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ '&:hover': { textDecoration: 'none', cursor: 'pointer' } }}
      className="termly-display-preferences"
      onClick={() => {
        if (typeof window.displayPreferenceModal === 'function') {
          window.displayPreferenceModal();
        } else {
          logger.error(new Error('Termly cookie manager is not loaded'));
        }
      }}
    >
      Consent Preferences
    </Typography>
  );
};
