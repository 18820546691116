import { api } from './baseApi';

export const addTagTypes = ['Workflows'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getWorkflows: build.query<GetWorkflowsApiResponse, GetWorkflowsApiArg>({
        query: () => ({ url: `/v1/workflows` }),
        providesTags: ['Workflows'],
      }),
      createWorkflow: build.mutation<
        CreateWorkflowApiResponse,
        CreateWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows`,
          method: 'POST',
          body: queryArg.createWorkflowRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      createWorkflowRun: build.mutation<
        CreateWorkflowRunApiResponse,
        CreateWorkflowRunApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs`,
          method: 'POST',
          body: queryArg.createWorkflowRunRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      searchWorkflowRuns: build.query<
        SearchWorkflowRunsApiResponse,
        SearchWorkflowRunsApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/search`,
          params: {
            query: queryArg.query,
            expand: queryArg.expand,
            limit: queryArg.limit,
            skip: queryArg.skip,
            sort: queryArg.sort,
          },
        }),
        providesTags: ['Workflows'],
      }),
      searchWorkflowTasks: build.query<
        SearchWorkflowTasksApiResponse,
        SearchWorkflowTasksApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/tasks/search`,
          params: {
            query: queryArg.query,
            limit: queryArg.limit,
            skip: queryArg.skip,
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Workflows'],
      }),
      getWorkflowTask: build.query<
        GetWorkflowTaskApiResponse,
        GetWorkflowTaskApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/tasks/${queryArg.workflowTaskId}`,
        }),
        providesTags: ['Workflows'],
      }),
      getWorkflowRun: build.query<
        GetWorkflowRunApiResponse,
        GetWorkflowRunApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/${queryArg.workflowRunId}`,
          params: {
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Workflows'],
      }),
      cancelWorkflowRun: build.mutation<
        CancelWorkflowRunApiResponse,
        CancelWorkflowRunApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/${queryArg.workflowRunId}/cancel`,
          method: 'PATCH',
        }),
        invalidatesTags: ['Workflows'],
      }),
      retryWorkflowRunFailures: build.mutation<
        RetryWorkflowRunFailuresApiResponse,
        RetryWorkflowRunFailuresApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/${queryArg.workflowRunId}/retry_failures`,
          method: 'POST',
          body: queryArg.retryWorkflowRunFailuresRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      searchWorkflows: build.query<
        SearchWorkflowsApiResponse,
        SearchWorkflowsApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/search`,
          params: {
            query: queryArg.query,
            sort: queryArg.sort,
            limit: queryArg.limit,
            skip: queryArg.skip,
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Workflows'],
      }),
      validateWorkflowAction: build.mutation<
        ValidateWorkflowActionApiResponse,
        ValidateWorkflowActionApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/validate-action`,
          method: 'POST',
          body: queryArg.body,
          params: {
            runner_mode: queryArg.runnerMode,
          },
        }),
        invalidatesTags: ['Workflows'],
      }),
      validateWorkflowConfig: build.mutation<
        ValidateWorkflowConfigApiResponse,
        ValidateWorkflowConfigApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/validate-config`,
          method: 'POST',
          body: queryArg.validateWorkflowConfigRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      getWorkflow: build.query<GetWorkflowApiResponse, GetWorkflowApiArg>({
        query: queryArg => ({
          url: `/v1/workflows/${queryArg.workflowId}`,
          params: {
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Workflows'],
      }),
      deleteWorkflow: build.mutation<
        DeleteWorkflowApiResponse,
        DeleteWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/${queryArg.workflowId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Workflows'],
      }),
      updateWorkflowConfig: build.mutation<
        UpdateWorkflowConfigApiResponse,
        UpdateWorkflowConfigApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/${queryArg.workflowId}/config`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Workflows'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as WorkflowsAPI };
export type GetWorkflowsApiResponse = /** status 200 OK */ GetWorkflowsResponse;
export type GetWorkflowsApiArg = void;
export type CreateWorkflowApiResponse = /** status 200 OK */ Workflow;
export type CreateWorkflowApiArg = {
  createWorkflowRequest: CreateWorkflowRequest;
};
export type CreateWorkflowRunApiResponse = /** status 200 OK */ WorkflowRun;
export type CreateWorkflowRunApiArg = {
  createWorkflowRunRequest: CreateWorkflowRunRequest;
};
export type SearchWorkflowRunsApiResponse =
  /** status 200 OK */ SearchWorkflowRunsResponse;
export type SearchWorkflowRunsApiArg = {
  /** The query string for searching workflow runs. Supported fields are: `id`, `workflow_id`, `project_id`, `status`, `runner_mode`, `cancelation_request` */
  query?: string;
  /** The fields to expand in the search results. Supported values are: `project`, `config.system`, `created_by` */
  expand?: string[];
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
  /** The sort order for the search results. Supported sort fields are: `created_at`, `updated_at` */
  sort?: string;
};
export type SearchWorkflowTasksApiResponse =
  /** status 200 OK */ SearchWorkflowTasksResponse;
export type SearchWorkflowTasksApiArg = {
  /** The query string for searching workflow tasks. Supported fields are: `workflow_run_id` */
  query?: string;
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
  /** The fields to expand in the search results. Supported values are: `project`, `created_by` */
  expand?: string[];
};
export type GetWorkflowTaskApiResponse = /** status 200 OK */ WorkflowTask;
export type GetWorkflowTaskApiArg = {
  /** The ID of the workflow task to retrieve. */
  workflowTaskId: string;
};
export type GetWorkflowRunApiResponse = /** status 200 OK */ WorkflowRun;
export type GetWorkflowRunApiArg = {
  /** The ID of the workflow run to retrieve. */
  workflowRunId: string;
  /** The fields to expand in the response. Supported values are: `project`, `config.system`, `created_by`, `actions` */
  expand?: string[];
};
export type CancelWorkflowRunApiResponse = /** status 200 OK */ WorkflowRun;
export type CancelWorkflowRunApiArg = {
  /** The ID of the workflow run to cancel. */
  workflowRunId: string;
};
export type RetryWorkflowRunFailuresApiResponse =
  /** status 200 OK */ WorkflowRun;
export type RetryWorkflowRunFailuresApiArg = {
  workflowRunId: string;
  retryWorkflowRunFailuresRequest: RetryWorkflowRunFailuresRequest;
};
export type SearchWorkflowsApiResponse =
  /** status 200 OK */ SearchWorkflowsResponse;
export type SearchWorkflowsApiArg = {
  /** The query string for searching workflows. Supported fields are: `id`, `project_id` */
  query?: string;
  /** The sort order for the search results. Supported sort fields are: `created_at`, `updated_at` */
  sort?: string;
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
  /** The fields to expand in the search results. Supported values are: `project`, `created_by`, `updated_by`, `latest_run` */
  expand?: string[];
};
export type ValidateWorkflowActionApiResponse =
  /** status 200 OK */ ValidateWorkflowActionResponse;
export type ValidateWorkflowActionApiArg = {
  /** The runner mode to validate the action for. If this field is not UNSET, take the runner mode (cloud or hybrid) into account. This primarily affects which connections can be used in an action; those with Gretel-managed credentials encryption can't be used in a Hybrid workflow, and vice versa. */
  runnerMode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  body: object;
};
export type ValidateWorkflowConfigApiResponse = /** status 200 OK */ string;
export type ValidateWorkflowConfigApiArg = {
  validateWorkflowConfigRequest: ValidateWorkflowConfigRequest;
};
export type GetWorkflowApiResponse = /** status 200 OK */ Workflow;
export type GetWorkflowApiArg = {
  /** The ID of the workflow to retrieve. */
  workflowId: string;
  /** The fields to expand in the response. Supported values are: `project`, `created_by`, `updated_by`, `latest_run` */
  expand?: string[];
};
export type DeleteWorkflowApiResponse = /** status 200 OK */ string;
export type DeleteWorkflowApiArg = {
  /** The ID of the workflow to delete. */
  workflowId: string;
};
export type UpdateWorkflowConfigApiResponse = /** status 200 OK */ Workflow;
export type UpdateWorkflowConfigApiArg = {
  /** The ID of the workflow to update. */
  workflowId: string;
  body: string;
};
export type UserProfileImage = {
  small?: string;
  medium?: string;
  large?: string;
};
export type UserProfile = {
  id?: string;
  firstname?: string;
  lastname?: string;
  image?: UserProfileImage;
  email: string;
};
export type CloudProviderInfoAws = {};
export type CloudProviderInfoGcp = {};
export type CloudProviderInfoAzure = {};
export type ClusterCloudProviderInfo = {
  region?: string;
  aws?: CloudProviderInfoAws;
  gcp?: CloudProviderInfoGcp;
  azure?: CloudProviderInfoAzure;
};
export type ClusterStatus = {
  health_status?:
    | 'HEALTH_STATUS_UNKNOWN'
    | 'HEALTH_STATUS_HEALTHY'
    | 'HEALTH_STATUS_DEGRADED'
    | 'HEALTH_STATUS_UNHEALTHY';
};
export type ConfigAsymmetricKeyMetadata = {
  /** A string to allow identifying the key used for decryption. This may reference a resource within a cloud provider; however, clients may treat this as a fully opaque value. */
  key_id?: string;
  /** The asymmetric decryption algorithm to use with this key. */
  algorithm?: 'UNKNOWN_ALGORITHM' | 'RSA_4096_OAEP_SHA256';
  /** PEM-encoded public key. */
  public_key_pem?: string;
};
export type ClusterConfig = {
  asymmetric_key?: ConfigAsymmetricKeyMetadata;
};
export type Cluster = {
  guid?: string;
  name?: string;
  owner_guid?: string;
  owner_profile?: UserProfile;
  cloud_provider?: ClusterCloudProviderInfo;
  cloud_provider_type?: 'UNKNOWN' | 'AWS' | 'GCP' | 'AZURE';
  status?: ClusterStatus;
  created_at?: string;
  last_checkin_time?: string;
  config?: ClusterConfig;
  chart_version?: string;
  app_version?: string;
};
export type Project = {
  id?: string;
  uid?: string;
  name?: string;
  display_name?: string;
  description?: string;
  long_description?: string;
  owner?: string;
  color?: string;
  public?: boolean;
  domain_guid?: string;
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  cluster_guid?: string;
  cluster?: Cluster;
  modified?: string;
  created?: string;
};
export type StatusDetails = {
  message?: string;
};
export type WorkflowRunCancellationRequest = {
  requested_by: string;
  requested_at: string;
};
export type ActionSummary = {
  /** The name of the action (v1) or step (v2) */
  name: string;
  /** The action_type (v1) or task (v2) */
  action_type: string;
  /** The status of the action */
  status?:
    | 'RUN_STATUS_UNKNOWN'
    | 'RUN_STATUS_CREATED'
    | 'RUN_STATUS_PENDING'
    | 'RUN_STATUS_ACTIVE'
    | 'RUN_STATUS_ERROR'
    | 'RUN_STATUS_LOST'
    | 'RUN_STATUS_COMPLETED'
    | 'RUN_STATUS_CANCELLING'
    | 'RUN_STATUS_CANCELLED'
    | 'RUN_STATUS_SKIPPED';
  /** The ID of the workflow task for this action, if it exists */
  task_id?: string;
  /** The ID of the workflow run for this action, if it exists */
  run_id?: string;
  /** (V1 workflows only) The ID of the workflow run that should be used for checkpoint data. This is usually the ID of the most recent successfully completed run (or empty if there are no previous completed runs for this workflow). However, in the case of retrying failures, this will be the ID of the run being retried for failed actions so that it can pick up partial state from the previous attempt. */
  previous_run_manifest_id?: string;
  /** (V1 workflows only) The ID of the workflow run containing the input action run manifest to use. This is usually the ID of the workflow run to which both "this" action being summarized and its input belong. However, in the case of retrying failures, this field will reference the ID of the workflow run being retried if "this" action's input was successful in that previous run and thus skipped in this one. This field is empty for actions that do not take any input. */
  input_run_manifest_id?: string;
  /** (V2 workflows only) The ID of the workflow run containing partial output from a previous attempt of "this" step being summarized. */
  step_checkpoint_source?: string;
};
export type WorkflowRun = {
  /** The unique ID of the workflow run. */
  id: string;
  /** The ID of the workflow that this run belongs to. */
  workflow_id: string;
  /** The project ID that this workflow run belongs to. This will be the same as the project ID of the workflow. */
  project_id: string;
  /** The project that this workflow run belongs to. Provided when the `expand=project` query param is present. */
  project?: Project;
  /** The GUID of the cluster where the workflow run is executed. */
  cluster_guid?: string;
  /** The config of the workflow run. */
  config?: object;
  /** The config of the workflow run as a YAML string. */
  config_text?: string;
  /** The runner mode of the workflow run. Can be `cloud` or `hybrid`. */
  runner_mode:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  /** The status of the workflow run. */
  status:
    | 'RUN_STATUS_UNKNOWN'
    | 'RUN_STATUS_CREATED'
    | 'RUN_STATUS_PENDING'
    | 'RUN_STATUS_ACTIVE'
    | 'RUN_STATUS_ERROR'
    | 'RUN_STATUS_LOST'
    | 'RUN_STATUS_COMPLETED'
    | 'RUN_STATUS_CANCELLING'
    | 'RUN_STATUS_CANCELLED'
    | 'RUN_STATUS_SKIPPED';
  /** Additional details about the status of the workflow run. */
  status_details?: StatusDetails;
  /** The user ID that created this workflow run. */
  created_by: string;
  /** A timestamp indicating when this workflow run was created. */
  created_at: string;
  /** A timestamp indicating when this workflow run was last updated. */
  updated_at?: string;
  /** A timestamp indicating when this workflow run entered the pending state. */
  pending_at?: string;
  /** A timestamp indicating when this workflow run entered the active state. */
  active_at?: string;
  /** A timestamp indicating when an error occurred in this workflow run. */
  error_at?: string;
  /** A timestamp indicating when this workflow run was lost. */
  lost_at?: string;
  /** A timestamp indicating when this workflow run was cancelled. */
  cancelled_at?: string;
  /** A timestamp indicating when the lease for this workflow run expires. */
  lease_expires_at?: string;
  /** The cancellation request for this workflow run. */
  cancellation_request?: WorkflowRunCancellationRequest;
  /** The user profile of the user that created this workflow run. Provided when the `expand=created_by` query param is present. */
  created_by_profile?: UserProfile;
  /** The total compute time in seconds for this workflow run. */
  total_compute_time_sconds?: number;
  /** An ordered summary of the actions in this workflow run. Provided when the `expand=actions` query param is present. */
  actions?: ActionSummary[];
};
export type Workflow = {
  /** The unique ID of the workflow. */
  id: string;
  /** The name of the workflow. */
  name: string;
  /** The project ID that this workflow belongs to. */
  project_id: string;
  /** The project that this workflow belongs to. Provided when the `expand=project` query param is present. */
  project?: Project;
  /** The config of the workflow. */
  config?: object;
  /** The config of the workflow as a YAML string. */
  config_text?: string;
  /** The runner mode of the workflow. Can be `cloud` or `hybrid`. */
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  /** The user ID that created this workflow. */
  created_by: string;
  /** The user profile of the user that created this workflow. Provided when the `expand=created_by` query param is present. */
  created_by_profile?: UserProfile;
  /** The user ID that last updated this workflow. */
  updated_by?: string;
  /** The user profile of the user that last updated this workflow. Provided when the `expand=updated_by` query param is present. */
  updated_by_profile?: UserProfile;
  /** A timestamp indicating when this workflow was created. */
  created_at: string;
  /** A timestamp indicating when this workflow was last updated. */
  updated_at?: string;
  /** A timestamp indicating when the next scheduled run is. */
  next_scheduled_run?: string;
  /** The latest run of this workflow. Provided when the `expand=latest_run` query param is present. */
  latest_run?: WorkflowRun;
};
export type GetWorkflowsResponse = {
  /** The list of workflows. */
  workflows?: Workflow[];
};
export type GoogleProtobufAny = {
  /** The type of the serialized message. */
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  /** The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code]. */
  code?: number;
  /** A developer-facing error message, which should be in English. Any user-facing error message should be localized and sent in the [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client. */
  message?: string;
  /** A list of messages that carry the error details.  There is a common set of message types for APIs to use. */
  details?: GoogleProtobufAny[];
};
export type CreateWorkflowRequest = {
  /** The name of the workflow. This field has been deprecated in favor of the `config.name` field. */
  name?: string;
  /** The project ID that this workflow should belong to. */
  project_id: string;
  /** The workflow config object. See production documentation for more information on the structure of this field. */
  config?: object;
  /** The workflow config as a YAML string. */
  config_text?: string;
  /** The runner mode of the workflow. Can be `cloud` or `hybrid`. Some projects may require workflows to run in a specific mode. */
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
};
export type CreateWorkflowRunRequest = {
  /** The ID of the workflow to create a run for. */
  workflow_id: string;
  /** An optional config for the workflow run If provided, this will be used in place of the workflow's config. */
  config?: object;
  /** An optional config for the workflow run as a YAML string. If provided, this will be used in place of the workflow's config. */
  config_text?: string;
};
export type SearchWorkflowRunsResponse = {
  /** The list of workflow runs that matched the search query in the requested page. */
  runs?: WorkflowRun[];
  /** The total number of workflow runs that matched the search query. */
  total: number;
};
export type WorkflowTask = {
  /** The unique ID of the workflow task. */
  id: string;
  /** The ID of the workflow run that this task belongs to. */
  workflow_run_id: string;
  /** The project ID that this workflow task belongs to. */
  project_id: string;
  /** The project that this workflow task belongs to. Provided when the `expand=project` query param is present. */
  project?: Project;
  /** The location of the log for this workflow task. */
  log_location: string;
  /** The status of the workflow task. */
  status:
    | 'RUN_STATUS_UNKNOWN'
    | 'RUN_STATUS_CREATED'
    | 'RUN_STATUS_PENDING'
    | 'RUN_STATUS_ACTIVE'
    | 'RUN_STATUS_ERROR'
    | 'RUN_STATUS_LOST'
    | 'RUN_STATUS_COMPLETED'
    | 'RUN_STATUS_CANCELLING'
    | 'RUN_STATUS_CANCELLED'
    | 'RUN_STATUS_SKIPPED';
  /** The user supplied name of the workflow action that produced this task. The name can be mapped back to the original workflow config. */
  action_name: string;
  /** The type of workflow action running the task. Eg `s3_source` or `gretel_model`. */
  action_type: string;
  /** If the task is in an error state, this field will get populated with an error message suitable for displaying in the console. These error messages are meant to span a single line, and will be human readable. */
  error_msg?: string;
  /** The code associated with an error message. These codes can be used to group like errors together. */
  error_code?: number;
  /** A more detailed stack trace that can be used for root cause analysis. This stack trace generally shouldn't be shown in the UI and will span many lines. */
  stack_trace?: string;
  /** The user ID that created this workflow. */
  created_by: string;
  /** The user profile of the user that created this workflow. Provided when the `expand=created_by` query param is present. */
  created_by_profile?: UserProfile;
  /** A timestamp indicating when this workflow was created. */
  created_at: string;
  /** A timestamp indicating when this workflow was last updated. */
  updated_at?: string;
  /** A timestamp indicating when this workflow entered the pending state. */
  pending_at?: string;
  /** A timestamp indicating when this workflow entered the active state. */
  active_at?: string;
  /** A timestamp indicating when an error occurred in this workflow. */
  error_at?: string;
  /** A timestamp indicating when this workflow was lost. */
  lost_at?: string;
  /** A timestamp indicating when this workflow was completed. */
  completed_at?: string;
  /** A timestamp indicating when this workflow was cancelled. */
  cancelled_at?: string;
  /** The total compute time in seconds for this workflow. */
  total_compute_time_sconds?: number;
};
export type SearchWorkflowTasksResponse = {
  /** The list of workflow tasks that matched the search query in the requested page. */
  tasks?: WorkflowTask[];
  /** The total number of workflow tasks that matched the search query. */
  total: number;
};
export type RetryWorkflowRunFailuresRequest = {
  /** An optional config for the retry workflow run If absent, the config of the workflow run being retried will be used. */
  config?: object;
};
export type SearchWorkflowsResponse = {
  /** The list of workflows that matched the search query in the requested page. */
  workflows?: Workflow[];
  /** The total number of workflows that matched the search query. */
  total: number;
};
export type ValidateWorkflowActionResponse = {
  /** The validation status of the action. */
  status?:
    | 'VALIDATION_STATUS_UNKNOWN'
    | 'VALIDATION_STATUS_VALIDATING'
    | 'VALIDATION_STATUS_VALID'
    | 'VALIDATION_STATUS_INVALID';
  /** The error message if the action is invalid. */
  message?: string;
};
export type ValidateWorkflowConfigRequest = {
  config: object;
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
};
export const {
  useGetWorkflowsQuery,
  useCreateWorkflowMutation,
  useCreateWorkflowRunMutation,
  useSearchWorkflowRunsQuery,
  useSearchWorkflowTasksQuery,
  useGetWorkflowTaskQuery,
  useGetWorkflowRunQuery,
  useCancelWorkflowRunMutation,
  useRetryWorkflowRunFailuresMutation,
  useSearchWorkflowsQuery,
  useValidateWorkflowActionMutation,
  useValidateWorkflowConfigMutation,
  useGetWorkflowQuery,
  useDeleteWorkflowMutation,
  useUpdateWorkflowConfigMutation,
} = injectedRtkApi;
