import { LOGIN_ROUTE } from 'src/routes';

type LoginParams = {
  error?: string;
  redirect: string;
  email?: string;
};

export const useLoginParams = (): LoginParams => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    error: urlParams.get('error') || undefined,
    redirect: urlParams.get('redirect') || window.location.origin,
    email: urlParams.get('email')?.trim(),
  };
};

export const redirectToLogin = (params?: Partial<LoginParams>) => {
  const url = new URL(LOGIN_ROUTE.path, window.location.origin);
  for (const key in params) {
    if (params[key]) {
      url.searchParams.set(key, params[key]);
    }
  }
  window.location.assign(url);
};
