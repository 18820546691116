import * as React from 'react';
import type { DefaultToastOptions } from 'react-hot-toast';
import { useToaster } from 'react-hot-toast';
import { styled } from '@mui/material';
import type { DefaultToastAlertOptions, ToastAlert } from './Toast';
import Toast from './Toast';

// Extend useToaster to add our additional default prop values
const useToasterExtended = (
  options?: DefaultToastOptions & DefaultToastAlertOptions
) => useToaster(options);

const ToastContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(2),
  marginRight: theme.spacing(2),
  zIndex: theme.zIndex.snackbar,
}));

const Notifications = () => {
  const { toasts, handlers } = useToasterExtended({
    close: false,
    duration: 6000,
    error: {
      // Overrides duration set for all types
      duration: Infinity,
      // Needs close otherwise toast will only go away with Refresh
      close: true,
    },
  });
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;
  return (
    <ToastContainer
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      data-testid="toast-container"
    >
      {toasts.map((t: ToastAlert) => {
        const offset = calculateOffset(t, {
          reverseOrder: false,
          gutter: 8,
        });

        return (
          <Toast
            key={t.id}
            toastObject={t}
            offset={offset}
            updateHeight={updateHeight}
          />
        );
      })}
    </ToastContainer>
  );
};

export default Notifications;
