import * as React from 'react';
import MODEL_STATUS from 'constants/modelStatus';
import { MODEL_COMPLETION_MODAL_POLLING_INTERVAL } from 'constants/pollingIntervals';
import { useModelQuery } from 'src/api';
import useActions from 'utils/useActions';
import modelDialogDuck from '../../modelDialogDuck';

export const Watcher = ({
  uid,
  projectGuid,
  createdAt,
}: {
  uid: string;
  projectGuid: string;
  createdAt: number;
}) => {
  const { promoteModelToDialogList, removeModelFromWatchList } = useActions(
    modelDialogDuck.actions
  );
  const [isDonePolling, setIsDonePolling] = React.useState(false);

  const { data, isError } = useModelQuery(
    { uid, guid: projectGuid },
    {
      skip: isDonePolling,
      pollingInterval: MODEL_COMPLETION_MODAL_POLLING_INTERVAL,
    }
  );

  React.useEffect(() => {
    if (!isDonePolling && isError) {
      // if api errors, stop polling it.
      // for now, we'll remove it from the watch list below, and not try again.
      setIsDonePolling(true);
    }

    if (!isDonePolling && data) {
      if (MODEL_STATUS.TERMINAL_STATUSES.includes(data.status)) {
        setIsDonePolling(true);
      }
    } else if (isDonePolling) {
      // these only need to happen once,
      // as the component will get removed due to redux update
      if (data?.status === MODEL_STATUS.CANCELLED || isError) {
        // remove it so we stop watching it, no modal will be shown
        removeModelFromWatchList(uid);
      } else {
        promoteModelToDialogList({ modelUid: uid, projectGuid, createdAt });
      }
    }
  }, [
    uid,
    projectGuid,
    isDonePolling,
    isError,
    data,
    removeModelFromWatchList,
    promoteModelToDialogList,
    createdAt,
  ]);

  // we dont want to show any ui for this logic, it's all side effects...
  return <></>;
};
