export type GetModelArtifacts = {
  guid: string;
  uid: string;
  type?: 'model' | 'report' | 'report_json' | 'data_preview';
  download?: boolean;
};

export enum RecordHandlerArtifactType {
  /** CSV/JSON data output of record handler */
  DATA = 'data',

  /** JSON Report about record handler run */
  RUN_REPORT_JSON = 'run_report_json',

  /** JSON logs of record handler */
  RUN_LOGS = 'run_logs',
}

export type GetRecordHandlerArtifacts = {
  guid: string;
  modelId: string;
  uid: string;
  type?: RecordHandlerArtifactType;
  download?: boolean;
};

export type ProjectArtifact = {
  key: string;
  artifactKey: string;
  size_bytes: number;
  last_modified: string;
};

export type ProjectArtifactUrl = {
  key: string;
  url: string;
  method: string;
};

// note: this is not a comprehensive definition, but includes fields we're likely to use
export type ProjectArtifactWithManifest = {
  artifact_id: string;
  project_id: string;
  guid: string;
  status: 'success' | 'error';
  file_size?: number;
  manifest: {
    extension?: string; // ".csv"
    reader_type?: string; // "csv"
    record_count?: number;
    field_count?: number;
    fields?: Array<{ name: string; type: string; count: number }>;
  };
  error_message?: string;
};
