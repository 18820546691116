import { TARGET_TYPE_BOTH } from 'src/Console/Connections/utils';
import { AuthStrategy, AzureFormState } from './types';

// Setting the default values for the form
export const initialAzureForm: AzureFormState = {
  name: '',
  type: 'azure',
  account_name: '',
  default_container: '',
  access_key: '',
  entra_password: '',
  sas_token: '',
  client_id: '',
  tenant_id: '',
  username: '',
  connection_target_type: TARGET_TYPE_BOTH,
  auth_strategy: AuthStrategy.ACCESS_KEY,
};
