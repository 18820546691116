import * as React from 'react';
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { AutosizeInput } from 'common/AutosizeInput';
import { StealthInputWrapper } from 'common/AutosizeInput/StealthInputWrapper';
import ProjectDescriptionField from 'common/input/ProjectDescriptionField';
import Formatters from 'common/utils/formatters';
import useSelectedProject from 'common/utils/useSelectedProject';
import { PROJECT_NAME_MAX_LENGTH } from 'constants/data';
import { USER_ACCESS_LEVEL } from 'constants/userAccessLevel';
import { useUpdateProjectMutation } from 'src/api';
import { Project } from 'src/api/types/Project';
import { useHybridEnabled } from 'src/Console/Projects/util';
import ProjectTabs from './ProjectTabs';

const DESCRIPTION_HEIGHT = 20;

const HybridChip = ({ mode }: { mode?: Project['runner_mode'] }) => {
  const hybridEnabled = useHybridEnabled();

  if (!hybridEnabled) {
    return null;
  }

  return (
    <Chip
      label={mode === 'hybrid' ? 'Hybrid' : 'Cloud'}
      sx={theme => ({ ...theme.typography.body2Strong, alignSelf: 'center' })}
    />
  );
};

const ProjectPageHeader = () => {
  const { selectedProject } = useSelectedProject();

  const [editProject, editProjectStatus] = useUpdateProjectMutation();
  const {
    display_name: initialTitle = '',
    description: initialDescription = '',
    level: userAuthLevel = 0,
  } = selectedProject || {};

  const [title, setTitle] = React.useState(initialTitle);
  const [description, setDescription] = React.useState(initialDescription);

  const handleChangeTitle = newTitle =>
    newTitle !== initialTitle &&
    editProject({
      display_name: newTitle,
      guid: selectedProject?.guid as string,
    });
  const handleChangeDescription = () => {
    if (description !== initialDescription) {
      editProject({ description, guid: selectedProject?.guid as string });
    }
  };

  const isTitleLoading =
    !selectedProject ||
    (editProjectStatus.isLoading &&
      editProjectStatus.originalArgs?.display_name);
  const isTitleError =
    editProjectStatus.isError && editProjectStatus.originalArgs?.display_name;
  const isDescriptionLoading =
    !selectedProject ||
    (editProjectStatus.isLoading &&
      editProjectStatus.originalArgs?.description);
  const isDescriptionError =
    editProjectStatus.isError && editProjectStatus.originalArgs?.description;

  React.useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle, isTitleError]);
  React.useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription, isDescriptionError]);

  return (
    <Box sx={{ backgroundColor: 'background.secondary', pt: 4, mb: 10 }}>
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Grid item container>
            {isTitleLoading ? (
              <Skeleton variant="text" height={44} width={256} />
            ) : userAuthLevel < USER_ACCESS_LEVEL.READ_WRITE ? (
              <Typography variant="h2">
                {Formatters.Project.displayName(title)}
              </Typography>
            ) : (
              <StealthInputWrapper>
                <AutosizeInput
                  maxLength={PROJECT_NAME_MAX_LENGTH}
                  value={title}
                  placeholder="Untitled Project"
                  onChange={({ target: { value: newValue } }) =>
                    setTitle(newValue)
                  }
                  onKeyDown={({ key }) => {
                    if (key === 'Enter') {
                      handleChangeTitle(title);
                    }
                  }}
                  onBlur={() => handleChangeTitle(title)}
                  sx={{
                    ml: -2,
                    pr: 1,
                    pb: 0.5,
                  }}
                  data-testid="project-name-input"
                />
              </StealthInputWrapper>
            )}
            {/**
             * Show Cloud chip for users who have hybrid enabled, we want to
             * show the chip on any non-hybrid project including on legacy
             * projects which may not have a runner_mode set
             **/}
            <HybridChip mode={selectedProject?.runner_mode} />
          </Grid>
        </Grid>
        {isDescriptionLoading ? (
          <Skeleton variant="text" height={DESCRIPTION_HEIGHT} width={666} />
        ) : userAuthLevel < USER_ACCESS_LEVEL.READ_WRITE ? (
          <Typography variant="body2">{description}</Typography>
        ) : (
          <StealthInputWrapper>
            <ProjectDescriptionField
              fullWidth
              placeholder="Click here to update project description."
              value={description}
              onChange={setDescription}
              onBlur={handleChangeDescription}
              sx={theme => ({
                my: 0,
                mx: -2,
                py: 1,
                px: 2,
                '& input': {
                  p: 0,
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: theme.typography.body1.lineHeight,
                },
              })}
            />
          </StealthInputWrapper>
        )}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <ProjectTabs />
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ my: 0 }} />
    </Box>
  );
};

export default ProjectPageHeader;
