import * as React from 'react';
import {
  Box,
  BoxProps,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import MODEL_STATUS from 'constants/modelStatus';
import { MODEL_TYPE, MODELS_BY_TYPE } from 'constants/modelType';
import { Model } from 'src/api/types/Model';
import Actions from './Actions';
import { CommonMetrics } from './CommonMetrics';
import CreatedBy from './CreatedBy';
import ReportMetrics from './ReportMetrics';

type ModelHeaderTypes = BoxProps & {
  model: Model;
};

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  border: '1px solid',
  borderColor: theme.palette.border.default,
  borderRadius: theme.shape.radii.large,
}));

const ModelHeader = ({ model, ...props }: ModelHeaderTypes) => {
  const modelAttributes = MODELS_BY_TYPE[model.model_type];
  /**
   * Checks model completion, model type, and runner mode to decide to show report related info.
   */
  const showReport = Boolean(
    model.status === MODEL_STATUS.COMPLETED &&
      modelAttributes?.hasReport &&
      ![MODEL_TYPE.TRANSFORM, MODEL_TYPE.TRANSFORM_V2].includes(
        model.model_type
      )
  );

  return (
    <StyledBox {...props}>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          mb: 4,
        }}
      >
        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            Model name
          </Typography>
          <Typography variant="h2">{model.model_name}</Typography>
        </Grid>
        <CreatedBy model={model} />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pb: 4 }}
      >
        <Grid item>
          <Actions model={model} />
        </Grid>

        {/* Quality + PPL Scores*/}
        {showReport && model.report && (
          <Grid item>
            <ReportMetrics report={model.report} />
          </Grid>
        )}
      </Grid>
      {/* Status, Model Type, Training Time, Data Source info */}
      <Divider sx={{ mx: -4 }} />
      <CommonMetrics model={model} sx={{ mt: 2 }} />
    </StyledBox>
  );
};

export default ModelHeader;
