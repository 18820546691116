import { TARGET_TYPE_BOTH } from 'src/Console/Connections/utils';
import { GCSFormState } from './types';

// Setting the default values for the form
export const initialGCSForm: GCSFormState = {
  name: '',
  type: 'gcs',
  credentials: {
    private_key_json: '',
  },
  connection_target_type: TARGET_TYPE_BOTH,
};
