import React from 'react';
import { logger } from 'utils/logger';
import { clearToken } from 'utils/token';
import ErrorLayout from './Layout';

const ERROR_THRESHOLD = 5;
const ERROR_TIMEOUT_MILLISECONDS = 2000;

type ErrorProps = React.PropsWithChildren;
type ErrorState = { errorCount: number; hasError: boolean };

/**
 * Capture global errors for reporting.
 * Most errors captured this way are non-critical
 * so they are ignored 5 times before blocking the UI.
 * They should dissipate after a few seconds as well,
 * so as to only capture recurrent critical errors */
export default class GlobalErrorBoundary extends React.Component<
  ErrorProps,
  ErrorState
> {
  state = {
    errorCount: 0,
    hasError: false,
  };

  subtract = () => {
    this.setState(state => {
      const errorCount = state.errorCount - 1;
      const hasError = state.hasError || state.errorCount > ERROR_THRESHOLD;
      return { errorCount, hasError };
    });
  };

  /** Count global errors to prevent an infinitely looping UI crash */
  countGlobalError = () => {
    this.setState(state => {
      // subtract from the count after a short timeout to prevent long-lived
      // sessions from accumulating enough errors to present the ErrorLayout
      setTimeout(() => this.subtract(), ERROR_TIMEOUT_MILLISECONDS);

      const errorCount = state.errorCount + 1;
      const hasError = state.hasError || errorCount > ERROR_THRESHOLD;
      return { errorCount, hasError };
    });
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logger.error(error, { scope: 'global', ...errorInfo });
    this.setState(state => ({ ...state, hasError: true }));
  }

  componentDidMount() {
    window.addEventListener('error', this.countGlobalError);
    window.addEventListener('unhandledrejection', this.countGlobalError);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.countGlobalError);
    window.removeEventListener('unhandledrejection', this.countGlobalError);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const reload = () => window.location.reload();
    const clearUser = () => {
      clearToken();
      reload();
    };

    return (
      <>
        {hasError ? (
          <ErrorLayout handleRefresh={reload} handleLogout={clearUser} />
        ) : (
          children
        )}
      </>
    );
  }
}
