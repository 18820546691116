import * as React from 'react';
import { styled, Tooltip, TooltipProps, Typography } from '@mui/material';
import { Help } from 'icons/figma';

type HelpIconTooltipProps = Partial<TooltipProps> & {
  title: NonNullable<React.ReactNode>;
  IconProps?: React.SVGProps<SVGElement>;
};

const StyledHelpIcon = styled(Help)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const HelpIconTooltip = ({
  title,
  IconProps = {},
  ...props
}: HelpIconTooltipProps) => {
  return (
    <Tooltip
      variant="light"
      aria-label={typeof title === 'string' ? title : undefined}
      title={
        <Typography
          variant="body2"
          color={theme => theme.palette.text.secondary}
        >
          {title}
        </Typography>
      }
      {...props}
      componentsProps={{ tooltip: { sx: { p: 4 } } }}
    >
      <StyledHelpIcon height={16} width={16} {...IconProps} />
    </Tooltip>
  );
};

HelpIconTooltip.displayName = 'HelpIconTooltip';

export default HelpIconTooltip;
