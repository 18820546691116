import * as React from 'react';
import { Box } from '@mui/material';
import Loader, { LoaderProps } from '.';

type LoaderFullProps = LoaderProps;

const LoaderFull = React.forwardRef<HTMLDivElement, LoaderFullProps>(
  ({ className, ...loaderProps }, ref) => {
    return (
      <Box
        sx={{
          p: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
        className={className}
        ref={ref}
      >
        <Loader {...loaderProps} />
      </Box>
    );
  }
);

LoaderFull.displayName = 'LoaderFull';

export default LoaderFull;
