import * as React from 'react';
import { Tabs } from '@mui/material';
import NavTab from 'common/NavTab';
import {
  isRoute,
  Route,
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT,
} from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';

const SELECTED_PROJECT_TABS =
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT.filter<Route>(isRoute).filter(
    route => route.isTab
  );

const ProjectTabs = () => {
  const { consoleRoute, compilePath, location } = useConsoleRoute({
    withRouterContext: true,
  });
  const { childComponent, ...serializableConsoleRoute } = consoleRoute;

  const currentTabIndex = SELECTED_PROJECT_TABS.findIndex(tabRoute =>
    window.location.pathname.toString().includes(compilePath(tabRoute))
  );

  return (
    <Tabs value={currentTabIndex >= 0 ? currentTabIndex : false}>
      {SELECTED_PROJECT_TABS.map((tabRoute, index) => (
        <NavTab
          key={`selected-project-navigation-tab-${index}`}
          label={tabRoute.title}
          to={{
            ...location,
            pathname: compilePath(tabRoute),
            state: {
              from: {
                consoleRoute: serializableConsoleRoute,
                ...location,
              },
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default ProjectTabs;
