export const copyToClipboard = (text: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      reject(new Error('Clipboard API not available'));
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        resolve();
      },
      err => {
        reject(err);
      }
    );
  });
};
