import { datadogRum } from '@datadog/browser-rum';

/**
 * A generic error logging method to a) print to the console, and b) forward to DataDog
 
 * @param err An Error object
 * @param customAttributes Name/value pairs to append to the NR error instance
 */
const error: typeof datadogRum.addError = (err, context) => {
  if (process.env.DATADOG_APP_ID) {
    // Datadog likes errors to be Errors, coerce when necessary. -md
    if (err instanceof Error) {
      datadogRum.addError(err, context);
    } else if (typeof err === 'string') {
      datadogRum.addError(new Error(err), context);
    } else {
      datadogRum.addError(new Error('Unexpected error'), {
        raw: err,
        ...context,
      });
    }
  } else {
    console.error(err);
  }
};

export const logger = { error };
