import { Components, Theme } from '@mui/material';

const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button2,
      color: theme.palette.text.secondary,
      border: '1px solid',
      borderColor: theme.palette.border.default,
      margin: 0,
      // Hover styles
      ':hover': {
        backgroundColor: theme.palette.grey[100],
      },
      // Selected styles
      ':active, &.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary[50],
        borderColor: theme.palette.primary[300],
        // Selected + hover styles
        ':hover': {
          backgroundColor: theme.palette.primary[100],
        },
        // Extra specificity to override mui
        ':not(:first-of-type)': {
          borderColor: theme.palette.primary[300],
        },
      },
      // Sibling after select, cleanup left margin + border
      '&.Mui-selected + .MuiToggleButton-root,&:active + .MuiToggleButton-root':
        {
          marginLeft: 0,
          borderLeft: 0,
        },
      '& > *': {
        // prevents child elements from registering clicks rather than the button itself
        // to help with Google Analytics event delegation
        pointerEvents: 'none',
      },
    }),
  },
};

export default MuiToggleButton;
