import { Components, Theme } from '@mui/material';

declare module '@mui/material/List' {
  interface ListOwnProps {
    variant?: 'bulleted';
  }
}

const MuiList: Components<Theme>['MuiList'] = {
  variants: [
    {
      props: { variant: 'bulleted' },
      style: {
        listStyleType: 'disc',
        '& .MuiListItem-root': {
          display: 'list-item',
        },
      },
    },
  ],
};

export default MuiList;
