import * as React from 'react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { DOCUMENTATION_HUB } from 'common/constants/urls';
import { DestructiveConfirmDialog } from 'design-system';
import { Tip } from 'icons/figma';

type CancelModelDialogProps = {
  onCancelModel: () => void;
  onClose?: () => void;
  open: boolean;
};

export const CancelModelDialog = ({
  onCancelModel,
  onClose = () => undefined,
  open,
}: CancelModelDialogProps) => {
  return (
    <DestructiveConfirmDialog
      isOpen={open}
      subtitle="If you quit now all progress will be lost."
      onConfirm={onCancelModel}
      onNevermind={onClose}
      confirmBtnText="Stop training"
    >
      <Box display="flex">
        <SvgIcon
          sx={{
            height: 24,
            width: 24,
            mr: 2,
          }}
        >
          <Tip />
        </SvgIcon>
        <Box>
          <Typography display="block">
            Not getting the results you were hoping for? Check out our tips for
            improving synthetic data quality.
          </Typography>
          <Button
            color="secondary"
            size="small"
            href={DOCUMENTATION_HUB}
            target="_blank"
          >
            Read the docs
          </Button>
        </Box>
      </Box>
    </DestructiveConfirmDialog>
  );
};
