import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { NavigatorPrompt, Type } from 'src/api/pilot/navigatorPrompts';
import { ModelMode } from 'src/api/types/Model';
import { ProjectType } from 'src/api/types/Project';
import { Store } from 'src/store.types';
import {
  ExampleTableImport,
  Field,
  NavigatorUploadMetadata,
  PromptMode,
  TableData,
} from './util/types';
import { fieldsMap } from './constants';

export const getFieldsByModelMode = (
  modelMode: ModelMode
): readonly Field[] => {
  const copy = (field: Field): Field => ({ ...field });

  if (modelMode === ModelMode.NAVIGATOR) {
    return fieldsMap[ModelMode.NAVIGATOR].map(copy);
  } else {
    return fieldsMap[ModelMode.NATURAL].map(copy);
  }
};

export const getDefaultHyperParametersByModelMode = (
  modelMode: ModelMode
): Record<string, number> => {
  const fields = getFieldsByModelMode(modelMode);
  const params: Record<string, number> = {};

  for (const { field, value } of fields) {
    params[field] = value;
  }
  return params;
};

export const getDefaultNumberOfRecordsByModelMode = (
  modelMode: ModelMode
): number => {
  return modelMode === ModelMode.NAVIGATOR ? 100 : 50;
};

const selectPlaygroundState = (state: Store) => state.playground;

export const selectPrompt = createSelector(selectPlaygroundState, configState =>
  getNaturalOrTabularPrompt(configState)
);

const getNaturalOrTabularPrompt = (state: PlaygroundState) => {
  return state.modelMode === ModelMode.NATURAL
    ? state.naturalPrompt
    : state.tabularPrompt;
};

export type ExampleTable = {
  headers: { columnId: string; text: string }[];
  rows: Record<string, string>[];
  errors: Record<string, string> /* Record< columnId, errorMessage > */;
};

export type PlaygroundState = {
  modelMode: ModelMode;
  params: Record<string, number>;
  prompt: string;
  naturalPrompt: string;
  tabularPrompt: string;
  exampleTable: ExampleTable | null;
  promptMode: PromptMode;
  exampleTableImport: ExampleTableImport;
  project: {
    guid: string;
    display_name?: string;
    runner_mode?: ProjectType;
  };
  aiSource: string;
  numberOfRecords: number;
  editableDataset: TableData | null;
  importMetadata: null | NavigatorUploadMetadata;
  promptName: string;
  activeTabularSavedPrompt: NavigatorPrompt;
  activeGptSavedPrompt: NavigatorPrompt;
};

const DEFAULT_MODEL_MODE = ModelMode.NAVIGATOR;
export const NAVIGATOR_SUGGESTED_TABLE_PROMPT =
  'Generate more data like the following table';

export const SAVED_PROMPTS_PLACEHOLDER = '--Saved prompts--';

export const initialState: PlaygroundState = {
  modelMode: DEFAULT_MODEL_MODE,
  params: getDefaultHyperParametersByModelMode(DEFAULT_MODEL_MODE),
  prompt: '',
  naturalPrompt: '',
  tabularPrompt: '',
  exampleTable: null,
  promptMode: PromptMode.TEXT,
  exampleTableImport: {
    error: '',
    file: null,
  },
  project: { guid: '', display_name: undefined },
  aiSource: '',
  numberOfRecords: getDefaultNumberOfRecordsByModelMode(DEFAULT_MODEL_MODE),
  editableDataset: null,
  importMetadata: null,
  promptName: '',
  activeTabularSavedPrompt: {
    id: SAVED_PROMPTS_PLACEHOLDER,
    name: SAVED_PROMPTS_PLACEHOLDER,
    body: '',
    created_by: '',
    created_at: '',
    updated_at: '',
    type: Type.PromptTypeTabular,
  },
  activeGptSavedPrompt: {
    id: SAVED_PROMPTS_PLACEHOLDER,
    name: SAVED_PROMPTS_PLACEHOLDER,
    body: '',
    created_by: '',
    created_at: '',
    updated_at: '',
    type: Type.PromptTypeGpt,
  },
};

type CheckValidationAsyncThunkApi = {
  state: Store;
  rejectValue: ExampleTable['errors'];
};
export const playgroundActions = {
  checkValidation: createAsyncThunk<true, void, CheckValidationAsyncThunkApi>(
    'playground/prompt/checkValidation',
    async (_, api) => {
      const exampleTable = (api.getState() as Store).playground.exampleTable;
      if (!exampleTable) {
        return true;
      }

      const { headers, rows } = exampleTable;
      // check there are some headers w/ values
      const hasDataInHeaders = headers.some(({ text }) => !!text);
      // check that some rows have some data
      const hasDataInCells = rows.some(row =>
        Object.entries(row).some(([col, cell]) =>
          // id columns are automatically populated & don't count as user entry
          col === 'id' ? false : cell !== ''
        )
      );
      const hasSomeContentFilledIn = hasDataInHeaders || hasDataInCells;

      if (!hasSomeContentFilledIn) {
        return api.rejectWithValue({ table: 'Empty table' });
      }

      // scan headers for problems w/ JSON serialization before sending to api
      const errors: Record<string, string> = {};
      const emptyHeaders: string[] = [];
      const overlapping: string[] = [];
      const memory: Record<string, string[]> = {};
      for (const { columnId, text } of headers) {
        if (text === '') {
          emptyHeaders.push(columnId);
        }
        if (memory[text]) {
          memory[text].push(columnId);
          overlapping.push(...memory[text]);
        }
        memory[text] = [columnId];
      }

      if (emptyHeaders.length > 0 || overlapping.length > 0) {
        emptyHeaders.reduce((errors, columnId) => {
          errors[columnId] = 'Empty header';
          return errors;
        }, errors);

        overlapping.reduce((errors, columnId) => {
          errors[columnId] = 'Duplicate header';
          return errors;
        }, errors);

        return api.rejectWithValue(errors);
      }

      return true;
    }
  ),
};

/** validator for `rejectWithValue(errors)` inside `playgroundActions.checkValidation` is an map of Errors for PromptTable Headers */
const isPromptTableHeaderError = (
  value: unknown
): value is ExampleTable['errors'] => {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.values(value).every(val => typeof val === 'string')
  );
};

const playgroundDuck = createSlice({
  name: 'playground',
  initialState,
  reducers: {
    setAiSource: (state, action: PayloadAction<string>) => {
      state.aiSource = action.payload;
    },
    setModelMode: (state, action: PayloadAction<ModelMode>) => {
      if (state.modelMode !== action.payload) {
        state.exampleTable = initialState.exampleTable;
      }
      state.modelMode = action.payload;
      // doing this makes NL mode not render... should i refactor "prompt mode" out?
      // state.promptMode = PromptMode.TEXT;
      state.params = getDefaultHyperParametersByModelMode(action.payload);
      state.numberOfRecords = getDefaultNumberOfRecordsByModelMode(
        action.payload
      );
    },
    setParameterField: (
      state,
      action: PayloadAction<{ field: string; value: number }>
    ) => {
      const { field, value } = action.payload;
      // do we need this to do error handling? is there _REALLY_ a possibility of adding funky fields here?
      if (field in state.params) {
        state.params[field] = value;
      }
    },
    setPromptValue: (state, action: PayloadAction<string>) => {
      state.modelMode === ModelMode.NATURAL
        ? (state.naturalPrompt = action.payload)
        : (state.tabularPrompt = action.payload);
    },
    setNaturalPromptValue: (state, action: PayloadAction<string>) => {
      state.naturalPrompt = action.payload;
    },
    setTabularPromptValue: (state, action: PayloadAction<string>) => {
      state.tabularPrompt = action.payload;
    },
    dropExampleTable: state => {
      state.exampleTable = null;
      state.exampleTableImport.error = '';

      if (state.tabularPrompt === NAVIGATOR_SUGGESTED_TABLE_PROMPT) {
        state.tabularPrompt = '';
      }
    },
    initializeExampleTable: state => {
      if (state.tabularPrompt === '') {
        state.tabularPrompt = NAVIGATOR_SUGGESTED_TABLE_PROMPT;
      }

      state.exampleTable = {
        headers: [{ columnId: '0', text: '' }],
        rows: [{ id: 'a', '0': '' }],
        errors: {},
      };
      state.exampleTableImport = initialState.exampleTableImport;
    },
    setExampleTable: (
      state,
      action: PayloadAction<{
        headers: string[];
        rows: Record<string, string>[];
      }>
    ) => {
      const headers: ExampleTable['headers'] = action.payload.headers.map(
        (text, i) => ({ columnId: String(i), text })
      );
      const rows: ExampleTable['rows'] = action.payload.rows
        .slice(0, 3)
        .map((row, i) => {
          // we're maxed out at 3 rows, so it's safe to use this small cheat for row ids
          const newRow: Record<string, string> = { id: 'abc'[i] };
          for (const header of headers) {
            newRow[header.columnId] = row[header.text];
          }
          return newRow;
        });

      state.exampleTable = { headers, rows, errors: {} };
    },
    addRowToExampleTable: state => {
      if (!state.exampleTable) {
        return;
      }
      if (state.exampleTable.rows.length > 2) {
        return;
      }
      const { rows } = state.exampleTable;
      // we're maxed out at 3 rows, so it's safe to use this small cheat for row ids
      const newRow: Record<string, string> = { id: 'abc'[rows.length] };
      rows.push(newRow);
    },
    addColumnToExampleTable: state => {
      if (!state.exampleTable) {
        return;
      }

      const headers = state.exampleTable.headers;
      for (let i = 0; i < 100; i++) {
        const existingHeader = headers.find(
          header => header.columnId === String(i)
        );
        if (!existingHeader) {
          headers.push({ text: '', columnId: String(i) });
          break;
        }
      }
    },
    editExampleTableHeader: (
      state,
      action: PayloadAction<{ columnId: string; update: string }>
    ) => {
      if (!state.exampleTable) {
        return;
      }
      const toUpdate = state.exampleTable.headers.find(
        header => header.columnId === action.payload.columnId
      );
      if (!toUpdate) {
        return;
      }
      const oldText = toUpdate.text;
      toUpdate.text = action.payload.update;

      if (state.exampleTable.errors[action.payload.columnId]) {
        const errors = state.exampleTable.errors;
        if (errors[action.payload.columnId] === 'Empty header') {
          delete errors[action.payload.columnId];
        } else {
          const duplicateHeaders = state.exampleTable.headers
            .filter(header => header.text === oldText)
            .map(header => header.columnId);

          if (duplicateHeaders.length === 2) {
            for (const columnId of duplicateHeaders) {
              delete errors[columnId];
            }
          } else {
            delete errors[action.payload.columnId];
          }
        }
      }
      state.exampleTableImport.error = '';
    },
    changeExampleTableHeaderOrder: (
      state,
      {
        payload: { oldIndex, targetIndex },
      }: PayloadAction<{
        oldIndex: number;
        targetIndex: number;
      }>
    ) => {
      if (!state.exampleTable) {
        return;
      }
      const headers = state.exampleTable.headers;
      if (oldIndex >= headers.length || targetIndex >= headers.length) {
        return;
      }

      const element = headers[oldIndex];
      headers.splice(oldIndex, 1);
      headers.splice(targetIndex, 0, element);

      state.exampleTableImport.error = '';
    },
    editExampleTableRow: (
      state,
      action: PayloadAction<{ id: string } & Record<string, string>>
    ) => {
      if (!state.exampleTable) {
        return;
      }
      const rowId = action.payload.id;
      const rowIndex = state.exampleTable.rows.findIndex(r => r.id === rowId);
      if (rowIndex < 0) {
        return;
      }
      state.exampleTable.rows[rowIndex] = action.payload;
      state.exampleTableImport.error = '';
      state.exampleTable.errors = {};
    },
    setPromptMode: (state, action: PayloadAction<PromptMode>) => {
      state.promptMode = action.payload;
    },
    setExampleTableImport: (
      state,
      action: PayloadAction<Partial<ExampleTableImport>>
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state.exampleTableImport[key] = value;
      });
    },
    setProject: (
      state,
      action: PayloadAction<{
        guid: string;
        display_name?: string;
        runner_mode?: ProjectType;
      }>
    ) => {
      state.project = action.payload;
    },
    setPromptName: (state, action: PayloadAction<string>) => {
      state.promptName = action.payload;
    },
    setActiveGptSavedPrompt: (
      state,
      action: PayloadAction<NavigatorPrompt>
    ) => {
      state.activeGptSavedPrompt = action.payload;
    },
    setActiveTabularSavedPrompt: (
      state,
      action: PayloadAction<NavigatorPrompt>
    ) => {
      state.activeTabularSavedPrompt = action.payload;
    },
    setNumberOfRecords: (state, action: PayloadAction<number>) => {
      state.numberOfRecords = action.payload;
    },
    setImportMetadata: (
      state,
      action: PayloadAction<NavigatorUploadMetadata | null>
    ) => {
      if (!action.payload) {
        state.importMetadata = null;
      } else {
        state.importMetadata = {
          ...state.importMetadata,
          ...action.payload,
        };
      }
    },
    setImport: (state, action: PayloadAction<TableData | null>) => {
      state.editableDataset = action.payload;
    },
    updateImport: (state, action: PayloadAction<Partial<TableData>>) => {
      if (!state.editableDataset) {
        return;
      }

      const editableDataset = {
        rows: state.editableDataset.rows ?? [],
        columns: state.editableDataset.columns ?? [],
      };

      if (action.payload.rows) {
        editableDataset.rows.push(...action.payload.rows);
      }

      if (action.payload.columns) {
        editableDataset.columns = action.payload.columns;
      }

      state.editableDataset = editableDataset;
    },
    resetImport: state => {
      if (state.editableDataset) {
        state.editableDataset.rows = [];
        state.editableDataset.columns = [];
      }
    },
    resetPlayground: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(playgroundActions.checkValidation.pending, state => {
      if (!state.exampleTable) {
        return;
      }
      state.exampleTable.errors = {};
    });
    builder.addCase(
      playgroundActions.checkValidation.rejected,
      (state, action) => {
        if (!state.exampleTable) {
          return;
        }
        if (isPromptTableHeaderError(action.payload)) {
          state.exampleTable.errors = action.payload;
        }
      }
    );
  },
});

export const {
  setAiSource,
  setNumberOfRecords,
  setPromptValue,
  setTabularPromptValue,
  setNaturalPromptValue,
  setExampleTable,
  initializeExampleTable,
  dropExampleTable,
  setPromptMode,
  setProject,
  resetPlayground,
  setExampleTableImport,
  setImport,
  resetImport,
  addColumnToExampleTable,
  addRowToExampleTable,
  editExampleTableHeader,
  editExampleTableRow,
} = playgroundDuck.actions;

export default playgroundDuck;
