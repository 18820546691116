import * as React from 'react';
import { Grid, SvgIcon, Typography } from '@mui/material';
import { Info } from 'icons/figma';
import { FeelingStuck } from './FeelingStuck';

type ColorOptions =
  | 'error'
  | 'success'
  | 'warning'
  | 'primary'
  | 'grey'
  | 'info';

export type MessageBoxProps = {
  title?: string;
  subtitle?: React.ReactNode;
  color?: ColorOptions;
  icon?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  feelingStuck?: boolean;
};

export const MessageBox = React.forwardRef<HTMLDivElement, MessageBoxProps>(
  (
    {
      title = 'Looks like an error happened!',
      subtitle = 'Something went wrong, sorry about that',
      color = 'error',
      icon: Icon = <Info height={16} width={16} />,
      content: Content,
      actions: Actions,
      feelingStuck = false,
    },
    ref
  ) => {
    return (
      <Grid
        container
        sx={{
          px: 4,
          py: 6,
          border: 1,
          borderColor: 'border.default',
          borderRadius: theme => theme.shape.radii.base,
        }}
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
        ref={ref}
      >
        <SvgIcon
          sx={{
            mt: 0.5,
            color: theme => theme.palette[color][600],
            fontSize: '1.5rem',
          }}
        >
          {Icon}
        </SvgIcon>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          wrap="nowrap"
        >
          <Typography
            sx={{ color: theme => theme.palette[color][600] }}
            variant="h4"
          >
            {title}
          </Typography>
          <Typography sx={{ mt: 1 }}>{subtitle}</Typography>
          {(Content || feelingStuck) && (
            <Grid
              item
              container
              direction="column"
              sx={{
                backgroundColor: 'grey.100',
                borderRadius: theme => theme.shape.radii.base,
                boxShadow: 1,
                p: 4,
                mt: 4,
              }}
            >
              {Content && Content}
              {feelingStuck && <FeelingStuck />}
            </Grid>
          )}
          {Actions && Actions}
        </Grid>
      </Grid>
    );
  }
);
MessageBox.displayName = 'MessageBox';
