import * as React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';
import MetricLabel from 'common/Metrics/Label';
import { Score, Show } from 'icons/figma';
import { Report } from 'src/api/types/Report';

type ReportMetricsTypes = GridProps & {
  report: Report;
};

const ReportMetrics = ({ report, ...props }: ReportMetricsTypes) => {
  const qualityScore =
    report.synthetic_text_data_quality_score?.score ||
    report.synthetic_data_quality_score?.score;

  const pplScore = report.privacy_protection_level?.grade;
  // find item in summary array with field 'data_privacy_score'
  const dataPrivacyScore = report.summary?.find(
    item => item.field === 'data_privacy_score'
  );

  return (
    <Grid container {...props}>
      {qualityScore && (
        <Grid item sx={{ mr: pplScore || dataPrivacyScore ? 12 : 0 }}>
          <MetricLabel
            label="Quality Score"
            icon={<Score height={16} width={16} />}
          >
            <Typography variant="body1Medium">
              {`${qualityScore} / 100`}
            </Typography>
          </MetricLabel>
        </Grid>
      )}
      {dataPrivacyScore?.value ? (
        <Grid item>
          <MetricLabel
            label="Data Privacy Score"
            icon={<Show height={16} width={16} />}
          >
            <Typography variant="body1Medium">
              {`${Math.floor(Number(dataPrivacyScore.value)).toString()} / 100`}
            </Typography>
          </MetricLabel>
        </Grid>
      ) : (
        pplScore && (
          <Grid item>
            <MetricLabel
              label="Privacy Protection Level"
              icon={<Show height={16} width={16} />}
            >
              <Typography variant="body1Medium">{pplScore}</Typography>
            </MetricLabel>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default ReportMetrics;
