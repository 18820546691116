import * as React from 'react';
import { toast } from 'react-hot-toast';
import {
  Button,
  ButtonProps,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import MODEL_STATUS from 'constants/modelStatus';
import { USER_ACCESS_LEVEL } from 'constants/userAccessLevel';
import { DestructiveConfirmDialog } from 'design-system';
import { ChevronDown, More } from 'icons/figma';
import { useDeleteModelMutation, useUpdateModelMutation } from 'src/api';
import { Model } from 'src/api/types/Model';
import { Project } from 'src/api/types/Project';
import { copyToClipboard } from 'src/common/utils/copyToClipboard';
import { SELECTED_PROJECT_MODELS } from 'src/routes';
import Formatters from 'utils/formatters';
import useConsoleRoute from 'utils/useConsoleRoute';
import useSelectedProject from 'utils/useSelectedProject';
import { CancelModelDialog } from '../../../CancelModelDialog';

type MoreOptionsProps = Omit<ButtonProps, 'variant'> & {
  model: Model;
  selectedProject: Project;
  variant: 'button' | 'icon';
};

const FetchMoreOptions = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'variant'> & {
    model: Model;
    variant: 'button' | 'icon';
  }
>(({ model, variant, ...props }, ref) => {
  const { selectedProject } = useSelectedProject();

  if (!selectedProject) {
    return null;
  } else {
    return (
      <MoreOptions
        model={model}
        selectedProject={selectedProject}
        variant={variant}
        ref={ref}
        {...props}
      />
    );
  }
});

FetchMoreOptions.displayName = 'FetchMoreOptions';

const MoreOptions = React.forwardRef<HTMLButtonElement, MoreOptionsProps>(
  ({ model, selectedProject, variant, ...props }, ref) => {
    const { go } = useConsoleRoute({ withRouterContext: true });
    const [updateModel] = useUpdateModelMutation();
    const [deleteModel] = useDeleteModelMutation();

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [showCancelDialog, setShowCancelDialog] = React.useState(false);

    const popupState = usePopupState({
      variant: 'popover',
      popupId: 'modelMoreOptionsMenu',
    });

    const handleDeleteModel = React.useCallback(() => {
      setShowDeleteDialog(false);
      deleteModel({ guid: model.project_guid, uid: model.uid });
      go(SELECTED_PROJECT_MODELS);
    }, [model.uid, model.project_guid, go, deleteModel, setShowDeleteDialog]);

    const handleCancelModel = React.useCallback(() => {
      setShowCancelDialog(false);
      updateModel({
        status: MODEL_STATUS.CANCELLED,
        guid: model.project_guid,
        uid: model.uid,
      });
      go(SELECTED_PROJECT_MODELS);
    }, [model.uid, model.project_guid, go, updateModel, setShowCancelDialog]);

    const handleCopyGUID = React.useCallback(() => {
      copyToClipboard(model.guid)
        .then(() => {
          toast.success('ID copied to your clipboard');
        })
        .catch(() => {
          toast.error('Unable to copy the ID to your clipboard');
        });
    }, [model.guid]);

    return (
      <>
        {variant === 'icon' ? (
          <IconButton
            color="secondary"
            data-testid="model-action-menu"
            ref={ref}
            {...props}
            {...bindTrigger(popupState)}
          >
            <More width={16} height={16} />
          </IconButton>
        ) : (
          <Button
            data-testid="model-action-menu"
            ref={ref}
            color="secondary"
            endIcon={<ChevronDown width={16} height={16} />}
            {...props}
            {...bindTrigger(popupState)}
          >
            Model options
          </Button>
        )}
        <Menu {...bindMenu(popupState)}>
          <MenuItem onClick={handleCopyGUID}>
            <ListItemText primary="Copy model ID" />
          </MenuItem>
          {selectedProject.membership.level >= USER_ACCESS_LEVEL.READ_WRITE ? (
            <MenuItem
              onClick={
                MODEL_STATUS.RUN_STATUSES.includes(model?.status)
                  ? () => setShowCancelDialog(true)
                  : () => setShowDeleteDialog(true)
              }
              data-testid="model-destructive-action"
              color="error"
            >
              <ListItemText
                primary={
                  MODEL_STATUS.RUN_STATUSES.includes(model?.status)
                    ? 'Cancel'
                    : 'Delete'
                }
              />
            </MenuItem>
          ) : null}
        </Menu>
        <DestructiveConfirmDialog
          isOpen={showDeleteDialog}
          title={`Delete ${Formatters.Project.modelName(model)}`}
          subtitle="Are you sure you want to delete this model?"
          onConfirm={handleDeleteModel}
          onNevermind={() => setShowDeleteDialog(false)}
        />
        <CancelModelDialog
          open={showCancelDialog}
          onCancelModel={handleCancelModel}
          onClose={() => setShowCancelDialog(false)}
        />
      </>
    );
  }
);

MoreOptions.displayName = 'MoreOptions';

export default FetchMoreOptions;
