import { DateFormatter } from './date';
import { StringFormatter } from './string';

export const WorkflowFormatter = {
  /**
   * Takes a raw cron string such as '0 0 8 ? * * *'
   * and turns it into a human-readable format.
   */
  schedulingCron: (cronString: string) => {
    try {
      const parsedCron = DateFormatter.cron(cronString);
      return `Your workflow will run ${StringFormatter.lowercase(
        parsedCron
      )}${parsedCron.slice(1)}`;
    } catch (e) {
      return 'Unable to parse schedule, please check for errors.';
    }
  },
};
