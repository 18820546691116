import * as React from 'react';

type SidebarContextState = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const INITIAL_STATE: SidebarContextState = {
  isOpen: true,
  setIsOpen: () => true,
};

export const SidebarStatusContext =
  React.createContext<SidebarContextState>(INITIAL_STATE);
