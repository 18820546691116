import { LicenseInfo } from '@mui/x-license-pro';

/**
 * TODO(INT-1656): audit & cleanup the scripts here.
 * */

declare global {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface Window {
    zESettings: any;
    Prism: any;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

/**
 * We've abstracted the process of <script async src="...">
 * because we're doing it a few times and could forsee
 * doing it more in this file in particular */
const createScriptElement = (src: string): HTMLScriptElement => {
  const head = document.head;
  const script = document.createElement('script');
  script.async = true;
  script.src = src;

  head.appendChild(script);

  return script;
};

(function () {
  /* Google Tag Manager */
  /** @see https://developers.google.com/tag-platform/gtagjs/install */
  createScriptElement(
    `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_CONTAINER_ID}`
  );
  // NOTE: moved this <script> content to index.html
  // it is meant to be an instantly available system
  // therefore it doesn't make sense to put it in the app globals, which take a bit to load
  // window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({
  //   'gtm.start': new Date().getTime(),
  //   event: 'gtm.js',
  // });
  /* End Google Tag Manager */

  /* Prism */
  window.Prism = window.Prism || { languages: {} };
  window.Prism.languages.curl = {
    curl: /\bcurl\b/,
    url: /https?:[a-zA-Z0-9:.?=/\-_{}]*/,
    parameter: {
      pattern: /[A-Za-z0-9[\]-_]+ *(?=[=])/,
    },
    value: [
      {
        pattern: /([=])([A-Za-z0-9-_.]*)/,
        lookbehind: true,
      },
      {
        pattern: /(["'])(\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
      },
      {
        pattern: /(-u )([A-Za-z0-9-_.{}]*)/,
        lookbehind: true,
      },
    ],
    option: / *-[a-zA-Z]*\b/,
  };
  window.zESettings = {
    webWidget: {
      position: {
        horizontal: 'right',
      },
      color: {
        launcher: '#E5E5E6', // Zendesk only plays nice with hex
      },
    },
  };

  /* MUI X Pro */
  LicenseInfo.setLicenseKey(process.env.MUI_X_PRO_LICENSE_KEY as string);
})();
