import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Dial from 'common/charts/Dial';
import { Score } from 'src/api/types/Report';
import fireworks from 'src/resources/Fireworks.svg?asset';
import { SuccessLevel } from './types';

export type Props = React.PropsWithChildren<{
  isOpen: boolean;
  successLevel: SuccessLevel;
  score: Score;
  onClose: () => void;
}>;

export const Layout = ({
  isOpen,
  onClose,
  successLevel,
  score,
  children,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          minWidth: '572px',
          borderRadius: theme.shape.radii.large,
        }),
      }}
    >
      <DialogContent dividers>
        <Box sx={{ position: 'relative' }}>
          {successLevel === SuccessLevel.Success && <object data={fireworks} />}
          <Box
            sx={{
              position:
                successLevel === SuccessLevel.Success ? 'absolute' : 'relative',
              width: '143px',
              height: '143px',
              mt: 6,
              top: 0,
              left: 0,
              right: 0,
              mr: 'auto',
              ml: 'auto',
            }}
          >
            <Dial
              value={score.score}
              label={`${score.score} - ${score.grade}`}
              chipSize="small"
            />
          </Box>
        </Box>
        <DialogTitle variant="h2" sx={{ textAlign: 'center' }}>
          Model training complete
        </DialogTitle>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', pb: 3 }}>
        <Button
          id={`model-complete-${successLevel}-close`}
          variant="text"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
