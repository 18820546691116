import * as React from 'react';
import { Container } from '@mui/material';
import DomainNotFound from 'common/DomainNotFound';
import { DASHBOARD_ROUTE } from 'src/routes';

const ProjectNotFound = () => {
  return (
    <Container sx={{ width: '80%', mt: '5%' }}>
      <DomainNotFound
        secondaryText="This project does not exist, or you do not have access to view it."
        domain={'project'}
        action={{ text: 'Back to dashboard', redirect: DASHBOARD_ROUTE }}
        contentStyles={{ marginTop: 32 }}
      />
    </Container>
  );
};

export default ProjectNotFound;
