import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { mapErrors } from 'common/utils/config';
import { ConfigValidationError } from 'src/api/types/ModelConfig';
import { Stringifiable } from '../helper.types';
import { StringFormatter } from './string';

export const OtherFormatter = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- the point of this method is to flatten any value
  flatValue: (value: any) =>
    typeof value === 'object' ? JSON.stringify(value) : String(value),
  messageMaybeError: (message: string, error: Error) =>
    `${
      message[message.length - 1] === '.'
        ? message.substring(0, message.length - 1)
        : message
    }${
      error?.message ? `: ${StringFormatter.capitalize(error.message)}.` : '.'
    }`,

  /**
   * @see https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
   * Note: Ideally, we only pass the error types into the formatter specified in TS. In reality, catch blocks typically
   * set the error type to `any`, so its possible we pass something different or even undefined in here.
   * */
  rtkqError: (err: FetchBaseQueryError | SerializedError) => {
    if (!err) {
      return OtherFormatter.error('Unexpected error');
    }

    if (typeof err === 'string') {
      return OtherFormatter.error(err);
    } else if ('data' in err && err.data != null) {
      if (typeof err.data === 'string') {
        return OtherFormatter.error(err.data);
      } else if (Array.isArray(err.data)) {
        return OtherFormatter.error(err.data);
      } else if (typeof err.data === 'object') {
        if ('error' in err.data && typeof err.data.error === 'string') {
          return OtherFormatter.error(err.data.error);
        } else {
          return OtherFormatter.error(err.data);
        }
      }
    } else if ('error' in err) {
      return OtherFormatter.error(err.error);
    }

    return 'Unknown';
  },
  /**
   * @note use `Formatters.Other.rtkqError()` to handle query errors directly!
   * @todo Handle `context` key? */
  error: (error?: string | string[] | { message: string } | Stringifiable) =>
    !error
      ? ''
      : typeof error === 'string'
        ? error
        : error instanceof Array
          ? error.join(', ')
          : 'message' in error
            ? error.message
            : error.toString(), // No point in trying to stringify/template cast it, it'll just be ugly.
  configValidationErrors: (msgs: ConfigValidationError[]): string[] => {
    const newSet = new Set(mapErrors(msgs));
    return Array.from(newSet);
  },
};
