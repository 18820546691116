import { Components, Theme } from '@mui/material';

const MuiSnackbarContent: Components<Theme>['MuiSnackbarContent'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      padding: 0,
      borderRadius: 0,
    },
    message: {
      padding: 0,
      flex: 3,
    },
    action: {
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
      flex: 1,
      flexShrink: 1,
    },
  },
};

export default MuiSnackbarContent;
