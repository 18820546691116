import * as React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';
import UserAvatar from 'common/UserAvatar';
import { useLoadProfileQuery, useMembersQuery } from 'src/api';
import { Model } from 'src/api/types/Model';
import { User } from 'src/api/types/User';
import { shorthandDistanceToNow } from 'utils/date';
import Formatters from 'utils/formatters';
import useSelectedProject from 'utils/useSelectedProject';

type CreatedByTypes = GridProps & {
  model: Model;
  user: User;
};

const FetchCreatedBy = ({ model, ...props }: { model: Model }) => {
  // data fetching logic
  const { selectedProject } = useSelectedProject();
  const projectId = selectedProject?.guid ?? '';
  const membersQuery = useMembersQuery(
    { guid: projectId },
    { skip: !projectId }
  );
  const creator: User | undefined = React.useMemo(
    () =>
      Object.values(membersQuery.data || {}).find(
        ({ user_id }) => user_id === model.user_id
      ),
    [membersQuery.data, model?.user_id]
  );
  if (!creator) {
    return null;
  } else {
    return <CreatedBy user={creator} model={model} {...props} />;
  }
};

const CreatedBy = ({ user, model, ...props }: CreatedByTypes) => {
  const { data: loggedInUser } = useLoadProfileQuery();
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        ...props.sx,
      }}
      {...props}
    >
      <UserAvatar size="small" user={user} sx={{ mr: 2 }} />
      <Typography variant="body2Strong" component="span">
        {Formatters.User.name(user, loggedInUser?.email === user.email)}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp;created&nbsp;
      </Typography>
      <Typography variant="body2Strong" component="span">
        {shorthandDistanceToNow({
          date: model?.status_history?.created as string,
          addSuffix: true,
        })}
      </Typography>
    </Grid>
  );
};

export default FetchCreatedBy;
