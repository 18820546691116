import { Components, Theme } from '@mui/material';

const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  defaultProps: {
    disableSticky: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body1Strong,
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }),
  },
};

export default MuiListSubheader;
