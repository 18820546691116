import { Components, Theme } from '@mui/material';

const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true,
    square: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.background.default,
      '&::before, &::after': {
        display: 'none',
      },
      '.MuiAccordionSummary-expandIconWrapper': {
        order: -1,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
      },
      '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    }),
  },
};

export default MuiAccordion;
