import { Components, Theme } from '@mui/material';

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    error: true;
  }
}

const MuiSlider: Components<Theme>['MuiSlider'] = {
  variants: [
    {
      props: { color: 'error' },
      style: ({ theme }) => ({
        color: theme.palette.error.main,
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary.light,
      height: theme.spacing(2),
    }),
  },
};

export default MuiSlider;
