export enum STAGE {
  READY = 'STAGE.READY',
  LOADING = 'STAGE.LOADING',
  CREATING = 'STAGE.CREATING',
  UPDATING = 'STAGE.UPDATING',
  DELETING = 'STAGE.DELETING',
  FLUSHING = 'STAGE.FLUSHING',
  CREATED = 'STAGE.CREATED',
  UPDATED = 'STAGE.UPDATED',
  DELETED = 'STAGE.DELETED',
  FLUSHED = 'STAGE.FLUSHED',
  CANCELLED = 'STAGE.CANCELLED',
  PROCESSING = 'STAGE.PROCESSING',
  MATCHED = 'STAGE.MATCHED',
  DONE = 'STAGE.DONE',
  WARNING = 'STAGE.WARNING',
  ERROR = 'STAGE.ERROR',
}

const VARIABLE_LOADING_STAGES = [
  STAGE.CREATING,
  STAGE.DELETING,
  STAGE.FLUSHING,
  STAGE.PROCESSING,
  STAGE.UPDATING,
];

const VARIABLE_LOADED_STAGES = [
  STAGE.CREATED,
  STAGE.DELETED,
  STAGE.FLUSHED,
  STAGE.MATCHED,
  STAGE.UPDATED,
];

const EXCEPTION_STAGES = [STAGE.WARNING, STAGE.ERROR, STAGE.CANCELLED];

export const isCancelled = (stage: STAGE) => stage === STAGE.CANCELLED;

export const isFlushed = (stage: STAGE) => stage === STAGE.FLUSHED;

export const isDone = (stage: STAGE, strict = false) =>
  [STAGE.DONE, ...(strict ? [] : VARIABLE_LOADED_STAGES)].includes(stage);

export const isError = (stage: STAGE) => stage === STAGE.ERROR;

export const isException = (stage: STAGE) => EXCEPTION_STAGES.includes(stage);

export const isLoading = (stage: STAGE, strict = false) =>
  [STAGE.LOADING, ...(strict ? [] : VARIABLE_LOADING_STAGES)].includes(stage);

export const isProcessing = (stage: STAGE) => stage === STAGE.PROCESSING;

export const isReady = (stage: STAGE) => stage === STAGE.READY;

export const isReadyOrLoading = (stage: STAGE, strict = false) =>
  isReady(stage) || isLoading(stage, strict);

export default STAGE;
