import * as React from 'react';
import { Grid } from '@mui/material';
import DownloadArtifactButton from 'common/input/Download/ArtifactButton';
import DownloadRecordButton from 'common/input/Download/RecordButton';
import { getModelArtifacts, useModelArtifactLazyQuery } from 'src/api';
import { ModelArtifact, ModelArtifactType } from 'src/api/types/Model';
import { Download, Score as ScoreIcon } from 'src/resources/icons/figma';

export const NextStepsTabContent = ({
  modelName,
  modelUid,
  projectGuid,
  artifacts,
}: {
  modelName: string;
  modelUid: string;
  projectGuid: string;
  artifacts?: ModelArtifact[];
}) => {
  const modelHasGptReport =
    artifacts &&
    artifacts.find(
      artifact => artifact.name === ModelArtifactType.TEXT_METRICS_REPORT
    );
  const modelHasSqsReport =
    artifacts &&
    artifacts.find(artifact => artifact.name === ModelArtifactType.REPORT);

  return (
    <Grid display="flex" gap={2} sx={{ justifyContent: 'space-between' }}>
      {modelHasGptReport && (
        <DownloadArtifactButton
          id="model-complete-success-report"
          color="secondary"
          icon={<ScoreIcon />}
          iconPlacement="start"
          sx={{ flex: 1 }}
          type="text/html"
          fetchArgs={{
            guid: projectGuid,
            uid: modelUid,
            type: 'text_metrics_report',
          }}
          lazyHook={useModelArtifactLazyQuery}
          getFile={() => getModelArtifacts()[modelUid]?.text_metrics_report}
          artifactName={`${modelName}-text_metrics_report.html`}
        >
          View the report
        </DownloadArtifactButton>
      )}
      {!modelHasGptReport && modelHasSqsReport ? (
        <DownloadArtifactButton
          id="model-complete-success-report"
          color="secondary"
          icon={<ScoreIcon />}
          iconPlacement="start"
          sx={{ flex: 1 }}
          type="text/html"
          fetchArgs={{
            guid: projectGuid,
            uid: modelUid,
            type: 'report',
          }}
          lazyHook={useModelArtifactLazyQuery}
          getFile={() => getModelArtifacts()[modelUid]?.report}
          artifactName={`${modelName}-report.html`}
        >
          View the report
        </DownloadArtifactButton>
      ) : null}
      <DownloadRecordButton
        id="model-complete-success-data"
        color="primary"
        startIcon={<Download />}
        lazyHook={useModelArtifactLazyQuery}
        fetchArgs={{
          guid: projectGuid,
          uid: modelUid,
          type: 'data_preview',
          download: false,
        }}
        sx={{ flex: 1 }}
      >
        Download synthetic data
      </DownloadRecordButton>
    </Grid>
  );
};
