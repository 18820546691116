import * as React from 'react';
import type { BoxProps, SxProps, Theme, TypographyProps } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import HelpIconTooltip from 'common/HelpIconTooltip';

export type MetricLabelProps = React.PropsWithChildren<{
  label: React.ReactNode;
  LabelProps?: TypographyProps;
  icon?: React.ReactNode;
  IconProps?: BoxProps;
  tooltip?: React.ReactNode;
  value?: string;
  ValueProps?: TypographyProps;
  sx?: SxProps<Theme>;
}>;

const MetricLabel = ({
  label,
  LabelProps = {
    variant: 'body2',
    color: 'textSecondary',
  } as TypographyProps,
  value,
  ValueProps = { variant: 'body1Medium' } as TypographyProps,
  icon,
  IconProps,
  tooltip,
  children,
  sx,
}: MetricLabelProps) => {
  return (
    <Grid item display="flex" flexDirection="column" sx={sx}>
      <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
        <Typography {...LabelProps}>{label}</Typography>
        {tooltip && <HelpIconTooltip title={tooltip} arrow />}
      </Grid>
      <Grid container flexGrow={1}>
        <Grid container alignItems="center">
          {icon && (
            <Box {...IconProps} sx={{ mr: 2, lineHeight: 0, ...IconProps?.sx }}>
              {icon}
            </Box>
          )}
          {value ? <Typography {...ValueProps}>{value}</Typography> : children}
        </Grid>
      </Grid>
    </Grid>
  );
};

MetricLabel.displayName = 'MetricLabel';

export default MetricLabel;
