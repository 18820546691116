import { Components, Theme } from '@mui/material';

const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: '56px',
      backgroundColor: theme.palette.background.default,
    }),
  },
};

export default MuiToolbar;
