import { AnalyticsBrowser } from '@segment/analytics-next';

// As new analytics values are added please append the string literals below
type AnalyticsEvent =
  | 'playground_feedback_negative'
  | 'playground_feedback_positive'
  | 'playground_paste_prompt_input';

/**
 * if you need to add fields, please add them to the strong type so that
 * others in the future will use the same field names.
 * please snake_case the fields, to emulate BE. */
type AnalyticsProperties = {
  stream_id: string;
};

/**
 * This is currently set to never due to being unneeded (and satisfying TS)
 * if the need arises for having strongly typed options,
 * please remove the "[key: string]: never;" line and replace it with
 * whatever field is needed. */
type AnalyticsOptions = {
  [key: string]: never;
};

declare module '@segment/analytics-next' {
  interface AnalyticsBrowser {
    /**
     * [Segment.io: Analytics.js #track](https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track)
     *
     * The Track method lets you record actions your users perform.
     * You can [see a track example in the Quickstart guide](https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-4-track-actions) or find details on [the track method payload](https://segment.com/docs/connections/spec/track/).
     * We are using strong typing around the events, please see src/common/analytics.ts and add what is needed there.
     *
     * ### Example
     * ```typescript
     *  analytics.track('Article Completed', {
     *    title: 'How to Create a Tracking Plan',
     *    course: 'Intro to Analytics',
     *  });
     *  ```
     *
     * ### Signature
     * - `event_name: string` — The name of the event you’re tracking. **This is a strongly typed string in src/common/analytics.ts, so please add your event there**.
     * - `properties?: object` — Optional. A dictionary of properties for the event. If the event was 'Added to Cart', it might have properties like price and productType.
     * - `options?: object` — Optional. A dictionary of options. For example, enable or disable specific destinations for the call. Note: If you do not pass a properties object, pass an empty object (like ‘{}’) before options.
     */
    track(
      event_name: AnalyticsEvent,
      properties?: AnalyticsProperties,
      options?: AnalyticsOptions
    );
  }
}

export const analytics = new AnalyticsBrowser();

export const { track, identify, load } = analytics;

export default analytics;
