/* eslint-disable func-style */
import * as React from 'react';
import { __RouterContext, RouteComponentProps, useHistory } from 'react-router';
import { compile } from 'path-to-regexp';
import { DASHBOARD_ROUTE, Route } from 'src/routes';

export const ConsoleRouteContext = React.createContext<Route>(DASHBOARD_ROUTE);

type PathOptions = {
  params?: object;
  search?: string;
  hash?: string;
  method?: string;
  from?: object;
};

/**
 * **NOTE: DO NOT USE FOR react-router LINKS. Use compileLocation instead.**
 * A method to compile a Console Route path into an actual path.
 * @param route A route object from the routes index (src/routes.js)
 * @param [options = {}] Options for the path:
 *  - [params = {}] {Object} The route parameters to use when replacing tokens.
 *                    If none given, tries to use current route params, otherwise error.
 *  - [search] {String} A string of URL search/query arguments, e.g. 'page=5&count=10'
 *  - [hash] {String} A string for the URL hash/anchor.
 */
export const compilePath_unsmart = (
  route: { path: string },
  options: PathOptions = {}
) => {
  const { params = {}, search, hash } = options;
  const makeNewURL = compile(route.path);
  try {
    const newUrl = makeNewURL(params);
    return newUrl + (search ? `?${search}` : '') + (hash ? `#${hash}` : '');
  } catch (e) {
    console.error(e, route, options);
    return route.path;
  }
};

type UseConsoleRoute = {
  consoleRoute: Route;

  compilePath(
    route: Route,
    options?: PathOptions
  ): ReturnType<typeof compilePath_unsmart>;

  /**
   * TODO: See if there's some way we can actually use react router/window `history` in a way that is compatible with
   *        actual links (instead of a programmatic .back() that doesn't allow middle-click)
   * Creates a location object suitable for a Link that adds state.
   * @returns Location object for a react-router link with history state.
   */
  compileLocation(
    route: Route,
    options?: PathOptions
  ): RouteComponentProps['location'] &
    Omit<PathOptions, 'params'> & {
      pathname: string;
      state: object;
    };

  /**
   * A method to navigate to a console route.
   * @param  route A route object from the routes index (src/routes.js)
   *                              or a string pathname
   * @param [options = {}] Options for the navigation:
   *  - [params = {}] {Object} The route parameters to use when replacing tokens.
   *                    If none given, tries to use current route params, otherwise error.
   *                    Ignored if route is a string.
   *  - [search] {String} A string of URL search/query arguments, e.g. 'page=5&count=10'.
   *                    Ignored if route is a string.
   *  - [method = 'push'] {String} The react-router history method to use when navigating.
   * @param [state] Optional state for history
   */
  go(route: string | Route, options?: PathOptions, state?: object): void;
};

/**
 * @param params Optional args:
 *  - [withRouterContext = false] {Boolean} True to include react-router contexts (i.e. data returned from their hooks)
 * @returns with the following properties:
 *  - consoleRoute {Object} The console route object corresponding to the current page/path.
 *  - compilePath {Function} A method to compile a Console Route path into an actual path.
 *  - go {Function} A method to navigate to a console route
 *  - location {Object} react-router Location (if withRouterContext = true)
 *  - history {Object} react-router History (if withRouterContext = true)
 *  - match {Object} react-router Match (if withRouterContext = true)
 */

function useConsoleRoute(params?: {
  withRouterContext?: false;
}): UseConsoleRoute;
function useConsoleRoute(params: {
  withRouterContext: true;
}): UseConsoleRoute & RouteComponentProps;

function useConsoleRoute(params: { withRouterContext?: boolean } = {}) {
  const consoleRoute = React.useContext(ConsoleRouteContext);
  const reactRouterContext = React.useContext(__RouterContext);
  const history = useHistory();
  const { childComponent, ...serializableConsoleRoute } = consoleRoute;

  const res: UseConsoleRoute = {
    consoleRoute,
    compilePath: (route, options = {}) => {
      const { params = {} } = options;
      const currentParams = reactRouterContext?.match?.params;
      return compilePath_unsmart(route, {
        ...options,
        params: { ...currentParams, ...params },
      });
    },

    compileLocation: (route, { params, ...options } = {}) => ({
      ...reactRouterContext.location,
      ...options,
      pathname: res.compilePath(route, { params }),
      state: {
        from: {
          ...reactRouterContext.location,
          ...(options.from || {}),
          consoleRoute: serializableConsoleRoute,
        },
      },
    }),

    go: (
      route,
      options = {},
      state = {
        from: {
          ...reactRouterContext.location,
          ...(options.from || {}),
          consoleRoute: serializableConsoleRoute,
        },
      }
    ) => {
      const { method = 'push', ...rest } = options;
      history[method](
        typeof route === 'string' ? route : res.compilePath(route, rest),
        state
      );
    },
  };

  if (params?.withRouterContext) {
    return { ...res, ...reactRouterContext };
  }

  return res;
}

export default useConsoleRoute;
