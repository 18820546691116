import * as React from 'react';
import type { DropzoneRef } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { ActionCreators, KeyedUpload } from 'common/Upload/uploadDuck';
import type { ValidationResponse } from 'utils/data';
import { validateFiles } from 'utils/data';
import { logger } from 'utils/logger';
import useActions from 'utils/useActions';
import useSelectedProject from 'utils/useSelectedProject';

export interface UploadDropzoneCardProps {
  children?: React.ReactNode;
  dragActiveChildren?: React.ReactNode;
  forceDragActive?: boolean;
  ComponentProps?: React.HTMLAttributes<HTMLDivElement>;
  onDrop?: (result: ValidationResponse) => boolean;
  onUploaded?: (uploaded: KeyedUpload[]) => void;
  multiple?: boolean;
  isLoading?: boolean;
  showSampleFilesButton?: {
    label: string;
    onClick: () => void;
  };
  secondaryText?: string;
}

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  border: '1px dashed',
  borderColor: theme.palette.primary[200],
  borderRadius: theme.shape.radii.base,
  minHeight: '160px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: isActive ? theme.palette.primary[50] : 'inherit',
  boxShadow: isActive ? theme.shadows[2] : 'inherit',
}));

const UploadDropzoneCard = React.forwardRef<
  DropzoneRef,
  UploadDropzoneCardProps
>(
  (
    {
      children = '',
      dragActiveChildren = '',
      forceDragActive = false,
      ComponentProps = { className: '' },
      onDrop = () => true,
      multiple = true,
      showSampleFilesButton = undefined,
      onUploaded = () => void 0,
      isLoading: parentSaysLoading = false,
      secondaryText,
      ...props
    },
    ref
  ) => {
    const { queueUploads, queueRejected } = useActions(ActionCreators);
    const { selectedProject } = useSelectedProject();
    const selectedProjectGuid = selectedProject?.guid;

    const [internalLoading, setIsLoading] = React.useState(parentSaysLoading);

    const isLoading = parentSaysLoading || internalLoading;

    /**
     * NOTE: this is making a lot of assumption about the order
     * of operations that are no longer true with use cases
     * this is probably worth a refactor at some point.
     */
    const handleDrop = React.useCallback(
      files => {
        const { accepted, rejected } = validateFiles(files);
        const formattedAccepted = accepted.map(file =>
          Object.assign(
            file,
            selectedProjectGuid
              ? { projectId: selectedProjectGuid }
              : { newProject: true }
          )
        );
        const shouldAddToQueue = onDrop({
          accepted: formattedAccepted,
          rejected,
        });

        /**
         * this is looking _specifically_ for `false` because onDrop function will sometimes RETURN false
         * as a way to bypass the queueing that happens below.
         * */
        if (shouldAddToQueue === false) {
          return;
        }

        if (formattedAccepted.length) {
          setIsLoading(true);
          queueUploads({ files: formattedAccepted })
            // not sure when/why this broke… -walston
            // doesn't **SEEM** to be related to changes on the call to useActions(ActionCreators)
            .then(data => {
              setIsLoading(false);
              onUploaded(data.payload);
            })
            .catch((error: unknown) => {
              logger.error(error);
              setIsLoading(false);
            });
        }
        if (rejected.length) {
          queueRejected({ files: rejected });
        }
      },
      [selectedProjectGuid, onDrop, queueUploads, queueRejected, onUploaded]
    );
    const helperText =
      secondaryText ??
      `or drag and drop ${
        multiple ? '' : 'a'
      } CSV, JSON(L), or Parquet file${multiple ? 's' : ''} here to upload`;

    const { className: componentClassName, ...rest } = ComponentProps;

    return (
      <Dropzone
        onDrop={handleDrop}
        disabled={isLoading}
        multiple={multiple}
        noClick
        noKeyboard
        ref={ref}
        {...props}
      >
        {({ getRootProps, getInputProps, rootRef, inputRef, isDragActive }) => (
          <StyledBox
            {...rest}
            isActive={isDragActive || forceDragActive}
            className={componentClassName}
            {...getRootProps()}
            ref={rootRef as React.RefObject<HTMLDivElement>}
          >
            <input {...getInputProps()} ref={inputRef} id="file-upload" />
            <Grid container justifyContent="center" sx={{ mb: 4 }}>
              {/* this <label> disguised as a button will automagically open
               * the <input id="file-upload"> because of htmlFor -walston */}
              <Button
                htmlFor="file-upload"
                component="label"
                color="primary"
                sx={{ minWidth: '92px' }}
              >
                {isLoading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  `Choose file${multiple ? 's' : ''}`
                )}
              </Button>
              {showSampleFilesButton ? (
                <Button
                  component="label"
                  color="secondary"
                  onClick={showSampleFilesButton.onClick}
                  data-testid="sample-file"
                >
                  {showSampleFilesButton.label}
                </Button>
              ) : null}
            </Grid>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {helperText}
            </Typography>
            {isDragActive || forceDragActive ? dragActiveChildren : children}
          </StyledBox>
        )}
      </Dropzone>
    );
  }
);

UploadDropzoneCard.displayName = 'UploadDropzoneCard';

export default UploadDropzoneCard;
