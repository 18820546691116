import { Components, Theme } from '@mui/material';

const MuiPopover: Components<Theme>['MuiPopover'] = {
  defaultProps: {
    elevation: 4,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiListItem-root': {
        '&.Mui-disabled': {
          opacity: 1,
          '& *': {
            fill: theme.palette.text.disabled,
            color: theme.palette.text.disabled,
          },
        },
      },
      '& .MuiListItem-button,& .MuiListItem-container, & .MuiListItemText-primary':
        {
          transition: 'none',
          '&:hover': {
            '& .MuiChip-root': {
              color: 'inherit',
            },
            '& .MuiCheckbox-root': {
              backgroundColor: theme.palette.primary[500],
              color: theme.palette.common.white,
              '& .iconBorder': {
                color: theme.palette.common.white,
                fill: theme.palette.primary[500],
              },
              '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                '& .iconBorder': {
                  fill: theme.palette.common.white,
                  color: theme.palette.primary[500],
                },
                '& .iconCenter': {
                  color: theme.palette.common.white,
                  fill: theme.palette.primary[500],
                },
              },
            },
          },
        },
    }),
  },
};

export default MuiPopover;
