import { Components, Theme } from '@mui/material';

const MuiCardActions: Components<Theme>['MuiCardActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderTop: `1px solid ${theme.palette.border.default}`,
      backgroundColor: theme.palette.background.secondary,
      padding: theme.spacing(3, 6),
      minHeight: theme.spacing(14),
    }),
  },
};

export default MuiCardActions;
