import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { ProjectType } from 'src/api/types/Project';
import {
  ConnectionType,
  RELATIONAL_CONNECTION_TYPES,
} from 'src/Console/Connections/utils';
import { MaybeCron, TriggerVariant } from './types';

export type SelectedRelationalType =
  | 'entireDatabase'
  | 'tableNames'
  | 'sqlQuery';

export type WorkflowState = {
  projectId: string | null;
  projectType?: ProjectType;
  trigger: {
    when: TriggerVariant;
    cron: string | null;
  };
  input: {
    connectionId?: string;
    connectionType: ConnectionType | null;
    bucket?: string;
    container?: string;
    glob_filter?: string;
    path?: string;
    recursive?: boolean;
    tableNames?: string[];
    sqlQueryName?: string;
    sqlQuery?: string;
    selectedRelationalType?: SelectedRelationalType;
    dataset?: string;
  };
  output: {
    source: 'connection' | 'gretel';
    connectionId?: string;
    connectionType: ConnectionType | null;
    bucket?: string;
    container?: string;
    path: string;
    dataset?: string;
  };
};

export const initialState: WorkflowState = {
  projectId: null,
  trigger: {
    when: 'Manual',
    cron: null,
  },
  input: {
    connectionId: undefined,
    connectionType: 's3',
    bucket: '',
    container: '',
    glob_filter: '*.csv',
    path: '',
    recursive: false,
  },
  output: {
    source: 'gretel',
    connectionId: undefined,
    connectionType: null,
    bucket: '',
    container: '',
    path: 'gretel',
  },
};

const workflowsDuck = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    setWorkflowInputs: (
      state: WorkflowState,
      action: PayloadAction<Partial<WorkflowState['input']>>
    ) => {
      Object.assign(state.input, action.payload);
      const connectionType =
        action.payload.connectionType ?? state.input.connectionType;

      if (
        connectionType &&
        !RELATIONAL_CONNECTION_TYPES.includes(connectionType)
      ) {
        // Don't prefill these for relational types. For object-based connectors,
        // we DO want to prefill these for convenience.
        Object.assign(
          state.output,
          pick(action.payload, ['glob_filter', 'path'])
        );
      }
    },
    setWorkflowOutputs: (
      state: WorkflowState,
      action: PayloadAction<Partial<WorkflowState['output']>>
    ) => {
      Object.assign(state.output, action.payload);
    },
    setWorkflowMetadata: (
      state: WorkflowState,
      action: PayloadAction<Partial<WorkflowState>>
    ) => {
      const metadata = action.payload;

      return {
        ...state,
        ...metadata,
      };
    },
    setScheduleMode: (
      state: WorkflowState,
      action: PayloadAction<TriggerVariant>
    ) => {
      state.trigger.when = action.payload;

      if (action.payload === 'Manual') {
        state.trigger.cron = null;
      }
    },
    setScheduleCron: (
      state: WorkflowState,
      action: PayloadAction<MaybeCron | null>
    ) => {
      state.trigger.cron = action.payload;
    },
    resetWorkflowInputs: (state: WorkflowState) => {
      state.input = initialState.input;
    },
    resetWorkflowOutputs: (state: WorkflowState) => {
      state.output = initialState.output;
    },
    reset: () => initialState,
  },
});

export const {
  setWorkflowInputs,
  resetWorkflowInputs,
  setWorkflowOutputs,
  setWorkflowMetadata,
  reset,
} = workflowsDuck.actions;

export default workflowsDuck;
