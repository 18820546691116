import * as React from 'react';
import { toast } from 'react-hot-toast';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { Download, Show, Triangle } from 'icons/figma';
import { downloadFileFromLink, openFileNewWindowFromLink } from 'utils/data';
import Formatters from 'utils/formatters';

type DownloadArtifactButtonProps = Omit<ButtonProps, 'onClick' | 'type'> &
  React.PropsWithChildren<{
    onClick?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => boolean;
    icon?: React.ReactNode;
    iconPlacement?: 'start' | 'end' | 'none';
    disabled?: boolean;
    // type: mime_type for download, e.g., 'text/csv'
    type?: string;
    lazyHook: () => [
      trigger: (options?: Record<string, unknown>) => void, //options is actually fetchArgs
      result: Record<string, unknown>,
      lastPromiseInfo: Record<string, unknown>,
    ];
    fetchArgs: Record<string, unknown>;
    getFile: () => File | void;
    artifactName: string;
    snackbar?: string;
  }>;

const LOADING_STYLE = {
  backgroundColor: 'grey.100',
  color: 'grey.400',
};

const DownloadArtifactButton = React.forwardRef<
  HTMLButtonElement,
  DownloadArtifactButtonProps
>(
  (
    {
      children,
      lazyHook: useArtifactLazyHook,
      fetchArgs,
      getFile,
      artifactName,
      onClick = () => true,
      icon: iconProp = <Download height={16} width={16} />,
      iconPlacement = 'end',
      snackbar,
      disabled = false,
      type,
      ...buttonProps
    },
    ref
  ) => {
    const file = getFile();
    const [downloaded, setDownloaded] = React.useState(Boolean(file));
    const [loadArtifact, artifactQuery] = useArtifactLazyHook();
    const fileName =
      artifactName || Formatters.Project.artifactName(file?.name);

    let icon = iconProp;
    if (type == 'text/html') {
      icon = <Show height={16} width={16} />;
    }
    const iconProps = React.useMemo(
      () =>
        iconPlacement === 'none'
          ? {}
          : {
              [`${iconPlacement}Icon`]: artifactQuery.isFetching ? (
                <CircularProgress
                  size={16}
                  sx={{ color: LOADING_STYLE.color }}
                />
              ) : artifactQuery.isError ? (
                <Triangle height={16} width={16} />
              ) : (
                icon
              ),
            },
      [artifactQuery.isFetching, artifactQuery.isError, icon, iconPlacement]
    );

    const handleClick = React.useCallback(
      event => {
        if (artifactQuery.isFetching || disabled || onClick(event) === false) {
          return false;
        }
        setDownloaded(false);
        loadArtifact(fetchArgs);
      },
      [onClick, artifactQuery.isFetching, disabled, loadArtifact, fetchArgs]
    );

    React.useEffect(() => {
      if (file && !downloaded && !disabled) {
        if (type == 'text/html') {
          openFileNewWindowFromLink(file, fileName, type);
        } else {
          downloadFileFromLink(file, fileName, type);
        }
        setDownloaded(true);
        if (snackbar) {
          toast.success(snackbar);
        }
      }
    }, [downloaded, disabled, fileName, type, file, snackbar]);

    return (
      <Button
        {...buttonProps}
        sx={{
          ...buttonProps.sx,
          ...(artifactQuery.isFetching ? LOADING_STYLE : undefined),
        }}
        onClick={handleClick}
        {...iconProps}
        disabled={disabled}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

DownloadArtifactButton.displayName = 'DownloadArtifactButton';

export default DownloadArtifactButton;
