import { api } from './baseApi';

export const addTagTypes = ['Connections'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      listConnections: build.query<
        ListConnectionsApiResponse,
        ListConnectionsApiArg
      >({
        query: () => ({ url: `/v1/connections` }),
        providesTags: ['Connections'],
      }),
      createConnection: build.mutation<
        CreateConnectionApiResponse,
        CreateConnectionApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections`,
          method: 'POST',
          body: queryArg.createConnectionRequest,
        }),
        invalidatesTags: ['Connections'],
      }),
      getGretelConnectionWithCredentials: build.query<
        GetGretelConnectionWithCredentialsApiResponse,
        GetGretelConnectionWithCredentialsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/gretel`,
          params: {
            workflow_run_id: queryArg.workflowRunId,
          },
        }),
        providesTags: ['Connections'],
      }),
      listSampleConnections: build.query<
        ListSampleConnectionsApiResponse,
        ListSampleConnectionsApiArg
      >({
        query: () => ({ url: `/v1/connections/sample_connections` }),
        providesTags: ['Connections'],
      }),
      searchConnections: build.query<
        SearchConnectionsApiResponse,
        SearchConnectionsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/search`,
          params: {
            query: queryArg.query,
            sort: queryArg.sort,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ['Connections'],
      }),
      getConnection: build.query<GetConnectionApiResponse, GetConnectionApiArg>(
        {
          query: queryArg => ({
            url: `/v1/connections/${queryArg.connectionId}`,
          }),
          providesTags: ['Connections'],
        }
      ),
      deleteConnection: build.mutation<
        DeleteConnectionApiResponse,
        DeleteConnectionApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Connections'],
      }),
      updateConnection: build.mutation<
        UpdateConnectionApiResponse,
        UpdateConnectionApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}`,
          method: 'PATCH',
          body: queryArg.updateConnectionRequest,
        }),
        invalidatesTags: ['Connections'],
      }),
      getConnectionWithCredentials: build.query<
        GetConnectionWithCredentialsApiResponse,
        GetConnectionWithCredentialsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}/credentials`,
        }),
        providesTags: ['Connections'],
      }),
      validateConnectionCredentials: build.mutation<
        ValidateConnectionCredentialsApiResponse,
        ValidateConnectionCredentialsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}/validate-credentials`,
          method: 'POST',
        }),
        invalidatesTags: ['Connections'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ConnectionsAPI };
export type ListConnectionsApiResponse =
  /** status 200 OK */ ListConnectionsResponse;
export type ListConnectionsApiArg = void;
export type CreateConnectionApiResponse = /** status 200 OK */ Connection;
export type CreateConnectionApiArg = {
  createConnectionRequest: CreateConnectionRequest;
};
export type GetGretelConnectionWithCredentialsApiResponse =
  /** status 200 OK */ Connection;
export type GetGretelConnectionWithCredentialsApiArg = {
  workflowRunId?: string;
};
export type ListSampleConnectionsApiResponse =
  /** status 200 OK */ ListConnectionsResponse;
export type ListSampleConnectionsApiArg = void;
export type SearchConnectionsApiResponse =
  /** status 200 OK */ SearchConnectionsResponse;
export type SearchConnectionsApiArg = {
  /** Query string to filter connections. Supported fields are: `project_id` */
  query?: string;
  /** Sort order for connections. Supported sort fields are: `created_at`, `updated_at` */
  sort?: string;
  /** Maximum number of connections to return. Default is no limit. */
  limit?: number;
  /** Number of connections to skip before applying limit. */
  skip?: number;
};
export type GetConnectionApiResponse = /** status 200 OK */ Connection;
export type GetConnectionApiArg = {
  connectionId: string;
};
export type DeleteConnectionApiResponse = /** status 200 OK */ string;
export type DeleteConnectionApiArg = {
  /** The connection ID to delete. */
  connectionId: string;
};
export type UpdateConnectionApiResponse = /** status 200 OK */ Connection;
export type UpdateConnectionApiArg = {
  /** The connection ID to update. */
  connectionId: string;
  updateConnectionRequest: UpdateConnectionRequest;
};
export type GetConnectionWithCredentialsApiResponse =
  /** status 200 OK */ Connection;
export type GetConnectionWithCredentialsApiArg = {
  connectionId: string;
};
export type ValidateConnectionCredentialsApiResponse =
  /** status 200 OK */ ValidateConnectionCredentialsResponse;
export type ValidateConnectionCredentialsApiArg = {
  connectionId: string;
};
export type Connection = {
  /** The id of the connection. Connection id's are prefixed with `c_`. */
  id: string;
  /** Type of the connection: aws, gcs, azure etc. */
  type: string;
  /** Name of the connection. */
  name: string;
  /** Current connection validation status. Possible values are: `COMPLETED`, `ERROR`, or `NONE`. */
  validation_status:
    | 'VALIDATION_STATUS_UNKNOWN'
    | 'VALIDATION_STATUS_VALIDATING'
    | 'VALIDATION_STATUS_VALID'
    | 'VALIDATION_STATUS_INVALID';
  /** Connection credentials in plain text. */
  credentials?: object;
  /** The connection configuration. See [connection type documentation](https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors) for structure. */
  config?: object;
  /** Connection credentials in encrypted form. */
  encrypted_credentials?: object;
  /** When true, this connection is using a customer-managed key to encrypt credentials. Otherwise, this connection is using a Gretel-managed key to encrypt credentials. */
  customer_managed_credentials_encryption: boolean;
  /** Timestamp when this connection was created. */
  created_at: string;
  /** ID of the project that owns this connection */
  project_id: string;
  /** ID of the user who created this connection */
  created_by: string;
  /** The type of workflow action this connection may be used with. If empty or `unspecified`, this connection may be used with any workflow action. Possible values are: `source`, `destination`, `unspecified` */
  connection_target_type?: string;
  /** The auth strategy used when supported by a connection type. See [connection type documentation](https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors) for possible values. */
  auth_strategy?: string;
};
export type ListConnectionsResponse = {
  data?: Connection[];
};
export type GoogleProtobufAny = {
  /** The type of the serialized message. */
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  /** The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code]. */
  code?: number;
  /** A developer-facing error message, which should be in English. Any user-facing error message should be localized and sent in the [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client. */
  message?: string;
  /** A list of messages that carry the error details.  There is a common set of message types for APIs to use. */
  details?: GoogleProtobufAny[];
};
export type CreateConnectionRequest = {
  project_id: string;
  name?: string;
  type: string;
  /** Plaintext credentials for the connection, to be encrypted in our cloud. If this field is set, encrypted_credentials must be unset. At least one of those fields must be set. */
  credentials?: object;
  /** Pre-encrypted credentials for the connection, encrypted by a customer-managed key. If this field is set, credentials must be unset. At least one of those fields must be set. */
  encrypted_credentials?: object;
  config?: object;
  /** The type of workflow action this connection may be used with. If empty or `unspecified`, this connection may be used with any workflow action. Possible values are: `source`, `destination`, `unspecified` */
  connection_target_type?: string;
  auth_strategy?: string;
};
export type SearchConnectionsResponse = {
  /** List of sorted connections that match the provided query, given the limit and skip. */
  connections?: Connection[];
  /** Total number of connections that match the provided query. */
  total: number;
};
export type UpdateConnectionRequest = {
  /** A new connection name. (optional) */
  name?: string;
  /** Plaintext credentials for the connection, to be encrypted in our cloud. This field may only be set if the existing connection's credentials are encrypted with a Gretel-managed key. */
  credentials?: object;
  /** Pre-encrypted credentials for the connection, encrypted by a customer-managed key. This field may only be set if the existing connection's credentials are encrypted with a user-managed key. */
  encrypted_credentials?: object;
  /** An updated connection configuration. See [connection type documentation](https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors) for structure. */
  config?: object;
  /** An updated workflow action target type for this connection. Possible values are: `source`, `destination`, `unspecified` */
  connection_target_type?: string;
  /** An updated connection auth strategy. See [connection type documentation](https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors) for possible values. */
  auth_strategy?: string;
};
export type ValidateConnectionCredentialsResponse = {
  status?:
    | 'VALIDATION_STATUS_UNKNOWN'
    | 'VALIDATION_STATUS_VALIDATING'
    | 'VALIDATION_STATUS_VALID'
    | 'VALIDATION_STATUS_INVALID';
  message?: string;
};
export const {
  useListConnectionsQuery,
  useCreateConnectionMutation,
  useGetGretelConnectionWithCredentialsQuery,
  useListSampleConnectionsQuery,
  useSearchConnectionsQuery,
  useGetConnectionQuery,
  useDeleteConnectionMutation,
  useUpdateConnectionMutation,
  useGetConnectionWithCredentialsQuery,
  useValidateConnectionCredentialsMutation,
} = injectedRtkApi;
