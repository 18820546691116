import { Components, Theme } from '@mui/material';

const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: {
    disableRipple: true,
    iconPosition: 'start',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button1,
      textTransform: 'none',
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0, 3),
      color: theme.palette.text.secondary,
      height: 'auto',
      minHeight: 'unset',
      minWidth: 'unset',
      maxWidth: 'unset',
      opacity: 1,
      ':hover,:active,&.Mui-selected': {
        color: theme.palette.text.primary,
      },
      ':disabled,&.Mui-disabled, &.MuiTab-root.Mui-disabled > svg': {
        color: theme.palette.text.disabled,
      },
      '&:first-of-type': {
        marginLeft: 0,
      },
    }),
    iconWrapper: ({ theme }) => ({
      marginRight: theme.spacing(2),
    }),
    labelIcon: ({ theme }) => ({
      '&.MuiTab-root > svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: theme.spacing(2),
        color: theme.palette.grey[600],
      },
    }),
  },
};

export default MuiTab;
