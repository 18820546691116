import { Components, Theme } from '@mui/material';

const MuiStepContent: Components<Theme>['MuiStepContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderLeft: `3px solid ${theme.palette.border.default}`,
      paddingLeft: theme.spacing(8),
    }),
  },
};

export default MuiStepContent;
