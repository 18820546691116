import { Components, Theme } from '@mui/material';

const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(6),
    }),
  },
};

export default MuiCardContent;
