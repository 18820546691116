import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { routerMiddleware } from 'connected-react-router';
import upload from 'common/Upload/uploadDuck';
import GretelAPI from 'src/api';
import router, { history } from 'src/Console/App/Router/routerDuck';
import modelDialogSlice, {
  modelDialogLocalStorageMatcher,
  updateModelDialogLocalStorage,
} from 'src/Console/Projects/Models/CompletionDialog/modelDialogDuck';
import projects from 'src/Console/Projects/projectsDuck';
import {
  useCasesWorkflowDuck,
  useCasesWorkflowMiddleware,
} from 'src/Console/UseCases/useCasesWorkflowDuck';
import workflows from 'src/Console/Workflows/duck';
import { LambdaAPI } from './api/lambda';
import { PilotAPI } from './api/pilot';
import { modelTemplatesSlice } from './api/templates/models';
import { tenantsDuck } from './api/tenants';
import { builderDuck } from './Console/Builder/duck';
import connectionWizard from './Console/Connections/Wizard/duck';
import playground from './Console/Playground/playgroundDuck';

const WARNING_DELAY = 1_000_000;
const WARN_IN_DEV =
  process.env.NODE_ENV === 'production'
    ? false
    : {
        warnAfter: WARNING_DELAY,
        // Used for passing File to Batch Generate in navigator.
        ignoredActions: ['@@router/LOCATION_CHANGE'],
        // ignoredActionPaths: ['payload.location.state.file'],
        ignoredPaths: ['router.location.state.file'],
      };

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: modelDialogLocalStorageMatcher,
  effect: updateModelDialogLocalStorage,
});

// Don't forget to update test/utils/helpers.ts when making changes! -md
const Store = configureStore({
  reducer: {
    builder: builderDuck.reducer,
    projects: projects.reducer,
    modelCompletionDialog: modelDialogSlice.reducer,
    upload: upload.reducer,
    useCasesWorkflow: useCasesWorkflowDuck.reducer,
    workflows: workflows.reducer,
    playground: playground.reducer,
    router,
    [GretelAPI.reducerPath]: GretelAPI.reducer,
    [PilotAPI.reducerPath]: PilotAPI.reducer,
    [LambdaAPI.reducerPath]: LambdaAPI.reducer,
    modelTemplates: modelTemplatesSlice.reducer,
    connectionWizard: connectionWizard.reducer,
    [tenantsDuck.name]: tenantsDuck.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: WARN_IN_DEV,
      serializableCheck: WARN_IN_DEV,
    }).prepend(
      listenerMiddleware.middleware,
      useCasesWorkflowMiddleware.middleware,
      routerMiddleware(history),
      GretelAPI.middleware,
      PilotAPI.middleware,
      LambdaAPI.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
});
setupListeners(Store.dispatch);

export { history };
export default Store;
