import { Connection } from 'src/api/pilot/connections';
import { AuthStrategy, S3Connection, S3FormState } from './types';

export const formToApi = (form: S3FormState): Partial<Connection> => {
  const transformed: Partial<Connection> = {
    name: form.name,
    type: form.type,
    connection_target_type: form.connection_target_type,
    auth_strategy: form.auth_strategy,
  };

  switch (form.auth_strategy) {
    case AuthStrategy.ACCESS_KEY:
      transformed.config = {
        access_key_id: form.access_key_id,
      };
      transformed.credentials = {
        secret_access_key: form.secret_access_key,
      };
      break;
    case AuthStrategy.ROLE_ARN:
      transformed.config = {
        role_arn: form.role_arn,
      };
      break;
  }

  return transformed;
};

export const apiToForm = (connection: Partial<Connection>): S3FormState => {
  // TS not happy about the nested objects in Connection
  // being type 'object'
  const conn = connection as S3Connection;
  const transformed: S3FormState = {
    name: conn.name,
    type: 's3',
    connection_target_type: conn.connection_target_type,
    auth_strategy:
      conn.auth_strategy ??
      (conn.config?.role_arn ? AuthStrategy.ROLE_ARN : AuthStrategy.ACCESS_KEY),
    access_key_id:
      conn.config?.access_key_id ?? conn.credentials?.access_key_id,
    secret_access_key: conn.credentials?.secret_access_key,
    role_arn: conn.config?.role_arn,
  };

  return transformed;
};
