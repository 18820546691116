import * as React from 'react';
import { SvgIcon } from '@mui/material';
import AnimatedLoader from 'src/resources/AnimatedLoader.svg';

export type LoaderProps = Partial<React.SVGProps<SVGElement>>;

const Loader = ({ ...props }: LoaderProps) => {
  return (
    <SvgIcon
      sx={{
        width: '128px',
        height: '128px',
        '& .primary_fill': {
          fill: theme => theme.palette.grey[600],
        },
      }}
    >
      <AnimatedLoader
        role="progressbar"
        aria-labelledby="Content is loading"
        aria-busy="true"
        aria-live="polite"
        {...props}
      />
    </SvgIcon>
  );
};

export default Loader;
