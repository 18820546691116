import { Components, formLabelClasses, Theme } from '@mui/material';

const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2Strong,
      // By default MUI applies `primary.main` when focused, override that
      [`&.${formLabelClasses.focused}`]: {
        color: theme.palette.text.primary,
      },
    }),
  },
};

export default MuiFormLabel;
