import * as React from 'react';

export type ThemeMode = 'light' | 'dark' | 'auto';

type ThemeModeContextState = {
  mode: ThemeMode;
  setMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
};

const INITIAL_STATE: ThemeModeContextState = {
  mode: 'light', // TODO: This initial state is currently being ignored
  setMode: mode => mode,
};

export const ThemeModeContext =
  React.createContext<ThemeModeContextState>(INITIAL_STATE);
