import {
  DefaultPrivacyLevel,
  RumInitConfiguration,
} from '@datadog/browser-rum';
import { beforeSend } from 'common/dataDog';

// https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
const buildConfig = () => {
  if (!process.env.DATADOG_APP_ID || !process.env.DATADOG_CLIENT_TOKEN) {
    // note: we don't run datadog in local or branch previews, so won't have keys there.
    return;
  }

  const config: RumInitConfiguration = {
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com', // the datadog domain our app metrics are collected in.
    // Not currently provided by vercel, only for local development.
    service: 'console',
    env: process.env.DATADOG_ENV || 'unknown',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.VERCEL_GIT_COMMIT_SHA || '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0, // if not included, the default is 100
    trackResources: false,
    trackLongTasks: true,
    trackUserInteractions: false,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    beforeSend,
  };

  // Disabling this until we update the access-control-allow-headers for this
  // if (process.env.BACKEND_URL) {
  //   config.allowedTracingUrls = [process.env.BACKEND_URL];
  // }

  return config;
};

export default buildConfig;
