import { api } from './baseApi';

export const addTagTypes = ['NavigatorPrompts'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getNavigatorPrompts: build.query<
        GetNavigatorPromptsApiResponse,
        GetNavigatorPromptsApiArg
      >({
        query: () => ({ url: `/v1/navigator-prompts` }),
        providesTags: ['NavigatorPrompts'],
      }),
      createNavigatorPrompt: build.mutation<
        CreateNavigatorPromptApiResponse,
        CreateNavigatorPromptApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts`,
          method: 'POST',
          body: queryArg.createNavigatorPromptRequest,
        }),
        invalidatesTags: ['NavigatorPrompts'],
      }),
      searchNavigatorPrompts: build.query<
        SearchNavigatorPromptsApiResponse,
        SearchNavigatorPromptsApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts/search`,
          params: {
            query: queryArg.query,
            sort: queryArg.sort,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ['NavigatorPrompts'],
      }),
      getNavigatorPrompt: build.query<
        GetNavigatorPromptApiResponse,
        GetNavigatorPromptApiArg
      >({
        query: queryArg => ({ url: `/v1/navigator-prompts/${queryArg.id}` }),
        providesTags: ['NavigatorPrompts'],
      }),
      deleteNavigatorPrompt: build.mutation<
        DeleteNavigatorPromptApiResponse,
        DeleteNavigatorPromptApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['NavigatorPrompts'],
      }),
      updateNavigatorPrompt: build.mutation<
        UpdateNavigatorPromptApiResponse,
        UpdateNavigatorPromptApiArg
      >({
        query: queryArg => ({
          url: `/v1/navigator-prompts/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateNavigatorPromptRequest,
        }),
        invalidatesTags: ['NavigatorPrompts'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as NavigatorPromptsAPI };
export type GetNavigatorPromptsApiResponse =
  /** status 200 OK */ GetNavigatorPromptsResponse;
export type GetNavigatorPromptsApiArg = void;
export type CreateNavigatorPromptApiResponse =
  /** status 200 OK */ NavigatorPrompt;
export type CreateNavigatorPromptApiArg = {
  createNavigatorPromptRequest: CreateNavigatorPromptRequest;
};
export type SearchNavigatorPromptsApiResponse =
  /** status 200 OK */ SearchNavigatorPromptsResponse;
export type SearchNavigatorPromptsApiArg = {
  /** The query to search for. */
  query?: string;
  /** The sort order for the search results. */
  sort?: string;
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
};
export type GetNavigatorPromptApiResponse =
  /** status 200 OK */ NavigatorPrompt;
export type GetNavigatorPromptApiArg = {
  /** The id of the navigator prompt to get. */
  id: string;
};
export type DeleteNavigatorPromptApiResponse = /** status 200 OK */ string;
export type DeleteNavigatorPromptApiArg = {
  /** The id of the navigator prompt to delete. */
  id: string;
};
export type UpdateNavigatorPromptApiResponse =
  /** status 200 OK */ NavigatorPrompt;
export type UpdateNavigatorPromptApiArg = {
  /** The id of the navigator prompt to update. */
  id: string;
  updateNavigatorPromptRequest: UpdateNavigatorPromptRequest;
};
export type NavigatorPrompt = {
  /** A unique id for the navigator prompt, created by Gretel. */
  id: string;
  /** The name of the prompt. */
  name?: string;
  /** The body of the prompt. */
  body: string;
  /** The type of prompt. */
  type: Type;
  /** The user id of the user who created the prompt. */
  created_by: string;
  /** A timestamp of when the prompt was created. */
  created_at: string;
  /** A timestamp of when the prompt was last updated. */
  updated_at: string;
};
export type GetNavigatorPromptsResponse = {
  /** A list of navigator prompts for the current user. */
  prompts: NavigatorPrompt[];
};
export type GoogleProtobufAny = {
  /** The type of the serialized message. */
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  /** The status code, which should be an enum value of [google.rpc.Code][google.rpc.Code]. */
  code?: number;
  /** A developer-facing error message, which should be in English. Any user-facing error message should be localized and sent in the [google.rpc.Status.details][google.rpc.Status.details] field, or localized by the client. */
  message?: string;
  /** A list of messages that carry the error details.  There is a common set of message types for APIs to use. */
  details?: GoogleProtobufAny[];
};
export type CreateNavigatorPromptRequest = {
  /** The body of the prompt. */
  body: string;
  /** The name of the prompt. */
  name?: string;
  /** The type of prompt. */
  type: Type;
};
export type SearchNavigatorPromptsResponse = {
  /** A list of navigator prompts that match the search query. */
  prompts: NavigatorPrompt[];
  total: number;
};
export type UpdateNavigatorPromptRequest = {
  /** The new body of the prompt. */
  body?: string;
  /** The new name of the prompt. */
  name?: string;
  /** The new type of prompt. */
  type?: Type;
};
export enum Type {
  PromptTypeGpt = 'PROMPT_TYPE_GPT',
  PromptTypeTabular = 'PROMPT_TYPE_TABULAR',
}
export const {
  useGetNavigatorPromptsQuery,
  useCreateNavigatorPromptMutation,
  useSearchNavigatorPromptsQuery,
  useGetNavigatorPromptQuery,
  useDeleteNavigatorPromptMutation,
  useUpdateNavigatorPromptMutation,
} = injectedRtkApi;
