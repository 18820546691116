import bytes from 'bytes';
import Numbro from 'numbro';

export const NumberFormatter = {
  percent: (num = 0, { smart = true } = {}) =>
    `${smart ? Math.round(num <= 1 ? num * 100 : num) : num.toFixed(2)}%`,
  bytes: (num: string | number = 0) =>
    bytes(typeof num === 'string' ? parseFloat(num) : num, {
      decimalPlaces: 2,
      thousandsSeparator: ',',
      unitSeparator: ' ',
    }),
  abbreviate: (num = 0) => {
    const precise: Numbro.Format = { thousandSeparated: true };
    const estimated: Numbro.Format = {
      thousandSeparated: true,
      average: true,
      mantissa: 1,
    };

    return Numbro(num).format(num > 100000 ? estimated : precise);
  },
  /**
   * @note
   * If `num` is NaN default won't be applied, return "—" (emdash).
   * If fractions is false, limit minimum fraction digits. */
  currency: (num = 0, fractions = true) =>
    isNaN(num)
      ? '\u2014'
      : num.toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: fractions ? undefined : 0,
        }),
};
