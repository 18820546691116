export enum USER_ACCESS_LEVEL {
  READ = 1,
  READ_WRITE = 2,
  ADMIN = 3,
  CO_OWNER = 4,
}

export const userAccessLabelMap = {
  [USER_ACCESS_LEVEL.READ]: 'Read-only',
  [USER_ACCESS_LEVEL.READ_WRITE]: 'Read/Write',
  [USER_ACCESS_LEVEL.ADMIN]: 'Administrator',
  [USER_ACCESS_LEVEL.CO_OWNER]: 'Co-Owner',
} as const;

export const getUserAccessLabel = (level: USER_ACCESS_LEVEL) =>
  userAccessLabelMap[level];

export const sortedUserAccessLevels = [
  USER_ACCESS_LEVEL.READ,
  USER_ACCESS_LEVEL.READ_WRITE,
  USER_ACCESS_LEVEL.ADMIN,
  USER_ACCESS_LEVEL.CO_OWNER,
] as const;
