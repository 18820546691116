import { Components, Theme } from '@mui/material';

const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(6),
      paddingTop: theme.spacing(3),
      justifyContent: 'flex-start',
    }),
    spacing: ({ theme }) => ({
      '& > :not(:first-of-type)': {
        marginLeft: theme.spacing(3),
      },
    }),
  },
};

export default MuiDialogActions;
