import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useEmailValidation = (props?: { debounce?: boolean; delay?: number }) => {
  const { debounce = false, delay = 500 } = props || {};
  // Email Regex
  const EMAIL_REGEX =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [isApproved, setIsApproved] = React.useState(false);

  const testRegex = (text: string) => {
    const isValidEmail = EMAIL_REGEX.test(text);
    setIsApproved(isValidEmail);
    setIsInvalid(!isValidEmail);
    return isValidEmail;
  };

  // Wait until 'delay' time has passed since function has been called.
  const debounced = useDebouncedCallback(testRegex, delay);

  // Debounce the input so not checking validation every type
  const validator = (text: string, force = false) => {
    if (text === '') {
      debounced.cancel();
      // If forcing a result then an empty value is invalid.
      if (force) {
        setIsInvalid(true);
        setIsApproved(false);
      }
      return false;
    } else {
      return debounce && !force ? debounced(text) : testRegex(text);
    }
  };

  return [validator, { error: isInvalid, valid: isApproved }] as const;
};

export default useEmailValidation;
