import { Color, CommonColors, PaletteColorOptions } from '@mui/material';

const common: Partial<CommonColors> = {
  white: 'hsl(0, 0%, 100%)',
  black: 'hsl(264, 13%, 15%)',
};

const primary: PaletteColorOptions = {
  50: 'hsl(246, 83%, 98%)',
  100: 'hsl(228, 77%, 95%)',
  200: 'hsl(231, 89%, 89%)',
  300: 'hsl(232, 81%, 79%)',
  400: 'hsl(234, 71%, 70%)',
  500: 'hsl(241, 68%, 59%)',
  600: 'hsl(245, 64%, 50%)',
  700: 'hsl(246, 74%, 43%)',
  800: 'hsl(246, 76%, 33%)',
  900: 'hsl(246, 100%, 23%)',
  950: 'hsl(251, 93%, 17%)',
};

const error: PaletteColorOptions = {
  50: 'hsl(0, 86%, 97%)',
  100: 'hsl(0, 93%, 94%)',
  200: 'hsl(0, 96%, 89%)',
  300: 'hsl(0, 94%, 82%)',
  400: 'hsl(0, 91%, 71%)',
  500: 'hsl(0, 84%, 60%)',
  600: 'hsl(0, 69%, 47%)',
  700: 'hsl(0, 74%, 40%)',
  800: 'hsl(0, 70%, 35%)',
  900: 'hsl(0, 63%, 31%)',
  950: 'hsl(10, 89%, 17%)',
};

const warning: PaletteColorOptions = {
  50: 'hsl(42, 100%, 98%)',
  100: 'hsl(48, 100%, 94%)',
  200: 'hsl(48, 97%, 87%)',
  300: 'hsl(48, 97%, 77%)',
  400: 'hsl(46, 97%, 65%)',
  500: 'hsl(43, 96%, 56%)',
  600: 'hsl(38, 93%, 48%)',
  700: 'hsl(32, 94%, 35%)',
  800: 'hsl(26, 91%, 25%)',
  900: 'hsl(23, 82%, 20%)',
  950: 'hsl(22, 83%, 14%)',
};

const info: PaletteColorOptions = {
  50: 'hsl(196, 100%, 97%)',
  100: 'hsl(200, 87%, 94%)',
  200: 'hsl(200, 84%, 90%)',
  300: 'hsl(200, 86%, 80%)',
  400: 'hsl(200, 90%, 70%)',
  500: 'hsl(200, 80%, 60%)',
  600: 'hsl(200, 80%, 50%)',
  700: 'hsl(200, 80%, 40%)',
  800: 'hsl(200, 80%, 30%)',
  900: 'hsl(200, 100%, 20%)',
  950: 'hsl(200, 100%, 13%)',
};

const success: PaletteColorOptions = {
  50: 'hsl(164, 100%, 97%)',
  100: 'hsl(161, 68%, 93%)',
  200: 'hsl(162, 63%, 87%)',
  300: 'hsl(163, 59%, 76%)',
  400: 'hsl(165, 51%, 54%)',
  500: 'hsl(168, 69%, 37%)',
  600: 'hsl(171, 81%, 27%)',
  700: 'hsl(177, 88%, 20%)',
  800: 'hsl(184, 95%, 17%)',
  900: 'hsl(193, 97%, 12%)',
  950: 'hsl(184, 94%, 7%)',
};

const grey: Omit<Color, 'A100' | 'A200' | 'A400' | 'A700'> = {
  50: 'hsl(240, 60%, 99%)',
  100: 'hsl(240, 20%, 97%)',
  200: 'hsl(250, 17%, 93%)',
  300: 'hsl(236, 17%, 84%)',
  400: 'hsl(246, 14%, 73%)',
  500: 'hsl(249, 9%, 57%)',
  600: 'hsl(254, 8%, 41%)',
  700: 'hsl(254, 10%, 34%)',
  800: 'hsl(259, 12%, 26%)',
  900: 'hsl(264, 13%, 15%)',
  950: 'hsl(264, 8%, 10%)',
};

export { common, error, grey, info, primary, success, warning };
