import { Components, darken, Theme } from '@mui/material';

const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    target: '_blank',
    rel: 'noopener',
    variant: 'button2',
    underline: 'hover',
  },
  variants: [
    {
      props: { variant: 'button2' },
      style: ({ theme }) => ({
        textTransform: 'none',
        height: 'auto', // Escape 'Button' height for Typography height
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        '& svg': {
          color: 'inherit',
        },
        ':hover': {
          color: darken(
            theme.palette.primary.main,
            theme.palette.action.hoverOpacity
          ),
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          '& svg': {
            color: 'inherit',
          },
        },
        ':active': {
          color: darken(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity
          ),
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          '& svg': {
            color: 'inherit',
          },
        },
        ':disabled': {
          backgroundColor: 'transparent',
        },
      }),
    },
  ],
};

export default MuiLink;
