import { useMemo } from 'react';
import {
  useEnterpriseProjectManagement,
  useOnlyHybridFeature,
} from 'common/featureFlags';
import { useLoadBillingQuery, useLoadProfileQuery } from 'src/api';
import { UserPolicies } from 'src/api/types/User';
import { useHybridEnabled } from 'src/Console/Projects/util';
import { ORGANIZATION_SETTINGS, Route } from 'src/routes';

type UseUserPolicies = {
  policies: UserPolicies;
  isLoading: boolean;
};

const DEFAULT_POLICIES: UserPolicies = {
  hybrid_only: false,
  restrict_create_project: false,
  restrict_external_project_access: false,
};

export const useUserPolicies = (): UseUserPolicies => {
  const { data, isLoading: isLoadingProfile } = useLoadProfileQuery();
  const { value: onlyHybridFeature, loading: isOnlyHybridFeatureLoading } =
    useOnlyHybridFeature();

  const isLoading = isLoadingProfile || isOnlyHybridFeatureLoading;

  const policies = useMemo(() => {
    return {
      hybrid_only:
        (onlyHybridFeature || data?.policies?.hybrid_only) ??
        DEFAULT_POLICIES.hybrid_only,
      restrict_create_project:
        data?.policies?.restrict_create_project ??
        DEFAULT_POLICIES.restrict_create_project,
      restrict_external_project_access:
        data?.policies?.restrict_external_project_access ??
        DEFAULT_POLICIES.restrict_external_project_access,
    };
  }, [data?.policies, onlyHybridFeature]);

  const response = useMemo(
    () => ({ policies, isLoading }),
    [policies, isLoading]
  );

  return response;
};

/**
 * This is a custom hook that returns the organization settings routes
 * for the logged in user based on a combination of feature flags and
 * user permissions.
 *
 * @returns An array of organization settings routes
 */
export const useOrgSettingsRoutes = (): Route[] => {
  const hybridEnabled = useHybridEnabled();
  const { value: isEnterpriseProjectManagementEnabled } =
    useEnterpriseProjectManagement();

  const { data: billingData } = useLoadBillingQuery();

  const isDomainOwner = billingData?.me?.domain_owner ?? false;

  return useMemo(
    () =>
      ORGANIZATION_SETTINGS.filter(({ title }) => {
        if (
          // Filter out settings page if hybrid is disabled
          !hybridEnabled &&
          // or enterprise project management is disabled
          !isEnterpriseProjectManagementEnabled
        ) {
          return title !== 'Settings';
        }
        return true;
      }).filter(({ title }) => {
        // Filter out projects page if they are not a domain owner or
        // enterprise project management is disabled
        if (!isDomainOwner || !isEnterpriseProjectManagementEnabled) {
          return title !== 'Projects';
        }
        return true;
      }),
    [hybridEnabled, isEnterpriseProjectManagementEnabled, isDomainOwner]
  );
};
