import { Box, Theme } from '@mui/material';
import styled from '@mui/system/styled';

export const StealthInputWrapper = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    '.MuiOutlinedInput-root': {
      borderRadius: theme.shape.radii.medium,
      height: 'unset',
      backgroundColor: 'transparent',
      '&:hover, &:active, &:focus': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[600],
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        transition: 'all 0.15s ease-out',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[600],
        borderWidth: 1,
        boxShadow: theme.palette.action.focusBoxShadow,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        boxShadow: theme.palette.action.errorBoxShadow,
      },
      '.MuiOutlinedInput-input': {
        backgroundColor: 'transparent',
        height: 'unset',
        '&::placeholder': {
          color: theme.palette.grey[500],
          opacity: 1,
        },
      },
    },
  })
);
