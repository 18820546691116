import { Components, Theme } from '@mui/material';

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      borderRadius: theme.shape.radii.medium,
      lineHeight: theme.spacing(6),
      transition: 'all 0.15s ease-out',
    }),
    input: ({ theme }) => ({
      padding: theme.spacing(2, 3),
      height: 'auto',
    }),
    adornedStart: {
      '.MuiInputBase-input': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};

export default MuiInputBase;
