import { ConnectionTypes } from 'src/api/pilot';
import {
  ConnectionTargetType,
  ConnectionTargetTypeBoth,
} from 'src/Console/Connections/utils';

export enum AuthStrategy {
  ACCESS_KEY = 'access_key',
  ENTRA_ID = 'entra_id',
  SAS_TOKEN = 'sas_token',
}

export type AzureConnection = {
  name: string;
  type: 'azure';
  credentials?: ConnectionTypes['AzureStorageCredentials'];
  config: ConnectionTypes['AzureStorageConfig'];
  auth_strategy: AuthStrategy;
  connection_target_type: ConnectionTargetType | ConnectionTargetTypeBoth;
};

export type AzureFormState = {
  name: string;
  type: 'azure';
  connection_target_type: ConnectionTargetType | ConnectionTargetTypeBoth;
  auth_strategy: AuthStrategy;
} & AzureConnection['credentials'] &
  Omit<AzureConnection['config'], 'entra_config'> &
  AzureConnection['config']['entra_config'];

export type AzureConnectionErrors = Partial<{
  [K in keyof AzureFormState]: string;
}>;
