import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'src/Console/App';
import 'src/resources/fonts/Inter/inter.css';
import 'src/resources/fonts/ApercuPro/apercu-pro.css';
import 'src/resources/fonts/SourceCodePro/source-code-pro.css';

const mountNode = document.getElementById('root');
if (!mountNode) {
  throw new Error('mountNode not found!');
}

const root = createRoot(mountNode);
root.render(<App />);
