import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import {
  getUserAccessLabel,
  sortedUserAccessLevels,
  USER_ACCESS_LEVEL,
} from 'constants/userAccessLevel';
import { ChevronDown } from 'icons/figma';

export type PermissionMenuProps = Omit<ButtonProps, 'onChange'> & {
  value: number;
  onChange: (event: React.MouseEvent, value: number) => void;
  onRemove?: () => void;
  remove?: boolean;
  leave?: boolean;
  maxLevel?: USER_ACCESS_LEVEL;
};

const PermissionMenu = React.forwardRef<HTMLButtonElement, PermissionMenuProps>(
  (
    {
      value,
      onChange,
      onRemove = () => null,
      remove = false,
      leave = false,
      maxLevel = USER_ACCESS_LEVEL.CO_OWNER,
      ...buttonProps
    },
    ref
  ) => {
    const popupState = usePopupState({
      variant: 'popover',
      popupId: 'userPermissionMenu',
    });
    const handleClick = (event: React.MouseEvent, value: number) => {
      onChange(event, value);
      popupState.close();
    };

    const handleRemove = () => {
      onRemove();
      popupState.close();
    };

    let menuItems: Array<React.ReactElement> = [];
    if (leave) {
      menuItems.push(
        <MenuItem
          color="error"
          key="PermissionMenuLeave"
          onClick={handleRemove}
        >
          Leave project
        </MenuItem>
      );
    } else {
      menuItems = sortedUserAccessLevels
        .filter(level => level <= maxLevel)
        .map(level => (
          <MenuItem
            key={level}
            value={level}
            selected={level === value}
            onClick={event => handleClick(event, level)}
          >
            {getUserAccessLabel(level)}
          </MenuItem>
        ));
      if (remove) {
        menuItems = menuItems.concat([
          <Divider key="PermissionMenuDivider" />,
          <MenuItem
            color="error"
            key="PermissionMenuRemove"
            onClick={handleRemove}
          >
            Remove
          </MenuItem>,
        ]);
      }
    }

    return (
      <Box sx={{ overflow: 'visible !important' }}>
        <Button
          variant="text"
          size="small"
          endIcon={<ChevronDown />}
          ref={ref}
          name="level"
          {...buttonProps}
          {...bindTrigger(popupState)}
        >
          {getUserAccessLabel(value)}
        </Button>
        <Menu {...bindMenu(popupState)} disableAutoFocusItem>
          {menuItems}
        </Menu>
      </Box>
    );
  }
);

PermissionMenu.displayName = 'PermissionMenu';

export default PermissionMenu;
