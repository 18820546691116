import { PostgresFormState } from './types';

// Setting the default values for the form
export const initialPostgresForm: PostgresFormState = {
  name: '',
  type: 'postgres',
  credentials: {
    password: '',
  },
  connection_target_type: 'source',
  config: {
    username: '',
    host: '',
    port: 5432,
    database: '',
    schema: '',
    params: '',
  },
};
