import * as React from 'react';
import { Components, darken, Theme } from '@mui/material';
import RadioCheckedIcon from '../icons/Radio/Checked.svg';
import RadioIcon from '../icons/Radio/Radio.svg';

const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    icon: <RadioIcon />,
    checkedIcon: <RadioCheckedIcon />,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      // Color affects the "border" color here.
      color: theme.palette.grey[500],
      ':hover': {
        color: darken(
          theme.palette.grey[500],
          theme.palette.action.hoverOpacity
        ),
      },
      '&.Mui-focusVisible svg': {
        boxShadow: theme.palette.action.focusBoxShadow,
        zIndex: theme.zIndex.tooltip,
        borderRadius: '50%',
      },
      '&.Mui-checked': {
        fill: theme.palette.primary.main,
        '& .iconCenter': {
          fill: theme.palette.background.default,
        },
        ':hover .iconBorder': {
          fill: darken(
            theme.palette.primary.main,
            theme.palette.action.hoverOpacity
          ),
        },
      },
      '&.Mui-disabled': {
        color: theme.palette.border.strong,
        '& svg': {
          fill: theme.palette.border.default,
        },
      },
    }),
  },
};

export default MuiRadio;
