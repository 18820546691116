import { Components, Theme } from '@mui/material';

const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(3, 6),
      ...theme.typography.body2,
    }),
  },
};

export default MuiDialogContent;
