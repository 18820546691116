import * as React from 'react';
import { Box, Drawer, Grid, styled } from '@mui/material';
import { DRAWER_WIDTH } from 'common/constants/layouts';
import SidenavGradient from 'src/resources/SidenavGradient.svg';
import { useSidebarStatus } from '../../SidebarStatusContext';

const StyledGradientWrapper = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  height: 270,
  zIndex: -1,
}));

const AppSidebarLayout = ({ children }: { children: React.ReactNode }) => {
  const { isSidebarOpen } = useSidebarStatus();
  return (
    <Drawer
      id="sidebar"
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      PaperProps={{
        sx: {
          border: 'none',
        },
      }}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          px: 4,
          visibility: 'visible',
          position: 'relative',
          width: DRAWER_WIDTH,
          height: '100%',
          backgroundColor: 'background.secondary',
          borderRight: 1,
          borderRightColor: 'border.default',
          ':hover #sidenav-toggle-button': {
            visibility: 'visible',
          },
        }}
      >
        {children}
      </Grid>
      <StyledGradientWrapper>
        <SidenavGradient />
      </StyledGradientWrapper>
    </Drawer>
  );
};

export default AppSidebarLayout;
