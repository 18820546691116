import { createAsyncThunk } from '@reduxjs/toolkit';
import { GretelAPI } from '../GretelAPI';
import { LambdaAPI } from '../lambda';
import { PilotAPI } from '../pilot';

export const resetAllApiState = createAsyncThunk(
  'resetAllApiState',
  async (_, { dispatch }) => {
    dispatch(PilotAPI.util.resetApiState());
    dispatch(GretelAPI.util.resetApiState());
    dispatch(LambdaAPI.util.resetApiState());
  }
);
