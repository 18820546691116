import toast from 'react-hot-toast';
import { datadogRum } from '@datadog/browser-rum';
import { createApi } from '@reduxjs/toolkit/query/react';
import Pako from 'pako';
import { identify } from 'common/analytics';
import { FROM_SCRATCH_CARD, NAVIGATOR_CARD } from 'common/constants/blueprints';
import localStorageHelper from 'common/localStorageHelper';
import { ToastAlert } from 'common/Toasts/Toast';
import uploadDuck from 'common/Upload/uploadDuck';
import { FileStats } from 'common/utils/data';
import Formatters from 'common/utils/formatters';
import { DateFormatter } from 'common/utils/formatters/date';
import { clearToken } from 'common/utils/token';
import { prefillZenDeskWidget, showZenDeskWidget } from 'common/utils/zenDesk';
import projectsDuck from 'src/Console/Projects/projectsDuck';
import { useCasesWorkflowDuck } from 'src/Console/UseCases/useCasesWorkflowDuck';
import workflowDuck from 'src/Console/Workflows/duck';
import {
  DASHBOARD_ROUTE,
  getUseCaseDetailsPath,
  GRETEL_BLUEPRINTS_URL,
  LOGIN_ROUTE,
  MODEL_TYPES_CONFIG,
  PLAYGROUND_EXAMPLE_PROMPT_URL,
  USE_CASE_CONFIG,
} from 'src/routes';
import { fetchRemote } from 'utils/fetch';
import {
  GetModelArtifacts,
  GetRecordHandlerArtifacts,
  ProjectArtifact,
  ProjectArtifactUrl,
  ProjectArtifactWithManifest,
  RecordHandlerArtifactType,
} from './types/Artifact';
import { BillingData, BillingSummary } from './types/Billing';
import {
  FormattedPromptResponse,
  ModelTypeBlueprint,
  UseCaseBlueprint,
} from './types/Blueprint';
import { YesOrNo } from './types/common';
import { UserInvite } from './types/Invites';
import { Log } from './types/Log';
import {
  CreateProjectInviteParams,
  GetProjectMembersParams,
  ProjectInvite,
  ProjectMember,
} from './types/Members';
import {
  GetModelParams,
  GetModelResponse,
  GetModelsParams,
  GetModelsResponse,
  GetModelsTransformed,
  InferenceModel,
  Model,
  ModelConfig,
  ModelMode,
  RunnerMode,
} from './types/Model';
import {
  CreateRecordHandlerParams,
  GetModelRunParams,
  GetModelRunsParams,
  ModelRun,
  UpdateRecordHandlerParams,
} from './types/ModelRuns';
import {
  InferenceStreamIterate,
  InferenceStreamIterateTransformed,
} from './types/PlaygroundInference';
import {
  GetProjectArgs,
  GetProjectsParams,
  Project,
  ProjectCreateOrPatch,
} from './types/Project';
import { GetTeamResponse, UpdateTeamPoliciesParams } from './types/Team';
import { UpdateUserParams, User } from './types/User';
import { tenantBaseQuery } from './utils/tenantBaseQuery';
import { fetchAndProcessFile, formatPlaygroundExamplePrompts } from './utils';

type ApiResponse<T> = { data: T };

// Keyed by model uid, artifact type.
const modelArtifacts = {};

export const getModelArtifacts = () => modelArtifacts;

// Keyed by model uid, artifact type.
const modelRecordHandlerArtifacts = {};

export const getModelRecordHandlerArtifacts = () => modelRecordHandlerArtifacts;

enum TAGTYPES {
  CONNECTIONS_API = 'ConnectionsApi',
  DATA_SOURCES = 'DataSources',
  DATA_SOURCE_MANIFEST = 'DataSourceManifest',
  DATA_SOURCE_URL = 'DataSourceUrl',
  LICENSES = 'Licenses',
  MEMBERS = 'Members',
  MODELS = 'Models',
  PROJECTS = 'Projects',
  RECORD_HANDLERS = 'RecordHandlers',
  TEAM = 'Team',
  USE_CASES = 'UseCases',
  MODEL_TYPES = 'ModelTypes',
  USER = 'User',
}

/**
 * Called after `POST /users/me/logout`, this clears session cookies and localStorage, and refreshes the site.
 */
export const clearBrowserDataAndRedirect = () => {
  clearToken();

  // clean up any local storage data since the user is intentionally logging out
  localStorageHelper.clear();

  // Need an actual page load for the cookie removal to take effect.
  window.location.replace(LOGIN_ROUTE.path);
};

export const GretelAPI = createApi({
  baseQuery: tenantBaseQuery(),
  refetchOnMountOrArgChange: 5 * 60, // Refetch cached info every five minutes
  reducerPath: 'GretelAPI',
  tagTypes: [
    TAGTYPES.CONNECTIONS_API,
    TAGTYPES.DATA_SOURCES,
    TAGTYPES.DATA_SOURCE_MANIFEST,
    TAGTYPES.DATA_SOURCE_URL,
    TAGTYPES.LICENSES,
    TAGTYPES.MEMBERS,
    TAGTYPES.MODELS,
    TAGTYPES.MODEL_TYPES,
    TAGTYPES.PROJECTS,
    TAGTYPES.RECORD_HANDLERS,
    TAGTYPES.TEAM,
    TAGTYPES.USE_CASES,
    TAGTYPES.USER,
  ],
  endpoints: build => ({
    /*
     *      Projects
     */
    projects: build.query<
      Record<string, Project>,
      GetProjectsParams | undefined
    >({
      queryFn: async (
        {
          skip = 0,
          limit = 100,
          withMembers = false,
          withModels = false,
          withArtifacts = false,
          ownedByDomain = false,
          query = undefined,
        } = {},
        _,
        __,
        baseQuery
      ) => {
        const expand: string[] = [];
        if (withMembers) {
          expand.push('members');
        }
        if (withModels) {
          expand.push('models');
        }
        if (withArtifacts) {
          expand.push('artifacts');
        }
        const projects: Project[] = [];
        let responseData: Project[];
        let page = 0;
        try {
          do {
            const { data, error } = await baseQuery({
              url: 'projects',
              params: {
                skip: projects.length ? skip + limit * page : skip,
                limit: withMembers ? limit : 999,
                expand: expand.join(',') || undefined,
                owned_by: ownedByDomain ? 'domain' : undefined,
                query,
              },
            });
            if (error) {
              return { error, data };
            }
            responseData =
              (data as ApiResponse<{ projects: Project[] }>).data.projects ||
              [];
            projects.push(...responseData);
            page++;
          } while (withMembers && responseData.length === limit);
        } catch (error) {
          return { error };
        }
        return {
          data: projects.reduce(
            (all, project) => {
              all[project.guid] = project;
              return all;
            },
            {} as Record<string, Project>
          ),
        };
      },
      providesTags: (result = {}, error, { withMembers = false } = {}) => [
        ...Object.keys(result).map(id => ({
          type: TAGTYPES.PROJECTS as const,
          id,
        })),
        { type: TAGTYPES.PROJECTS, id: withMembers ? 'LIST+MEMBERS' : 'LIST' },
      ],
    }),
    project: build.query<Project, GetProjectArgs>({
      query: ({ guid, expand }) => {
        return {
          url: `projects/${guid}`,
          params: { expand },
        };
      },
      transformResponse: (response: ApiResponse<{ project: Project }>) =>
        response.data.project,
    }),
    createProject: build.mutation<
      { id: string; guid: string },
      ({ background?: boolean } & ProjectCreateOrPatch) | void
    >({
      query: ({ background, ...projectParams } = {}) => ({
        url: 'projects',
        method: 'POST',
        body: projectParams,
      }),
      transformResponse: (
        response: ApiResponse<{ id: string; guid: string }>
      ) => response.data,
      invalidatesTags: response => [
        { type: TAGTYPES.PROJECTS, id: response?.id },
        { type: TAGTYPES.PROJECTS, id: 'LIST' },
        { type: TAGTYPES.PROJECTS, id: 'LIST+MEMBERS' },
      ],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            toast.success('Project created.');
          })
          .catch(r => {
            toast.error('There was a problem creating a new project');
            return r;
          });
      },
    }),
    updateProject: build.mutation<
      Project,
      Pick<Project, 'guid'> & Partial<Omit<Project, 'guid'>>
    >({
      query: ({ guid, ...projectParams }) => ({
        url: `projects/${guid}`,
        method: 'PATCH',
        body: projectParams,
      }),
      transformResponse: (response: ApiResponse<{ project: Project }>) =>
        response?.data?.project,
      invalidatesTags: (result, error, { guid }) => [
        { type: TAGTYPES.PROJECTS, id: guid },
        { type: TAGTYPES.PROJECTS, id: 'LIST' },
        { type: TAGTYPES.PROJECTS, id: 'LIST+MEMBERS' },
      ],
      onQueryStarted: (
        { guid, ...projectParams },
        { dispatch, queryFulfilled }
      ) => {
        const projectUpdate = dispatch(
          GretelAPI.util.updateQueryData('project', { guid: guid }, project => {
            Object.assign(project, projectParams);
          })
        );
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('projects', undefined, projects => ({
            ...projects,
            [guid]: { ...projects[guid], ...projectParams },
          }))
        );
        queryFulfilled
          .then(() => {
            toast.success('Project updated.');
          })
          .catch(r => {
            projectUpdate.undo();
            listUpdate.undo();
            toast.error('There was a problem updating the project');
            return r;
          });
      },
    }),
    deleteProject: build.mutation<Project, { guid: string }>({
      query: ({ guid }) => ({
        url: `projects/${guid}`,
        method: 'DELETE',
      }),
      transformResponse: (response: ApiResponse<{ project: Project }>) =>
        response?.data?.project,
      invalidatesTags: (result, error, { guid }) => [
        { type: TAGTYPES.PROJECTS, id: guid },
        { type: TAGTYPES.PROJECTS, id: 'LIST' },
        { type: TAGTYPES.PROJECTS, id: 'LIST+MEMBERS' },
      ],
      onQueryStarted: async (
        { guid, ...patch },
        { dispatch, queryFulfilled }
      ) => {
        const projectUpdate = dispatch(
          GretelAPI.util.updateQueryData(
            'project',
            { guid: guid },
            () => undefined
          )
        );
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('projects', undefined, draft =>
            Object.assign(draft, patch)
          )
        );
        try {
          await queryFulfilled;
          toast.success('Project deleted.');
        } catch {
          projectUpdate.undo();
          listUpdate.undo();
          toast.error('There was a problem deleting the project');
        }
      },
    }),
    /*
     *      Models
     */
    models: build.query<GetModelsTransformed, GetModelsParams>({
      query: ({
        guid,
        skip = 0,
        limit = 999,
        status,
        model_name,
        model_type,
        sort_field,
        sort_by = 'desc',
        expand,
      }) => ({
        url: `projects/${guid}/models`,
        params: {
          skip,
          limit,
          status,
          model_name,
          model_type,
          sort_field,
          sort_by,
          expand,
        },
      }),
      transformResponse: (response: ApiResponse<GetModelsResponse>) => ({
        models: (response.data.models || []).reduce(
          (all, model) => {
            all[model.uid] = model;
            return all;
          },
          {} as Record<string, Model>
        ),
        total: response.data.total,
      }),
      providesTags: (result, error, { guid }) => [
        ...Object.keys(result?.models ?? {}).map(uid => ({
          type: TAGTYPES.MODELS as const,
          id: uid,
        })),
        { type: TAGTYPES.MODELS, id: `${guid}-LIST` },
      ],
    }),
    model: build.query<Model & Omit<GetModelResponse, 'model'>, GetModelParams>(
      {
        query: ({ guid, uid, expand = 'logs,report', ...other }) => ({
          url: `projects/${guid}/models/${uid}`,
          params: { expand, ...other },
        }),
        transformResponse: ({
          data: { model, logs, ...rest },
        }: ApiResponse<GetModelResponse>) => ({
          ...rest,
          ...model,
          logs, // Must be after `model` spread or will get overwritten
        }),
        providesTags: (response, error, { guid }) => [
          {
            type: TAGTYPES.MODELS,
            id: response?.uid,
          },
          { type: TAGTYPES.MODELS, id: `${guid}-LIST` },
        ],
      }
    ),
    createModel: build.mutation<
      Model,
      { guid: string; runner_mode?: RunnerMode; dry_run: YesOrNo } & ModelConfig
    >({
      query: ({
        guid,
        runner_mode = RunnerMode.CLOUD,
        dry_run,
        ...modelConfig
      }) => ({
        url: `/projects/${guid}/models`,
        method: 'POST',
        params: { runner_mode, dry_run },
        body: modelConfig,
      }),
      transformResponse: (response: ApiResponse<{ model: Model }>) =>
        response.data.model,
      invalidatesTags: (response, _error, { guid }) => [
        { type: TAGTYPES.MODELS, id: response?.uid },
        { type: TAGTYPES.MODELS, id: `${guid}-LIST` },
        { type: TAGTYPES.USER, id: 'BILLING' },
      ],
    }),
    updateModel: build.mutation<
      Model,
      { guid: string; uid: string } & ModelConfig
    >({
      query: ({ guid, uid, ...modelConfig }) => ({
        url: `projects/${guid}/models/${uid}`,
        method: 'PATCH',
        body: modelConfig,
      }),
      transformResponse: (response: ApiResponse<{ model: Model }>) =>
        response.data.model,
      invalidatesTags: (result, error, { guid, uid }) => [
        { type: TAGTYPES.MODELS, id: uid },
        { type: TAGTYPES.MODELS, id: `${guid}-LIST` },
      ],
      onQueryStarted: (
        { guid, uid, ...modelConfig },
        { dispatch, queryFulfilled }
      ) => {
        const modelUpdate = dispatch(
          GretelAPI.util.updateQueryData(
            'model',
            { guid, uid, logs: YesOrNo.NO },
            model => {
              Object.assign(model, modelConfig);
            }
          )
        );
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('models', { guid }, models => ({
            ...models,
            [uid]: { ...models[uid], ...modelConfig },
          }))
        );
        queryFulfilled.catch(r => {
          modelUpdate.undo();
          listUpdate.undo();
          return r;
        });
      },
    }),
    deleteModel: build.mutation<void, { guid: string; uid: string }>({
      query: ({ guid, uid }) => ({
        url: `projects/${guid}/models/${uid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { guid, uid }) => [
        { type: TAGTYPES.MODELS, id: uid },
        { type: TAGTYPES.MODELS, id: `${guid}-LIST` },
      ],
      onQueryStarted: ({ guid, uid }, { dispatch, queryFulfilled }) => {
        const modelUpdate = dispatch(
          GretelAPI.util.updateQueryData(
            'model',
            { guid, uid, logs: YesOrNo.NO },
            () => undefined
          )
        );
        const modelUpdate2 = dispatch(
          GretelAPI.util.updateQueryData(
            'model',
            { guid, uid },
            () => undefined
          )
        );
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('models', { guid }, models => {
            delete models[uid];
          })
        );
        queryFulfilled.catch(r => {
          modelUpdate.undo();
          modelUpdate2.undo();
          listUpdate.undo();
          return r;
        });
      },
    }),
    modelArtifact: build.query<string | { url: string }, GetModelArtifacts>({
      /*
        Available Types:
          - model: tarball of the trained model // RESTRICTED
          - report: HTML report about the model
          - report_json: JSON report about the model
          - data_preview: CSV/JSON depending on data_source
       */
      queryFn: async (
        { guid, uid, type = 'report_json', download = true },
        api,
        extra,
        baseQuery
      ) => {
        try {
          const { data: response, error } = await baseQuery({
            url: `/projects/${guid}/models/${uid}/artifact`,
            params: { type, uncompressed: 'auto' },
          });
          if (error) {
            return { error };
          }
          const { url, uncompressed } = (
            response as ApiResponse<{ url: string; uncompressed: boolean }>
          ).data;
          if (!download) {
            return { data: { url } };
          }

          const artifactResponse = await fetchRemote(url);
          modelArtifacts[uid] = modelArtifacts[uid] || {};
          const artifact = uncompressed
            ? await artifactResponse.text()
            : Pako.inflate(
                new Uint8Array(await artifactResponse.arrayBuffer()),
                { to: 'string' }
              );
          modelArtifacts[uid][type] = artifact;
          return { data: type };
        } catch (error) {
          return { error };
        }
      },
    }),
    /*
     *      Record Handlers
     */
    recordHandlers: build.query<ModelRun[], GetModelRunsParams>({
      query: ({
        guid,
        modelId,
        skip = 0,
        limit = 999,
        status,
        sort_by = 'desc',
      }) => ({
        url: `projects/${guid}/models/${modelId}/record_handlers`,
        params: {
          skip,
          limit,
          status,
          sort_by,
        },
      }),
      transformResponse: (response: ApiResponse<{ handlers: ModelRun[] }>) =>
        response.data.handlers,
      providesTags: (result = [], error, { guid, modelId }) => [
        ...result.map(({ uid }) => ({
          type: TAGTYPES.RECORD_HANDLERS as const,
          id: uid,
        })),
        { type: TAGTYPES.RECORD_HANDLERS, id: `${guid}-${modelId}-LIST` },
      ],
    }),
    recordHandler: build.query<ModelRun, GetModelRunParams>({
      query: ({ guid, modelId, uid, logs = YesOrNo.YES }) => ({
        url: `projects/${guid}/models/${modelId}/record_handlers/${uid}`,
        params: { logs },
      }),
      transformResponse: (
        response: ApiResponse<{
          handler: ModelRun;
          logs: Log[];
          billing_data: BillingData;
        }>
      ) => ({
        ...response?.data,
        ...response?.data?.handler,
      }),
      providesTags: (response, error, { guid, modelId }) => [
        {
          type: TAGTYPES.RECORD_HANDLERS,
          id: response?.uid,
        },
        { type: TAGTYPES.RECORD_HANDLERS, id: `${guid}-${modelId}-LIST` },
      ],
    }),
    recordHandlerArtifact: build.query<
      RecordHandlerArtifactType | { url: string },
      GetRecordHandlerArtifacts
    >({
      queryFn: async (
        {
          guid,
          modelId,
          uid,
          type = RecordHandlerArtifactType.DATA,
          download = true,
        },
        api,
        extra,
        baseQuery
      ) => {
        try {
          const { data: response, error } = await baseQuery({
            url: `/projects/${guid}/models/${modelId}/record_handlers/${uid}/artifact`,
            params: { type, uncompressed: 'auto' },
          });
          if (error) {
            return { error };
          }
          const { url, uncompressed } = (
            response as ApiResponse<{ url: string; uncompressed: boolean }>
          ).data;
          if (!download) {
            return { data: { url } };
          }

          const artifactResponse = await fetchRemote(url);
          modelRecordHandlerArtifacts[uid] =
            modelRecordHandlerArtifacts[uid] || {};
          modelRecordHandlerArtifacts[uid][type] =
            modelRecordHandlerArtifacts[uid][type] || {};
          const artifact = uncompressed
            ? await artifactResponse.text()
            : Pako.inflate(await artifactResponse.arrayBuffer(), {
                to: 'string',
              });
          modelRecordHandlerArtifacts[uid][type] = artifact;
          return { data: type };
        } catch (error) {
          return { error };
        }
      },
    }),
    createRecordHandler: build.mutation<
      ModelRun & { worker_key: string },
      CreateRecordHandlerParams
    >({
      query: ({
        guid,
        modelId,
        runner_mode = 'inherit', // inherits the runner mode of model
        ...recordHandlerConfig
      }) => ({
        url: `/projects/${guid}/models/${modelId}/record_handlers`,
        method: 'POST',
        params: { runner_mode },
        body: recordHandlerConfig,
      }),
      transformResponse: (
        response: ApiResponse<{ worker_key: string; handler: ModelRun }>
      ) => ({
        worker_key: response?.data?.worker_key,
        ...response?.data?.handler,
      }),
      invalidatesTags: (response, error, { guid, modelId }) => [
        { type: TAGTYPES.RECORD_HANDLERS, id: response?.uid },
        { type: TAGTYPES.RECORD_HANDLERS, id: `${guid}-${modelId}-LIST` },
        { type: TAGTYPES.USER, id: 'BILLING' },
      ],
    }),
    updateRecordHandler: build.mutation<ModelRun, UpdateRecordHandlerParams>({
      query: ({ guid, modelId, uid, ...recordHandlerConfig }) => ({
        url: `projects/${guid}/models/${modelId}/record_handlers/${uid}`,
        method: 'PATCH',
        body: recordHandlerConfig,
      }),
      transformResponse: (response: ApiResponse<{ handler: ModelRun }>) =>
        response?.data?.handler || {},
      invalidatesTags: (result, error, { guid, modelId, uid }) => [
        { type: TAGTYPES.RECORD_HANDLERS, id: uid },
        { type: TAGTYPES.RECORD_HANDLERS, id: `${guid}-${modelId}-LIST` },
        { type: TAGTYPES.USER, id: 'BILLING' },
      ],
    }),
    deleteRecordHandler: build.mutation<void, UpdateRecordHandlerParams>({
      query: ({ guid, modelId, uid }) => ({
        url: `projects/${guid}/models/${modelId}/record_handlers/${uid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { guid, modelId, uid }) => [
        { type: TAGTYPES.RECORD_HANDLERS, id: uid },
        { type: TAGTYPES.RECORD_HANDLERS, id: `${guid}-${modelId}-LIST` },
      ],
      onQueryStarted: (
        { guid, modelId, uid },
        { dispatch, queryFulfilled }
      ) => {
        const handlerUpdate = dispatch(
          GretelAPI.util.updateQueryData(
            'recordHandler',
            { guid, modelId, uid, logs: YesOrNo.NO },
            () => undefined
          )
        );
        const handlerUpdate2 = dispatch(
          GretelAPI.util.updateQueryData(
            'recordHandlers',
            { guid, modelId },
            () => undefined
          )
        );
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData(
            'recordHandlers',
            { guid, modelId },
            handlers => handlers.filter(({ uid: testUid }) => testUid !== uid)
          )
        );
        queryFulfilled.catch(r => {
          handlerUpdate.undo();
          handlerUpdate2.undo();
          listUpdate.undo();
          return r;
        });
      },
    }),
    /*
     *      Members
     */
    members: build.query<
      Record<string, ProjectMember>,
      GetProjectMembersParams
    >({
      queryFn: async ({ guid, skip = 0, limit = 50 }, _, __, baseQuery) => {
        const projectMembers: ProjectMember[] = [];
        let responseData: ProjectMember[];
        let page = 0;
        try {
          do {
            const { data, error } = await baseQuery({
              url: `projects/${guid}/members`,
              params: {
                skip: projectMembers.length ? skip + limit * page : skip,
                limit,
              },
            });
            if (error) {
              return { error, data };
            }
            responseData = (data as ApiResponse<{ members: ProjectMember[] }>)
              .data.members;
            projectMembers.push(...responseData);
            page++;
          } while (responseData.length === limit);
        } catch (error) {
          return { error };
        }
        return {
          data: projectMembers.reduce(
            (all, member) => {
              all[member._id] = member;
              return all;
            },
            {} as Record<string, ProjectMember>
          ),
        };
      },
      providesTags: (result = {}, error, { guid: projectId }) => [
        ...Object.keys(result).map(memberId => ({
          type: TAGTYPES.MEMBERS as const,
          id: memberId,
        })),
        { type: TAGTYPES.MEMBERS, id: `${projectId}-LIST` },
      ],
    }),
    inviteMember: build.mutation<ProjectInvite, CreateProjectInviteParams>({
      query: ({ guid, ...invite }) => ({
        url: `/projects/${guid}/invites`,
        method: 'POST',
        body: invite,
      }),
      transformResponse: (response: ApiResponse<{ invite: ProjectInvite }>) =>
        response.data.invite,
      invalidatesTags: (response, error, { guid: projectId }) => [
        { type: TAGTYPES.MEMBERS, id: response?._id },
        { type: TAGTYPES.MEMBERS, id: `${projectId}-LIST` },
      ],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            toast.success('User Invited.');
          })
          .catch(r => r);
      },
    }),
    updateMember: build.mutation<
      ProjectInvite,
      CreateProjectInviteParams & { guid: string }
    >({
      query: ({ guid, email, level }) => ({
        url: `/projects/${guid}/invites`,
        method: 'POST',
        body: { email, level },
      }),
      transformResponse: (response: ApiResponse<{ invite: ProjectInvite }>) =>
        response.data.invite,
      invalidatesTags: (response, error, { guid: projectId }) => [
        { type: TAGTYPES.MEMBERS, id: response?._id },
        { type: TAGTYPES.MEMBERS, id: `${projectId}-LIST` },
      ],
      onQueryStarted: (params, { dispatch, queryFulfilled }) => {
        const { email, level, guid } = params;
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('members', params, members => {
            members[guid].email = email;
            members[guid].level = level;
          })
        );
        queryFulfilled
          .then(() => {
            toast.success('Member Updated.');
          })
          .catch(r => {
            listUpdate.undo();
            toast.error('There was a problem editing the member');
            return r;
          });
      },
    }),
    deleteMember: build.mutation<
      void,
      { membershipId: string; guid: string; isSelf: boolean }
    >({
      query: ({ membershipId, guid }) => ({
        url: `projects/${guid}/members/${membershipId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { membershipId, guid }) => [
        { type: TAGTYPES.MEMBERS, id: membershipId },
        { type: TAGTYPES.MEMBERS, id: `${guid}-LIST` },
        { type: TAGTYPES.PROJECTS, id: guid },
        { type: TAGTYPES.PROJECTS, id: 'LIST' },
        { type: TAGTYPES.PROJECTS, id: 'LIST+MEMBERS' },
      ],
      onQueryStarted: (
        { membershipId, guid, isSelf },
        { dispatch, queryFulfilled }
      ) => {
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('members', { guid }, members => {
            delete members[membershipId];
          })
        );
        queryFulfilled
          .then(() => {
            toast.success(isSelf ? 'Left project' : 'Removed from project.');
          })
          .catch(r => {
            listUpdate.undo();
            toast.error(
              isSelf
                ? 'There was a problem leaving the project'
                : 'There was a problem removing member from project'
            );
            return r;
          });
      },
    }),
    /*
     *      Data Artifacts
     */
    dataSources: build.query<Record<string, ProjectArtifact>, string>({
      query: guid => `projects/${guid}/artifacts`,
      transformResponse: (
        response: ApiResponse<{ artifacts: ProjectArtifact[] }>
      ) =>
        response.data.artifacts.reduce((all, artifact) => {
          artifact.artifactKey = artifact.key;
          all[artifact.key] = artifact;
          return all;
        }, {}),
      providesTags: (result = {}, error, projectGuid) => [
        ...Object.keys(result).map(artifactKey => ({
          type: TAGTYPES.DATA_SOURCES as const,
          id: `${projectGuid}-${artifactKey}`,
        })),
        { type: TAGTYPES.DATA_SOURCES, id: `${projectGuid}-LIST` },
      ],
    }),
    dataSourceManifest: build.query<
      ProjectArtifactWithManifest,
      { projectGuid: string; artifactKey: string }
    >({
      query: ({ projectGuid, artifactKey }) => ({
        url: `projects/${projectGuid}/artifacts/manifest`,
        params: { key: artifactKey },
        responseHandler: 'text',
      }),
      transformResponse: (response: string) =>
        JSON.parse(response.replace('Infinity', '"Infinity"'))?.data || {},
      providesTags: [{ type: TAGTYPES.DATA_SOURCE_MANIFEST, id: 'guid' }],
    }),
    dataSourceURL: build.query<
      string,
      {
        projectGuid: string;
        artifactKey: string;
      }
    >({
      queryFn: async ({ projectGuid, artifactKey }, api, extra, baseQuery) => {
        try {
          // If sample data then just return artifactKey
          if (artifactKey.includes(GRETEL_BLUEPRINTS_URL)) {
            return { data: artifactKey };
          }
          const { data: response, error } = await baseQuery({
            url: `/projects/${projectGuid}/artifacts/download`,
            params: { key: artifactKey, uncompressed: 'auto' },
          });
          if (error) {
            return { error };
          }
          const { url } = (
            response as ApiResponse<{ data: ProjectArtifactUrl }>
          ).data.data;
          return { data: url };
        } catch (error) {
          return { error };
        }
      },
      providesTags: [TAGTYPES.DATA_SOURCE_URL],
    }),
    dataSourceFileStats: build.query<
      { fileStats: FileStats },
      { url: string; fileName: string } // artifactKey is usually provided as the fileName
    >({
      queryFn: async ({ url, fileName }) => {
        try {
          const fileStats = await fetchAndProcessFile(url, 'GET', fileName);
          return { data: { fileName, fileStats } };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteDataSource: build.mutation<
      void,
      { guid: string; artifactKey: string }
    >({
      query: ({ guid, artifactKey }) => ({
        url: `projects/${guid}/artifacts`,
        method: 'DELETE',
        params: { key: artifactKey },
      }),
      invalidatesTags: (result, error, { guid, artifactKey }) => [
        { type: TAGTYPES.DATA_SOURCES, id: `${guid}-${artifactKey}` },
        { type: TAGTYPES.DATA_SOURCES, id: `${guid}-LIST` },
      ],
      onQueryStarted: ({ guid, artifactKey }, { dispatch, queryFulfilled }) => {
        const listUpdate = dispatch(
          GretelAPI.util.updateQueryData('dataSources', guid, dataSources => {
            delete dataSources[artifactKey];
          })
        );
        queryFulfilled.catch(r => {
          listUpdate.undo();
          return r;
        });
      },
    }),
    /**
     * Sample Dataset Previews
     */
    sampleDatasetPreview: build.query({
      queryFn: async (fileName: string) => {
        try {
          const url = `${GRETEL_BLUEPRINTS_URL}/sample_data_previews/${fileName}`;
          const fileStats = await fetchAndProcessFile(url, 'GET', fileName);
          return { data: { ...fileStats } };
        } catch (error) {
          return { error };
        }
      },
    }),
    /*
     *      Current User
     */
    loadProfile: build.query<User | undefined, void>({
      query: () => ({
        url: 'users/me',
        params: { expand: 'policies' },
      }),
      transformResponse: (response: ApiResponse<{ me: User }>) =>
        response?.data?.me || {},
      providesTags: () => [{ type: TAGTYPES.USER, id: 'profile' }],
      onCacheEntryAdded: async (args, { cacheDataLoaded }) => {
        const { data: profile } = await cacheDataLoaded;
        if (profile) {
          // NOTE: Only the first `prefill` or `identify` call (whichever comes first) is read by Zen Desk.
          //       Subsequent updates are ignored by the Zen Desk widget, for they are not 禪.
          prefillZenDeskWidget(profile);
          showZenDeskWidget();
          identify(profile._id);
          datadogRum.setUser({ id: profile.guid, email: profile.email });
        }
      },
      onQueryStarted: (args, { queryFulfilled }) => {
        queryFulfilled.catch(() => true);
      }, // Ignore errors because this API hook is used to check user login state.
    }),
    logout: build.query<void, void>({
      query: () => ({
        url: 'users/me/logout',
        method: 'POST',
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(uploadDuck.actions.reset());
          dispatch(projectsDuck.actions.reset());
          dispatch(useCasesWorkflowDuck.actions.reset());
          dispatch(workflowDuck.actions.reset());
          dispatch(GretelAPI.util.resetApiState());
          clearBrowserDataAndRedirect();
        });
      },
    }),
    updateProfile: build.mutation<User, UpdateUserParams>({
      query: ({ query = {}, ...profile }) => ({
        url: 'users/me',
        method: 'PATCH',
        params: query,
        body: profile,
      }),
      transformResponse: (response: ApiResponse<{ me: User }>) =>
        response.data.me,
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'profile' }],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              GretelAPI.util.updateQueryData(
                'loadProfile',
                undefined,
                userProfile => ({ ...userProfile, ...data })
              )
            );
            toast.success('Profile updated.');
          })
          .catch(r => {
            toast.error('There was a problem updating your profile');
            return r;
          });
      },
    }),
    deleteUser: build.mutation<User, { dry_run: YesOrNo }>({
      query: ({ dry_run = YesOrNo.YES }) => ({
        url: 'users/me',
        method: 'DELETE',
        params: { dry_run },
      }),
      transformResponse: (response: ApiResponse<{ me: User }>) =>
        response?.data?.me || {},
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'profile' }],
      onQueryStarted: (
        { dry_run = YesOrNo.YES },
        { dispatch, queryFulfilled }
      ) => {
        if (dry_run === 'no') {
          queryFulfilled
            .then(() => {
              dispatch(
                GretelAPI.util.updateQueryData(
                  'loadProfile',
                  undefined,
                  () => undefined
                )
              );
            })
            .catch(r => {
              toast.error('There was a problem deleting your account');
              return r;
            });
        }
      },
    }),
    refreshAPIKey: build.mutation<string, void>({
      query: () => ({
        url: 'users/me/key',
        method: 'PATCH',
      }),
      transformResponse: (response: ApiResponse<{ key: string }>) =>
        response?.data?.key,
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'profile' }],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              GretelAPI.util.updateQueryData(
                'loadProfile',
                undefined,
                userProfile => {
                  if (userProfile) {
                    userProfile.api_key = data;
                  }
                }
              )
            );
            toast.success('Successfully refreshed your API key');
          })
          .catch(r => {
            toast.error('There was a problem refreshing your API key');
            return r;
          });
      },
    }),
    revokeAPIKey: build.mutation<void, void>({
      query: () => ({
        url: 'users/me/key',
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'profile' }],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(
              GretelAPI.util.updateQueryData(
                'loadProfile',
                undefined,
                userProfile => {
                  if (userProfile) {
                    delete userProfile.api_key;
                  }
                }
              )
            );
            toast.success('Successfully revoked your API key');
          })
          .catch(r => {
            toast.error('There was problem revoking your API key');
            return r;
          });
      },
    }),
    loadInvites: build.query({
      query: () => ({
        url: 'users/me/invites',
        params: { invite_types: 'team,project' },
      }),
      transformResponse: (response: ApiResponse<{ invites: UserInvite[] }>) =>
        response?.data?.invites || [],
      providesTags: (result = []) => [
        ...result.map(invite => ({
          type: TAGTYPES.USER as const,
          id: `invites-${'_id' in invite ? invite._id : invite.domain_guid}`,
        })),
        { type: TAGTYPES.USER, id: 'invites-LIST' },
      ],
    }),
    acceptProjectInvite: build.mutation({
      query: ({ inviteId }) => ({
        url: `users/me/invites/${inviteId}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, _id) => [
        { type: TAGTYPES.USER, id: `invites-${_id}` },
        { type: TAGTYPES.USER, id: 'invites-LIST' },
        { type: TAGTYPES.PROJECTS, id: 'LIST' },
        { type: TAGTYPES.PROJECTS, id: 'LIST+MEMBERS' },
      ],
      onQueryStarted: ({ projectName, href }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            toast.success(`You've successfully joined ${projectName}`, {
              close: true,
              action: {
                label: 'View Project',
                href: href,
              },
            } as ToastAlert);
          })
          .catch(r => {
            toast.error('There was a problem accepting the invite');
            return r;
          });
      },
    }),
    declineProjectInvite: build.mutation({
      query: inviteId => ({
        url: `users/me/invites/${inviteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, _id) => [
        {
          type: TAGTYPES.USER,
          id: `invites-${_id}`,
        },
        { type: TAGTYPES.USER, id: 'invites-LIST' },
      ],
    }),
    loadBilling: build.query<BillingSummary, void>({
      query: () => 'users/me/billing',
      transformResponse: (response: ApiResponse<{ billing: BillingSummary }>) =>
        response?.data?.billing || {},
      providesTags: () => [{ type: TAGTYPES.USER, id: 'BILLING' }],
    }),
    initiatePaymentMethodCollection: build.mutation<string, void>({
      query: () => ({
        url: '/users/me/billing/setup_payment_method',
        method: 'POST',
      }),
      transformResponse: (response: ApiResponse<{ client_secret: string }>) =>
        response?.data?.client_secret,
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'BILLING' }],
    }),
    deleteBillingPaymentMethod: build.mutation({
      query: payment_method_id => ({
        url: `users/me/billing/payment_methods/${payment_method_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'BILLING' }],
    }),
    setDefaultBillingPaymentMethod: build.mutation({
      query: payment_method_id => ({
        url: 'users/me/billing/payment_methods/set_default',
        method: 'POST',
        body: { payment_method_id },
      }),
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'BILLING' }],
    }),
    modifyBillingSubscription: build.mutation({
      query: product_id => ({
        url: 'users/me/billing/subscriptions',
        method: 'POST',
        body: { product_id },
      }),
      invalidatesTags: () => [{ type: TAGTYPES.USER, id: 'BILLING' }],
    }),
    /*
     *      Teams
     */
    teamMembers: build.query<GetTeamResponse, void>({
      query: () => 'users/me/team',
      transformResponse: ({ data }: ApiResponse<GetTeamResponse>) => data,
      providesTags: res => [{ type: TAGTYPES.TEAM, id: res?.guid }],
    }),
    inviteTeamMember: build.mutation<
      void,
      { domain_guid: string; user_email: string }
    >({
      query: ({ domain_guid, user_email }) => ({
        url: 'users/team/invites/create',
        method: 'POST',
        body: { domain_guid, user_email },
      }),
      invalidatesTags: (result, error, { domain_guid }) => [
        { type: TAGTYPES.TEAM, id: domain_guid },
      ],
      onQueryStarted: (args, { queryFulfilled }) => {
        toast.promise(
          queryFulfilled,
          {
            loading: 'Sending Invite...',
            success: 'Successfully sent!',
            error: err =>
              Formatters.Other.messageMaybeError('Error', err.error.data),
          },
          {
            error: {
              duration: Infinity,
            },
          }
        );
      },
    }),
    deleteTeamMember: build.mutation<
      void,
      { domain_guid: string; user_email: string; isSelf: boolean }
    >({
      query: ({ domain_guid, user_email }) => ({
        url: 'users/team/remove_member',
        method: 'POST',
        body: { domain_guid, user_email },
      }),
      invalidatesTags: (result, error, { domain_guid }) => [
        { type: TAGTYPES.USER, id: `invites-${domain_guid}` },
        { type: TAGTYPES.USER, id: 'invites-LIST' },
        { type: TAGTYPES.USER, id: 'BILLING' },
        { type: TAGTYPES.TEAM, id: domain_guid },
      ],
      onQueryStarted: ({ isSelf }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            toast.success(
              isSelf
                ? 'You have successfully left your team. Contact your team owner if this was done in error.'
                : 'Member removed.'
            );
          })
          .catch(r => {
            toast.error(
              isSelf
                ? `There was a problem leaving the team: ${r.error.data.message}`
                : `There was a problem removing the member: ${r.error.data.message}`
            );
            return r;
          });
      },
    }),
    // Mutation to invalidate tags.
    acceptTeamInvite: build.mutation({
      query: domainGuid => ({
        url: `users/team/invites/accept/${domainGuid}`,
        method: 'GET',
      }),
      invalidatesTags: (result, error, domainGuid) => [
        { type: TAGTYPES.USER, id: `invites-${domainGuid}` },
        { type: TAGTYPES.USER, id: 'invites-LIST' },
        { type: TAGTYPES.USER, id: 'BILLING' },
        { type: TAGTYPES.TEAM, id: domainGuid },
        // Unless the user is changing teams, the initial cached domain GUID/tag ID will be undefined
        { type: TAGTYPES.TEAM, id: undefined },
      ],
      onQueryStarted: (args, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            toast.success("You've successfully joined a new team", {
              close: true,
              action: {
                label: 'Go to my Dashboard',
                href: DASHBOARD_ROUTE.path,
              },
            } as ToastAlert);
          })
          .catch(r => {
            toast.error('There was a problem accepting the invite');
            return r;
          });
      },
    }),
    declineTeamInvite: build.mutation<
      void,
      { domain_guid: string; user_email?: string }
    >({
      query: ({ domain_guid, user_email }) => ({
        url: 'users/team/invites/reject',
        method: 'POST',
        body: { domain_guid, user_email },
      }),
      invalidatesTags: (result, error, { domain_guid }) => [
        { type: TAGTYPES.USER, id: `invites-${domain_guid}` },
        { type: TAGTYPES.USER, id: 'invites-LIST' },
        { type: TAGTYPES.USER, id: 'BILLING' },
        { type: TAGTYPES.TEAM, id: domain_guid },
        // Unless the user is changing teams, the initial cached domain GUID/tag ID will be undefined
        { type: TAGTYPES.TEAM, id: undefined },
      ],
      onQueryStarted: (args, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            toast.success('Invited member removed.');
          })
          .catch(r => {
            toast.error('There was a problem removing the pending member');
            return r;
          });
      },
    }),
    updateTeamPolicies: build.mutation<void, UpdateTeamPoliciesParams>({
      query: ({ domain_guid, policies }) => ({
        url: `users/team/policies/update`,
        method: 'POST',
        body: { domain_guid, policies },
      }),
      invalidatesTags: (result, error, { domain_guid }) => [
        { type: TAGTYPES.USER, id: 'profile' },
        { type: TAGTYPES.TEAM, id: domain_guid },
      ],
      onQueryStarted: (args, { queryFulfilled }) => {
        queryFulfilled.catch(r => {
          toast.error('There was a problem updating your team settings');
          return r;
        });
      },
    }),
    /*
     *      Use Cases
     */
    useCases: build.query<{ cards: UseCaseBlueprint[] }, void>({
      queryFn: async () => {
        try {
          const response = await fetchRemote(USE_CASE_CONFIG);
          const data = await response.json();
          const cards = [FROM_SCRATCH_CARD].concat(data.cards);
          /** @NOTE (INT-2070) move `use-case-navigator` to the front */
          const indexOfNavigatorCard = cards.findIndex(card => {
            return card.gtmId === NAVIGATOR_CARD.gtmId;
          });
          if (indexOfNavigatorCard > -1) {
            const [NAVIGATOR_CARD] = cards.splice(indexOfNavigatorCard, 1);
            cards.unshift(NAVIGATOR_CARD);
          }

          return { data: { cards } };
        } catch (error) {
          return { error };
        }
      },
      providesTags: [TAGTYPES.USE_CASES],
    }),
    useCaseDetails: build.query({
      queryFn: async (detailsFileName: string) => {
        try {
          const url = getUseCaseDetailsPath(detailsFileName);
          const response = await fetchRemote(url);
          const data = await response.text();
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    /*
     *      Playground / Inference
     */
    naturalLanguageInference: build.mutation<
      { text: string; logs: string[] },
      {
        model_id: string;
        prompt: string;
        params: object;
        signal?: AbortSignal;
      }
    >({
      query: ({ model_id, prompt, params, signal }) => ({
        url: '/v1/inference/natural/generate',
        method: 'POST',
        body: { model_id, prompt, params },
        signal,
      }),
    }),
    tabularInferenceEditStream: build.mutation<
      { stream_id: string },
      {
        model_id: string;
        prompt: string;
        params: object;
        num_rows?: number;
        ref_data: {
          sample_data?: {
            table_headers?: string[];
            table_data?: Record<string, string | number>[];
          };
          data: {
            table_headers?: string[];
            table_data?: Record<string, string | number>[];
          };
        };
        signal?: AbortSignal;
      }
    >({
      query: ({
        model_id,
        prompt,
        params,
        num_rows,
        ref_data: { data },
        signal,
      }) => ({
        url: '/v1/inference/tabular/stream',
        method: 'POST',
        body: {
          model_id,
          prompt,
          params,
          num_rows,
          ref_data: {
            data,
          },
        },
        signal,
      }),
    }),
    tabularInferenceStream: build.mutation<
      { stream_id: string },
      {
        model_id: string;
        prompt: string;
        params: object;
        num_rows?: number;
        table_headers: string[];
        table_data: Record<string, string>[];
        signal?: AbortSignal;
      }
    >({
      query: ({
        model_id,
        prompt,
        params,
        num_rows,
        table_headers,
        table_data,
        signal,
      }) => ({
        url: '/v1/inference/tabular/stream',
        method: 'POST',
        body: {
          model_id,
          prompt,
          params,
          num_rows,
          table_headers,
          table_data,
        },
        signal,
      }),
    }),
    inferenceModels: build.query<Record<ModelMode, InferenceModel[]>, void>({
      query: () => '/v1/inference/models',
      transformResponse: (response: { models: InferenceModel[] }) => {
        // group models by their model_type (e.g., NAVIGATOR, NATURAL)
        // since this is how we use the data in Playground.
        return response.models.reduce(
          (grouping, model) => {
            const type = model.model_type;
            if (grouping[type]) {
              grouping[type].push(model);
            } else {
              grouping[type] = [model];
            }

            return grouping;
          },
          {} as Record<ModelMode, InferenceModel[]>
        );
      },
    }),
    tabularInferenceStreamIterate: build.mutation<
      InferenceStreamIterateTransformed,
      {
        stream_id: string;
        iterator: string;
        count: number;
        signal?: AbortSignal;
      }
    >({
      query: ({ stream_id, iterator, count, signal }) => ({
        url: '/v1/inference/tabular/stream/iterate',
        method: 'POST',
        body: { stream_id, iterator, count },
        signal,
      }),

      transformResponse: (response: InferenceStreamIterate) => {
        return {
          data: response.data.map(response => {
            if (typeof response.data === 'string') {
              return {
                log: {
                  message: response.data,
                  time_stamp: DateFormatter.logTime(new Date().toISOString()),
                  type: response.data_type,
                },
                data_type: response.data_type,
                table_data: [],
                table_headers: [],
              };
            } else {
              return {
                table_headers: response.data?.table_headers,
                table_data: response.data?.table_data,
                data_type: response.data_type,
              };
            }
          }),
          next_iterator: response.next_iterator,
          stream_state: { status: response.stream_state.status },
        };
      },
    }),
    suggestedPromptName: build.mutation<
      { text: string; logs: string[] },
      {
        prompt: string;
        signal?: AbortSignal;
      }
    >({
      query: ({ prompt, signal }) => ({
        url: '/v1/inference/natural/name-prompt',
        method: 'POST',
        body: {
          prompt,
        },
        signal,
      }),
    }),
    /*
     *      Model Types
     */
    modelTypes: build.query<{ modelTypes: ModelTypeBlueprint[] }, void>({
      queryFn: async () => {
        try {
          const response = await fetchRemote(MODEL_TYPES_CONFIG);
          const data = await response.json();
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: [TAGTYPES.MODEL_TYPES],
    }),
    /*
     *      Playground Example Prompts
     */
    examplePrompts: build.query<FormattedPromptResponse, void>({
      queryFn: async () => {
        try {
          const response = await fetchRemote(PLAYGROUND_EXAMPLE_PROMPT_URL);
          const data = await response.json();

          const formatted = {
            ...data,
            natural_language: data.natural_language.map(
              formatPlaygroundExamplePrompts
            ),
            tabular: data.tabular.map(formatPlaygroundExamplePrompts),
          };

          return { data: formatted };
        } catch (error) {
          return { error };
        }
      },
      providesTags: [TAGTYPES.MODEL_TYPES],
    }),
    /*
     *      Licenses
     */
    licenses: build.query({
      query: () => ({
        url: 'opt/licenses',
      }),
      transformResponse: (
        response: ApiResponse<{
          licenses: { package: string; license: string }[];
        }>
      ) => response?.data?.licenses || [],
      providesTags: () => [{ type: TAGTYPES.LICENSES, id: 'licenses-LIST' }],
    }),
  }),
  keepUnusedDataFor: process.env.NODE_ENV === 'test' ? 0 : undefined,
});
