import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { ConnectedRouter } from 'connected-react-router';
import analytics from 'common/analytics';
import ConfigCatProvider from 'common/contextProviders/ConfigCatProvider';
import buildConfig from 'src/Console/dataDogConfig';
import GlobalErrorBoundary from 'src/Console/Error';
import store, { history } from 'src/store';
import ConsoleRouter from './Router';
import { SidebarStatusProvider } from './SidebarStatusContext';
import { Theming } from './Theming';

// we want datadog setup to happen ASAP, so we'll do this outside of the component,
// as soon as the file loads up. This should also prevent it from initializing twice
// if for some reason the App component re-renders.
const dataDogConfig = buildConfig();
if (dataDogConfig) {
  datadogRum.init(dataDogConfig);
  datadogRum.startSessionReplayRecording();
}

if (process.env.SEGMENT_WRITE_KEY) {
  analytics.load({ writeKey: process.env.SEGMENT_WRITE_KEY });
}

const App = () => {
  return (
    <GlobalErrorBoundary>
      <Router history={history}>
        <Provider store={store}>
          <ConfigCatProvider>
            <Theming>
              <ConnectedRouter history={history}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SidebarStatusProvider>
                    <ConsoleRouter />
                  </SidebarStatusProvider>
                </LocalizationProvider>
              </ConnectedRouter>
            </Theming>
          </ConfigCatProvider>
        </Provider>
      </Router>
    </GlobalErrorBoundary>
  );
};

export default App;
