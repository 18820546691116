import { Components, Theme } from '@mui/material';

const MuiStepLabel: Components<Theme>['MuiStepLabel'] = {
  styleOverrides: {
    label: ({ theme }) => ({
      '&.Mui-completed': {
        color: theme.palette.success.main,
      },
      '&.Mui-error': {
        color: theme.palette.text.primary,
      },
    }),
    iconContainer: ({ theme }) => ({
      marginLeft: theme.spacing(-0.5),
      marginRight: theme.spacing(2),
    }),
  },
};

export default MuiStepLabel;
