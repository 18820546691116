import { Components, Theme } from '@mui/material';

const MuiStepIcon: Components<Theme>['MuiStepIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.spacing(8),
      width: theme.spacing(8),
      color: theme.palette.background.secondary,
      '&.Mui-completed': {
        color: theme.palette.success[500],
        '& .MuiStepIcon-text': {
          fill: theme.palette.success.contrastText,
        },
      },
      '&.Mui-active': {
        color: theme.palette.primary.main,
        '& .MuiStepIcon-text': {
          fill: theme.palette.primary.contrastText,
        },
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
        '& .MuiStepIcon-text': {
          fill: theme.palette.text.primary,
        },
      },
    }),
    /* Styles applied to the SVG text element. */
    text: ({ theme }) => ({
      fill: theme.palette.text.disabled,
    }),
  },
};

export default MuiStepIcon;
