import * as React from 'react';
import { Components, SvgIcon, Theme } from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import FullLoader from 'common/Loader/Full';
import NoResultsOverlay from 'common/QubitTable/NoResultsOverlay';
import { ChevronDown, ChevronRight, ColumnSeparator } from 'icons/figma';

const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    autoHeight: true,
    columnHeaderHeight: 42,
    rowHeight: 42,
    slots: {
      loadingOverlay: FullLoader,
      noResultsOverlay: NoResultsOverlay,
      detailPanelExpandIcon: ChevronRight,
      detailPanelCollapseIcon: ChevronDown,
      columnResizeIcon: () => (
        <SvgIcon preserveAspectRatio="none">
          <ColumnSeparator />
        </SvgIcon>
      ),
    },
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableRowSelectionOnClick: true,
    localeText: {
      noResultsOverlayLabel: 'No Results',
    },
    showCellVerticalBorder: true,
    pageSizeOptions: [25],
  },
  styleOverrides: {
    root: ({ theme }) => ({
      border: '1px solid',
      borderColor: theme.palette.border.default,
      borderRadius: theme.shape.radii.large,
    }),
    toolbarContainer: ({ theme }) => ({
      marginBottom: theme.spacing(6),
    }),
    row: ({ theme }) => ({
      '&:hover': {
        backgroundColor: theme.palette.background.secondary,
      },
    }),
    footerContainer: ({ theme }) => ({
      borderTop: '1px solid',
      borderTopColor: theme.palette.border.default,
    }),
    columnHeaders: ({ theme }) => ({
      backgroundColor: theme.palette.background.secondary,
    }),
    columnHeader: {
      maxWidth: '400px',
      padding: 0,
      // Hack solution to remove outline on focus.
      // User can still focus into cell and fire off actions. Just no visual feedback as such.
      '&:focus, &:focus-within': {
        outline: 'none',
      },
    },
    columnHeaderTitle: ({ theme }) => ({
      ...theme.typography.body2Strong,
    }),
    columnHeaderTitleContainer: ({ theme }) => ({
      padding: theme.spacing(0, 4),
    }),
    'columnHeader--sortable': ({ theme }) => ({
      '& .MuiDataGrid-iconButtonContainer': {
        marginLeft: theme.spacing(1),
      },
    }),
    'columnSeparator--resizable': ({ theme }) => ({
      '& svg': {
        height: 42,
        paddingLeft: 2,
      },
      color: theme.palette.grey[100],
      ':hover,&.MuiDataGrid-columnSeparator--resizing': {
        color: theme.palette.grey[300],
      },
    }),
    cell: ({ theme }) => ({
      ...theme.typography.body2,
      borderBottomColor: theme.palette.border.default,
      // Hack solution to remove outline on focus.
      // User can still focus into cell and fire off actions. Just no visual feedback as such.
      '&:focus, &:focus-within': {
        outline: 'none',
      },
    }),

    withBorderColor: ({ theme }) => ({
      borderColor: theme.palette.border.default,
      '&:last-child': {
        borderRight: 0,
      },
    }),
    treeDataGroupingCellToggle: ({ theme }) => ({
      overflow: 'visible',
      minWidth: theme.spacing(8),
      marginRight: theme.spacing(2),
    }),
  },
};

export default MuiDataGrid;
