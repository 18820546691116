import { Components, Theme } from '@mui/material';

const MuiInput: Components<Theme>['MuiInput'] = {
  styleOverrides: {
    underline: {
      '&.Mui-disabled:before': {
        borderBottomStyle: 'none',
      },
    },
  },
};

export default MuiInput;
