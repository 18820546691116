import { alpha, Components, listItemIconClasses, Theme } from '@mui/material';

declare module '@mui/material/MenuItem' {
  interface MenuItemPropsVariantOverrides {
    // `color="error"` is used for destructive actions, like delete.
    color: 'error' | undefined;
  }
}

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      // A bit annoying but decent way to keep menu/list icon styles aligned.
      // https://github.com/mui/material-ui/issues/30536
      [`& .${listItemIconClasses.root}`]: {
        minWidth: 'unset',
        marginRight: theme.spacing(3),
      },
    }),
  },
  /*
   * Extends the default MUI props. https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
   */
  variants: [
    {
      props: { color: 'error' },
      style: ({ theme }) => ({
        color: theme.palette.error.main,
        '&:hover, &:focus': {
          backgroundColor: alpha(
            theme.palette.error.main,
            theme.palette.action.hoverOpacity
          ),
        },
      }),
    },
  ],
};

export default MuiMenuItem;
