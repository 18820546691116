import { TARGET_TYPE_BOTH } from 'src/Console/Connections/utils';
import { AuthStrategy, S3FormState } from './types';

// Setting the default values for the form
export const initialS3Form: S3FormState = {
  name: '',
  type: 's3',
  secret_access_key: '',
  access_key_id: '',
  role_arn: '',
  connection_target_type: TARGET_TYPE_BOTH,
  auth_strategy: AuthStrategy.ACCESS_KEY,
};
