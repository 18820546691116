import * as React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import TeamIllustration from 'src/resources/Team.svg';

const NoResultsOverlay = React.forwardRef<HTMLDivElement, GridProps>(
  (props, ref) => {
    const apiRef = useGridApiContext();
    const noResultsOverlayLabel = apiRef.current.getLocaleText(
      'noResultsOverlayLabel'
    );

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{ minHeight: '480px' }}
        ref={ref}
        {...props}
      >
        <TeamIllustration />
        <Typography variant="h3" sx={{ mt: 1 }}>
          {noResultsOverlayLabel}
        </Typography>
      </Grid>
    );
  }
);

NoResultsOverlay.displayName = 'NoResultsOverlay';

export default NoResultsOverlay;
