import * as React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { setToken } from 'common/utils/token';
import { isGretelHost } from 'src/api/utils/baseQuery';
import ROUTES, { isRedirect } from 'src/routes';
import { ConsoleRouteContext } from 'utils/useConsoleRoute';
import PrivateRoute from './PrivateRoute';

const ConsoleRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  React.useEffect(() => {
    if (token) {
      if (!isGretelHost()) {
        // Only set the token if we're on a host that cannot use credentials. -md
        setToken(token);
      }
      // Remove token & other URL Search Params
      history.replace(location.pathname);
    }
  }, [history, location, token]);

  return (
    <Switch>
      {ROUTES.map(route => {
        if (isRedirect(route)) {
          const { isRedirect, to, from, ...routeProps } = route;
          const enhancedTo = {
            ...location,
            ...(typeof to === 'object' ? to : { pathname: to }),
          };
          return (
            <Redirect
              key={`route-${from}-${to}`}
              to={enhancedTo}
              from={from}
              {...routeProps}
            />
          );
        }
        const {
          childComponent: ChildComponent,
          isPrivate,
          ...routeProps
        } = route;
        const key = `route-${route.title}`;
        if (isPrivate) {
          return (
            <PrivateRoute key={key} route={route} {...routeProps}>
              <React.Suspense fallback={null}>
                <ChildComponent />
              </React.Suspense>
            </PrivateRoute>
          );
        }
        return (
          <Route key={key} {...routeProps}>
            <ConsoleRouteContext.Provider value={route}>
              <React.Suspense fallback={null}>
                <ChildComponent />
              </React.Suspense>
            </ConsoleRouteContext.Provider>
          </Route>
        );
      })}
    </Switch>
  );
};

export default ConsoleRouter;
