import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import store from './store';

export type Store = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<Store> = useSelector;
export type ThunkApi<Extras = unknown> = {
  state: Store;
  dispatch: AppDispatch;
  extra: Extras;
};
