import { Components, Theme } from '@mui/material';

const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2Strong,
      color: theme.palette.text.primary,
    }),
  },
};

export default MuiInputLabel;
