import { Connection } from 'src/api/pilot/connections';
import {
  apiToForm as azureApiToForm,
  formToApi as azureFormToApi,
} from './steps/Forms/Azure/transform';
import {
  apiToForm as databricksApiToForm,
  formToApi as databricksFormToApi,
} from './steps/Forms/Databricks/transform';
import {
  apiToForm as s3ApiToForm,
  formToApi as s3FormToApi,
} from './steps/Forms/S3/transform';
import { AllConnectionForms } from './types';

export const formToApi = (form: AllConnectionForms): Partial<Connection> => {
  switch (form.type) {
    case 'azure':
      return azureFormToApi(form);
    case 'databricks':
      return databricksFormToApi(form);
    case 's3':
      return s3FormToApi(form);

    default:
      return form as Connection;
  }
};

export const apiToForm = (connection: Connection): AllConnectionForms => {
  switch (connection.type) {
    case 'azure':
      return azureApiToForm(connection);
    case 'databricks':
      return databricksApiToForm(connection);
    case 's3':
      return s3ApiToForm(connection);
    default:
      return connection as AllConnectionForms;
  }
};
