import * as DateFns from 'date-fns';
import { DateStringISO } from './formatters/types';

export type UnitsOfTime =
  | 'years'
  | 'months'
  | 'weeks'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds'
  | 'milliseconds';

/** Per order of kbarkmeier an extra layer of abstraction
 * is desired to wrap around the compareDesc to allow the acceptance of
 * ISO formatted datetime strings when using compareDesc
 * @param stringOne - first datestring to compare
 * @param stringTwo - second datestring to compare
 *
 * @returns Compare the two dates and return -1 if the first date is after the second,
 * 1 if the first date is before the second or 0 if dates are equal.
 */
export const compareDescStringISO = (
  stringOne: DateStringISO,
  stringTwo: DateStringISO
) =>
  DateFns.compareDesc(DateFns.parseISO(stringOne), DateFns.parseISO(stringTwo));

export const differenceBetween = (
  a: Date,
  b: Date
): Record<UnitsOfTime, number> => {
  const milliseconds = DateFns.differenceInMilliseconds(a, b);
  const seconds = DateFns.differenceInSeconds(a, b);
  const minutes = DateFns.differenceInMinutes(a, b);
  const hours = DateFns.differenceInHours(a, b);
  const days = DateFns.differenceInDays(a, b);
  const weeks = DateFns.differenceInWeeks(a, b);
  const months = DateFns.differenceInMonths(a, b);
  const years = DateFns.differenceInYears(a, b);

  return {
    milliseconds,
    seconds,
    minutes,
    hours,
    days,
    weeks,
    months,
    years,
  };
};
/** Per order of kbarkmeier an extra layer of abstraction
 * is desired to wrap around the isBefore func to allow
 * the use of ISO formatted string and compare to now
 * @param stringISO - first datestring to use
 *
 * @returns Boolean - Is the firest date before the second one
 */
export const isBeforeNowStringISO = (stringISO: DateStringISO) =>
  DateFns.compareDesc(DateFns.parseISO(stringISO), Date.now());

/** Get a shorthand version of formatDistance
 * If the distance is more than a month we will show the international date.
 * @param start:String - ISO timestamp
 * @param end:String - ISO timestamp to compare against
 * @param addSuffix:Boolean - bool to add suffix or not
 *
 * @return String - shorthand version of what formatDistanceStrict would return
 */
type TimeDistance = {
  start: DateStringISO;
  end: DateStringISO;
  addSuffix?: boolean;
};
export const shorthandDistance = ({ start, end, addSuffix }: TimeDistance) => {
  if (!start || !end) {
    return '';
  }
  const startDate = DateFns.parseISO(start);
  const endDate = DateFns.parseISO(end);

  if (DateFns.differenceInMonths(endDate, startDate) > 0) {
    return DateFns.intlFormat(startDate, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  if (addSuffix) {
    const longhand = DateFns.formatDistanceStrict(startDate, endDate, {
      addSuffix: true,
    });
    if (DateFns.compareAsc(startDate, endDate)) {
      const [amount, unit, suffix] = longhand.split(' ');
      return `${amount}${getUnitShorthand(unit)} ${suffix}`;
    } else {
      const [prefix, amount, unit] = longhand.split(' ');
      return `${prefix} ${amount}${getUnitShorthand(unit)}`;
    }
  }
  const [amount, unit] = DateFns.formatDistanceStrict(startDate, endDate).split(
    ' '
  );
  return `${amount}${getUnitShorthand(unit)}`;
};

type TimeDistanceToNow = {
  date: TimeDistance['start'];
  addSuffix?: TimeDistance['addSuffix'];
};
export const shorthandDistanceToNow = ({
  date,
  addSuffix,
}: TimeDistanceToNow) =>
  shorthandDistance({
    start: date,
    end: new Date().toISOString(),
    addSuffix,
  });

/** Crafting shorthand for twitter like 10s, 20m, 1hr
 * @param unit - String longhand version of unit
 *
 * @returns String - shorthand version of unit
 */
export const getUnitShorthand = (unit: string) => {
  switch (unit) {
    case 'second':
    case 'seconds':
      return 's';
    case 'minute':
      return ' min';
    case 'minutes':
      return ' mins';
    case 'hour':
    case 'hours':
      return 'h';
    case 'day':
    case 'days':
    default:
      return 'd';
    // If we've gotten to months they should be seeing date shorthand instead.
  }
};

/**
 * I need the start date of the next month
 * @returns String - The first of the next month in ISO format
 */
export const getStartOfNextMonth = (): DateStringISO =>
  DateFns.formatISO(DateFns.startOfMonth(DateFns.addMonths(new Date(), 1)));

/**
 *
 * @param time DateString ISO 8601
 * @returns a string describing the time relative to now.
 * (e.g. "1 week ago" or "in 2 minutes")
 * Note that for dates more than a month away, the MMM DD is returned, e.g. Apr 08
 * for dates more than a year away YYYY-MM-DD is returned, e.g. 2024-04-08
 */
export const timeRelativeToNow = (time?: DateStringISO) => {
  if (!time) {
    return '';
  }

  const date = DateFns.parseISO(time);

  // check that `time` is a valid DateStringISO
  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const now = new Date();
  const delta = differenceBetween(date, now);

  if (Math.abs(delta.months) >= 3) {
    return DateFns.intlFormat(date, {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
    });
  }

  if (Math.abs(delta.months) >= 1) {
    return DateFns.intlFormat(date, {
      month: 'short',
      day: '2-digit',
    });
  }

  if (Math.abs(delta.seconds) < 60) {
    return delta.seconds > 0 ? `in < 1 minute` : `< 1 minute ago`;
  }

  return DateFns.intlFormatDistance(date, now);
};
