const MODEL_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  ERROR: 'error',
  LOST: 'lost',
};

const FAIL_STATUSES = [
  MODEL_STATUS.CANCELLED,
  MODEL_STATUS.ERROR,
  MODEL_STATUS.LOST,
] as const;

const TERMINAL_STATUSES = [
  MODEL_STATUS.COMPLETED,
  MODEL_STATUS.CANCELLED,
  MODEL_STATUS.ERROR,
  MODEL_STATUS.LOST,
] as const;

const RUN_STATUSES = [MODEL_STATUS.PENDING, MODEL_STATUS.ACTIVE] as const;

const CREATED_PLUS_RUNNING = [MODEL_STATUS.CREATED, ...RUN_STATUSES] as const;

const getLabel = (status: string) =>
  `${MODEL_STATUS[status].substr(0, 1).toLocaleUpperCase()}${MODEL_STATUS[
    status
  ].substr(1)}`;

export default Object.freeze(
  Object.assign(MODEL_STATUS, {
    getLabel,
    FAIL_STATUSES,
    TERMINAL_STATUSES,
    RUN_STATUSES,
    CREATED_PLUS_RUNNING,
  })
);
